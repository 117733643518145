import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const EBW_NAMESPACE = 'bsro-ui-ebw';

const checkQueryStrings = () => {
    const queryString = getUrlParameter(EBW_NAMESPACE) || '';

    if (queryString === 'v2' || queryString === '2') {
        setCookie(EBW_NAMESPACE, 'v2');
        return true;
    } else if (queryString === 'v1' || queryString === '1') {
        setCookie(EBW_NAMESPACE, 'v1');
        return false;
    }
};

export const expeditedBookingSwitch = () => {
    // enable a way to trigger switch, even if it's turned off.
    checkQueryStrings();

    // If no "bsro-ui-tdp" cookie, then default to v1.
    const version = getCookie(EBW_NAMESPACE) || 'v1';

    // Switch if v2.
    if (version === 'v2') {
        return true;
    }
    
    // Don't switch by default.
    return false;
};

export const expeditedBookingSwitchOld = () => {
    //////////////////
    // XB ON
    //////////////////
    if (localStorage.getItem('expeditedBookingIsActive') === 'false') {
        return false;
    }

    return true;

    ///////////////
    // XB OFF
    ///////////////
    // if (localStorage.getItem('expeditedBookingIsActive') === 'true') {
    //     return true;
    // }

    // return false;
};  
