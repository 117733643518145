import '../scss/main.scss';
import assetLoader from 'fcac/components/content/assets/v1/js/assets';

console.group('fcac main');

console.group('START OF APP');

document.addEventListener('DOMContentLoaded', () => {
    assetLoader().then(() => {
        //    import(
        /* webpackChunkName: "dynamic-import" */
        /* webpackPreload: true */
        //      '../../../dynamic-import/v1/js/dynamic-import.js'
        //    ).then(() => {
        // Do something with import...
        //  });

        //   if (DEVELOPMENT) {
        console.log('all assets loaded');
        console.groupEnd();
        //    }
    });
});
