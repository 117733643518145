import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { ecommSwitch } from 'common/js/util/ecommSwitch';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

const EcommOrderSummaryApp = lazy(() =>
    import(
        /* webpackChunkName: "ecomm-order-summary/v1" */ './EcommOrderSummaryApp'
    )
);

const EcommOrderSummaryLoader = () => {
    if (!ecommSwitch()) return null;
    if (tireDetailPageSwitch()) return null;

    const appRoot = document.getElementById('ecommOrderSummaryApp');

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner />}>
                  <EcommOrderSummaryApp />
              </Suspense>,
              appRoot
          )
        : null;
};

export { EcommOrderSummaryLoader };
