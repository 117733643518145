import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { ecommSwitch } from 'common/js/util/ecommSwitch';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

const EcommCartApp = lazy(() => import(/* webpackChunkName: "ecomm-cart/v2" */ './EcommCartApp'));

const EcommCartLoaderV2 = (props) => {
    if (!ecommSwitch()) return null;
    if (!tireDetailPageSwitch()) return null;

    const spinner = (props?.noSpinner && <></>) || <DefaultSpinner />;

    // const appRoot = document.getElementById('ecommCartApp');
    const appRoot = document.getElementsByTagName('header')[0];

    return appRoot
        ? createPortal(
              <Suspense fallback={spinner}>
                  <EcommCartApp />
              </Suspense>,
              appRoot
          )
        : null;
};

export { EcommCartLoaderV2 };
