/**
 * A custom react hook to provide props data to a component.
 * The initial value of the state will be undefined then it will be updated by a useState which will be passed to the context provider.
 * The createContext will be called in this component. The context provider will be called in the parent component.
 * The state setter will be called by a useEffect hook.
 * The useEffect hook will be called by the component.
 * The component will be rendered by the parent component.
 *
 * @module UseProductPrice
 *
 */

import React, { createContext, useContext } from 'react';

export const ProductPriceData = {};

const initialState = {
    productPriceData: ProductPriceData,
    setProductPriceData: () => {}
};

const ProductPriceContext = createContext(initialState);

// The provider will take a value prop which will be passed to consuming components that are descendants of this provider.
export const ProductPriceProvider = ({ value, children }) => {
    return <ProductPriceContext.Provider value={value}>{children}</ProductPriceContext.Provider>;
};

const useProductPrice = () => {
    const context = useContext(ProductPriceContext);

    if (context === undefined) {
        throw new Error('useProductPrice must be used within a ProductPriceProvider');
    }

    return context;
};

export default useProductPrice;
