import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik/dist/index';
import * as Yup from 'yup';
import { navigate } from '@gatsbyjs/reach-router';

import PasswordInput from 'common/components/content/react-shared/elements/password-input/v1/PasswordInput';
import TextInput from 'common/components/content/react-shared/elements/text-input/v1/TextInput';
import { updatePassword } from 'common/js/data/services/account/user';
import { PasswordMeter } from 'common/components/content/react-shared/components/password-meter/PasswordMeter';
import { FormActionsWrap } from 'common/components/content/react-shared/ui/FormActionsWrap/FormActionsWrap';
import Button from 'common/components/content/react-shared/elements/button/v1/Button';

import { accountSwitch } from 'common/js/util/accountSwitch';
import { SystemErrorPrompt } from 'common/components/content/react-shared/components/sign-in/prompts/SystemErrorPrompt';
import { EmailConfirmationRequiredPrompt } from 'common/components/content/react-shared/components/sign-in/prompts/EmailConfirmationRequiredPrompt';
import { AccessTokenInvalidPrompt } from 'common/components/content/react-shared/components/verify-email/prompts/AccessTokenInvalidPrompt';
import { ConfirmationCodeInvalidPrompt } from 'common/components/content/react-shared/components/verify-email/prompts/ConfirmationCodeInvalidPrompt';
import { loginNameCookie } from 'common/js/data/services/serviceUtilities';
import { getCookie } from 'common/js/library/util';
import { emailSanitizer } from 'common/js/util/emailSanitizer';

var IS_ACCOUNT_V3 = accountSwitch();

const EMAIL_CONFIRMATION_REQUIRED = 'EMAIL_CONFIRMATION_REQUIRED';
const SYSTEM_ERROR = 'SYSTEM_ERROR';
const ACCESS_TOKEN_INVALID = 'ACCESS_TOKEN_INVALID';
const CONFIRMATION_CODE_INVALID = 'CONFIRMATION_CODE_INVALID';

export function test3of4 (value) {
    let fails = 4;
    if (value) {
        if (value.match(/[a-z]/g)) {
            fails = fails - 1;
        }

        if (value.match(/[A-Z]/g)) {
            fails = fails - 1;
        }

        if (value.match(/\d/)) {
            fails = fails - 1;
        }

        if (value.match(/[-!$%^&*()\/_+|~=`{}[\]:;?,.@#]/g)) {
            fails = fails -1;
        }
    }

    return fails < 1 ? true : false;
}

export function ensureNoDisallowedCharacters(value) {
    if (value) {
        const backslashTest = new RegExp(/\\/, "g");
        if (backslashTest.test(value)) {
            return false;
        } else {
            return true
        }
    }
}

const changePasswordSchema = Yup.object().shape({
	email: Yup.string().email().matches(/[a-zA-Z0-9!#$%&‘*+\–\/=?^_`.{|}~]/g).required("Please enter your current login email"),
    currentPassword: Yup.string().required("Please enter your current password"),
    newPassword: Yup.string().required("Please enter a new password")
                        .min(8, "Password must be at least 8 characters")
                        .test({
                            name: 'disallowedCharacterTest',
                            exclusive: false,
                            message: 'Password contains invalid characters',
                            test: ensureNoDisallowedCharacters
                        })
                        .test({
                            name: 'is3of4',
                            exclusive: false,
                            message: 'is not valid',
                            test: test3of4
                        }),
    newPasswordConfirm: Yup.string()
                        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
						.required("You must enter in the password Confirmation")
});

const ChangePasswordForm = (props) => {

	const [ formError, setFormError ] = useState({
		hasError: false,
		message: null
	});

    const { closeModal, updateSuccess } = props;

    const handleEmailConfirmationRequired = (email, tokenId) => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: EMAIL_CONFIRMATION_REQUIRED,
            email,
            tokenId
        });
    };

    const handleSystemError = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: SYSTEM_ERROR
        });
    };

    const handleTokenIdInvalid = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: ACCESS_TOKEN_INVALID
        });
    };

    const handleConfirmationCodeInvalid = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: CONFIRMATION_CODE_INVALID
        });
    };

    const handleCloseModal = (e) => {
        e.preventDefault();
        closeModal();
	}

	const handleChangePasswordSubmit = async (values, actions) => {
		try {

            const cookieLoginEmail = getCookie(loginNameCookie) || window.siteProfile.userId || '';
            const loginEmail = emailSanitizer(cookieLoginEmail);

            const response = await updatePassword(loginEmail, values.currentPassword, values.newPassword, props?.tokenId);

            if (!response) {
                handleSystemError();
            }

            IS_ACCOUNT_V3 = response?.version === 'v3';

			if (response.success === "true") {

				updateSuccess(response?.data);
			} else {
				console.log("false response success");

                if (response.status === '520') {
                    if (IS_ACCOUNT_V3 &&
                        response?.message?.error === 'Internal Error') {
                        handleSystemError();
                    }
                }

				if (response.status === "sessionExpired") {
					navigate("/account/sign-in");
                    window.location.reload();
				}

                if (response.status === "053") {
                    if (IS_ACCOUNT_V3 &&
                        response?.message?.error === 'Invalid Login') {
                        setFormError({
                            hasError: true,
                            message: 'Username or password is invalid'
                        });
                    } else if (IS_ACCOUNT_V3 && response.message?.error === 'Email confirmation required') {
                        handleEmailConfirmationRequired(loginEmail, response?.data?.tokenId);
                    } else if (response?.message?.error === 'Access Token is invalid. Try again.') {
                        handleTokenIdInvalid();
                    } else if (response?.message?.error === 'Confirmation code is invalid. Try again.') {
                        handleConfirmationCodeInvalid();
                     } else {
                        // if new password equals the old password
                        setFormError({
                            hasError: true,
                            message: "Please set a new password different from your previous password"
                        })
                        actions.setSubmitting(false);
                    }
				}
			}

		} catch (err) {
			console.log("there's an error in the ChangePasswordForm handleChangePasswordSubmit function: ", {err});
            handleSystemError();
		}
	}

    // Excluding the email and password confirm field from the form validation.
    if (IS_ACCOUNT_V3) {
        delete changePasswordSchema?.fields?.email;
        delete changePasswordSchema?.fields?.newPasswordConfirm;
    }

    return (
        <div className="change-password">
            {formError.hasError && formError?.errorType === EMAIL_CONFIRMATION_REQUIRED && <EmailConfirmationRequiredPrompt email={formError?.email} tokenId={formError?.tokenId} />}
            {formError.hasError && formError?.errorType === ACCESS_TOKEN_INVALID && <AccessTokenInvalidPrompt />}
            {formError.hasError && formError?.errorType === CONFIRMATION_CODE_INVALID && <ConfirmationCodeInvalidPrompt />}
            {formError.hasError && formError?.errorType === SYSTEM_ERROR && <SystemErrorPrompt />}
            {!formError?.errorType &&
                <div>
                    <h2>Change Password</h2>
                    <p>To change your password please first enter your current password below. Then enter a new password below, confirm the new password, and press Submit.</p>
                    {formError.hasError ?
                    <p style={{ color: "red" }}>{formError.message}</p>
                    : null
                    }
                    <Formik
                        initialValues={{email: '', currentPassword: '', newPassword: '', newPasswordConfirm: ''}}
                        onSubmit={handleChangePasswordSubmit}
                        validationSchema={changePasswordSchema}
                        render={({ isSubmitting, values, touched, isValid }) => (
                            <Form id="changePasswordForm">
                                {!IS_ACCOUNT_V3 && <Field name="email" id="changePasswordEmail" component={TextInput} placeholder="Current login email" /> }
                                <Field name="currentPassword" id="changePasswordCurrentPassword" component={PasswordInput} placeholder="Current Password" />
                                <Field name="newPassword" id="newPassword" component={PasswordInput} placeholder="New password" />
                                {!IS_ACCOUNT_V3 && <Field name="newPasswordConfirm" id="changePasswordNewPasswordConfirm" component={PasswordInput} placeholder="Confirm new password"/> }
                                <PasswordMeter isTouched={touched['newPassword'] || values['newPassword'].length} pwValue={values['newPassword']} />
                                <FormActionsWrap
                                    primaryActionButton={<Button styleName="sm" type="submit" disabled={(isSubmitting || !isValid)}>Submit</Button>}
                                    secondaryActionButton={<Button styleName="secondary sm" clickAction={handleCloseModal} disabled={isSubmitting}>Cancel</Button>}
                                />
                            </Form>
                        )}
                    />
                </div>
            }
        </div>
    );
}

export { ChangePasswordForm }
