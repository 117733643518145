export const tireDecisionGuideSwitch = () => {
    //////////////////
    // TDG ON
    //////////////////
    if (navigator.cookieEnabled) {

        if (localStorage.getItem('tireDecisionGuideIsActive') === 'false') {
            return false;
        }
        
    }

    return true;

    ///////////////
    // TDG OFF
    ///////////////
    // if (localStorage.getItem('tireDecisionGuideIsActive') === 'true') {
    //     return true;
    // }

    // return false;
};
