import React, { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { SwitchableApp } from 'common/components/content/react-shared/utils/SwitchableApp';

/**
 * Dynamically imports the MyStoreApplication component.
 *
 * @returns {Promise} A promise that resolves to the imported MyStoreApplication component.
 */
const importApp = () => {
    return import(/* webpackChunkName: "my-store/v1" */ './MyStoreApplication');
};

/**
 * SwitchableApp component for the MyStoreApplication with feature switch class and import callback.
 */
const MyStoreStandaloneRedux = SwitchableApp({
    featureSwitchClass: 'bsro-ui-global-nav-v2',
    importCallback: importApp
});

/**
 * MyStoreLoader component that renders the MyStoreApplication component inside a Suspense fallback.
 *
 * @returns {JSX.Element|null} The rendered component or null if the app root is not found.
 */
const MyStoreLoader = () => {
    const appRoot = document.getElementById('my-store-app');

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner />}>
                  <MyStoreStandaloneRedux />
              </Suspense>,
              appRoot
          )
        : null;
};

export { MyStoreLoader };
