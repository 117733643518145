import React, { useContext } from 'react';
import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';
import { CreateAccountForm } from 'common/components/content/react-shared/components/create-account/CreateAccountForm';
import { SignInForm } from 'common/components/content/react-shared/components/sign-in/SignInForm';
import { ForgotPasswordForm } from 'common/components/content/react-shared/components/forgot-password/ForgotPasswordForm';

const SignInButtonApp = (props) => {
    const { setGlobalModal } = useContext(ModalContext);
    const { label } = props;

    const onSignIn = (e) => {
        e.preventDefault();

        setGlobalModal(
            <SignInForm
                createAccount={onSignUp}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={onSignInSuccess}
            />
        );
    };

    const onSignUp = (e) => {
        e.preventDefault();

        setGlobalModal(
            <CreateAccountForm
                openSignInForm={onSignIn}
                closeModal={() => setGlobalModal()}
                onCreateAccountSuccess={onCreateAccountSuccess}
            />
        );
    };

    const onSignInSuccess = () => {
        window.location.reload();
    };

    const onCreateAccountSuccess = () => {
        setGlobalModal();
        window.location.reload();
    };

    const onForgotPasswordSuccess = () => {
        setGlobalModal(
            <SignInForm
                createAccount={onSignUp}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={onSignInSuccess}
            />
        );
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        setGlobalModal(
            <ForgotPasswordForm
                openSignIn={onSignIn}
                closeModal={() => setGlobalModal()}
                onForgotPasswordSuccess={onForgotPasswordSuccess}
            />
        );
    };

    return (
        <button className="signin-button" onClick={onSignIn}>
            {label}
        </button>
    );
};

export { SignInButtonApp };
