import '../scss/fifty-fifty-basic.scss';

document.addEventListener('DOMContentLoaded', function () {
    // Function to toggle class on click
    function toggleClassOnClick() {
        if (document.body.classList.contains('local-seo-page')) {
            const titles = document.querySelectorAll('.local-seo-page h2.title');
            titles.forEach((title) => {
                title.addEventListener('click', function () {
                    console.log('click');
                    this.closest('.copy').classList.toggle('expanded');
                });
            });
        }
    }
    toggleClassOnClick();
});
