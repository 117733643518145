import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { expeditedBookingSwitch } from 'common/js/util/expeditedBookingSwitch';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';

const ExpeditedBookingApp = lazy(() => import(/* webpackChunkName: "expedited-booking/v2" */ './ExpeditedBookingApp'));

const ExpeditedBookingLoaderV2 = () => {
    // console.log('************************************');

    console.log('ExpeditedBookingLoader');
    const appRoot = document.getElementById('expedited-booking-app-v2');
    const customHeading = appRoot ? appRoot?.parentElement?.getAttribute('data-heading') : '';
    const apptService =
        (appRoot && { id: appRoot.getAttribute('data-service-id'), name: appRoot.getAttribute('data-service-name') }) ||
        null;
    // console.log('===================================');
    // console.log('service: ', apptService);

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner styles={{ margin: '0', height: '100%' }} />}>
                  <ExpeditedBookingApp apptService={apptService} customHeading={customHeading} basePath="" />
              </Suspense>,
              appRoot
          )
        : null;
};

export { ExpeditedBookingLoaderV2 };
