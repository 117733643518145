import React, { useState, useEffect, lazy, Suspense } from 'react';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

const InterruptAnnexApp = lazy(() => import(/* webpackChunkName: "interrupt-annex/v1" */ './InterruptAnnexApp'));

const InterruptAnnexLoader = () => {
    console.log('InterruptAnnexLoader');
    const initInterrupt = document.querySelector('[data-interrupt-id]');

    const interruptAnnex = (appRoot) => {
        const uniqueId = `${new Date().getTime()}`;
        const isMatch = appRoot && appRoot.dataset && appRoot.dataset.interruptId === 'interrupt-add-to-cart';
        const btnRoot = isMatch ? appRoot.querySelector('.ctas .btn') : null;

        if (appRoot) {
            appRoot.classList.add('app-global');
        }

        if (btnRoot) {
            console.log('interrupt-add-to-cart match found');

            return (
                <React.Fragment>
                    <DynamicPortal
                        id={`interrupt-portal-${uniqueId}`}
                        className="interrupt-annex"
                        dynamicParentElement={btnRoot}
                        hideParent={true}
                    >
                        <Suspense fallback={<></>}>
                            <InterruptAnnexApp appRoot={appRoot} />
                        </Suspense>
                    </DynamicPortal>
                </React.Fragment>
            );
        } else {
            console.log('interrupt-add-to-cart match not found');
            return null;
        }
    };

    const [root, setRoot] = useState(initInterrupt);
    const [portal, setPortal] = useState(interruptAnnex(root));

    useEffect(() => {
        setPortal(interruptAnnex(root));
    }, [root]);

    pubsub.subscribe(topics.CREATE_INTERRUPT, (el) => {
        console.log('create interrupt pubsub received');
        setRoot(el);
    });

    return portal ? portal : null;
};

export { InterruptAnnexLoader };
