import React from 'react';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';

const DesktopIcon = (props) => {
    const { isOpen, updateCartState, counter, disable } = props;
    const counterValue = counter ? 1 : 0;

    const handleClick = () => {
        updateCartState(true);
    };

    return (
        <>
            {disable ? (
                <div className="ecomm-cart-icon disabled"></div>
            ) : (
                <button className="ecomm-cart-icon" onClick={() => handleClick()}>
                    <div className={`counter counter-${counterValue}`}>{counterValue}</div>
                    <SVG
                        src="https://s7d1.scene7.com/is/content/bridgestone/cart-desk-mob-icon-global-web-bsro"
                        className="icon-cart"
                    />
                    <span className="ecomm-cart-text">My Cart</span>
                </button>
            )}
        </>
    );
};

DesktopIcon.defaultProps = {
    disable: false
};

DesktopIcon.propTypes = {
    counter: PropTypes.bool,
    isOpen: PropTypes.bool,
    disable: PropTypes.bool,
    updateCartState: PropTypes.func.isRequired
};

export { DesktopIcon };
