import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckboxInput = ({
    field: { name, value, onChange, onBlur },
    form,
    id,
    defaultChecked,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                name={name}
                id={id}
                type="checkbox"
                value={value} // could be something else for output?
                defaultChecked={defaultChecked}
                onChange={onChange}
                onBlur={onBlur}
                className={classNames('checkbox-button', className)}
                {...props}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}></label>
        </div>
    );
};

CheckboxInput.propTypes = {
    field: PropTypes.object,
    id: PropTypes.string,
    defaultChecked: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string
};

export default CheckboxInput;
