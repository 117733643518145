import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const checkQueryStrings = () => {
    const queryString = getUrlParameter('bsro-ui-tsr') || getUrlParameter('tsr') || '';

    if (queryString === 'v2' || queryString === '2') {
        setCookie('bsro-ui-tsr', 'v2');
    } else if (queryString === 'v1' || queryString === '1') {
        setCookie('bsro-ui-tsr', 'v1');
    }
};

export const tireSearchResultsSwitch = () => {
    checkQueryStrings();

    const tsrVersion = getCookie('bsro-ui-tsr');
    console.log('tireSearchResultsSwitch', { cookie: tsrVersion, isOn: tsrVersion === 'v2' });

    //////////////////
    // TSR ON
    //////////////////
    if (tsrVersion && tsrVersion === 'v1') {
        return false;
    }

    return true;

    ///////////////
    // TSR OFF
    ///////////////
    // if (tsrVersion && tsrVersion === 'v2') {
    //     return true;
    // }

    // return false;
};
