import { compareSetI18n, compareSetLoggedIn } from 'common/js/data/redux/actionCreators/tire-comparison';
import { isUserLoggedIn } from 'common/js/data/services/account/user';

const tireComparisonReduxLoader = async (store) => {
    console.log('tireComparisonReduxLoader');

    try {

        // place any redux initialization code here.
        const tireComparisonApp = document.getElementById('tire-comparison-app');
        const i18n = JSON.parse(tireComparisonApp.dataset.i18n);
        store.dispatch(compareSetI18n(i18n));

        // Is user logged in?
        const isLoggedIn = await isUserLoggedIn();
        store.dispatch(compareSetLoggedIn(isLoggedIn));
    } catch (err) {
        console.log('tireComparisonReduxLoader failed:', err);
    }
};

export { tireComparisonReduxLoader };
