import { getSiteData } from 'common/js/util/siteData';
console.group('fcac config');
// constants come from WebPack build.
const siteId = SITE_NAME || 'unknown';
const buildType = BUILD_TYPE || 'prod';
const debug = buildType === 'dev';
const initSiteProfile = {
    location: {
        autoZip: '',
        locationLvl: 1,
        myCity: '',
        myState: '',
        myZip: '61614',
        storeNumber: '244190'
    },
    site: '',
    tires: {
        main: {},
        tce: {}
    },
    vehicles: {
        aux: {},
        main: {},
        tce: {}
    },
    vid: ''
};

let siteData = getSiteData(siteId);
window.AEM = Object.assign({}, window.AEM, siteData);
window.BSRO = Object.assign({}, window.BSRO, siteData, { debug, buildType });

// if no siteProfile exists, initialize it.
window.siteProfile = Object.assign({}, initSiteProfile, window.siteProfile);
console.groupEnd();
