import { getInventoryPricing } from 'common/js/data/services/ecomm/quote';

/**
 * Request for Inventory and Pricing by store number and either vehicle or tire size
 * @param {string} store - the store number
 * @param {object} searchCriteria - either {year, make, model, trim/submodel} or {crossSection, aspectRatio, rimSize}
 * @returns object
 */
const requestInventoryPricing = async (store, searchCriteria) => {
    let responseObject = null;

    try {
        const response = await getInventoryPricing(store, searchCriteria);
        console.log('requestInventoryPricing:', { response });
        if (response?.success === 'false') {
            // TODO: populate an error object in the Redux store to trigger an error modal
        } else {
            responseObject = response.data.products;
        }
    } catch (err) {
        console.log(err);
    }

    return responseObject;
};

export { requestInventoryPricing };
