import { debounce } from 'common/js/util/debounce';
import { metaSetWindowSize } from './../actionCreators/meta';

const reduxMetaBootstrap = (store) => {
    const windowSizing = {
        height: window.innerHeight,
        width: window.innerWidth
    };

    store.dispatch(metaSetWindowSize(windowSizing));

    window.addEventListener(
        'resize',
        debounce(() => {
            const newWindowSizing = {
                height: window.innerHeight,
                width: window.innerWidth
            };
            store.dispatch(metaSetWindowSize(newWindowSizing));
        }, 100)
    );
};

export { reduxMetaBootstrap };
