import React, { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { SwitchableApp } from 'common/components/content/react-shared/utils/SwitchableApp';

/**
 * Dynamically imports the MyGarageApp component.
 *
 * @returns {Promise} A promise that resolves to the imported MyGarageApp component.
 */
const importApp = () => {
    return import(/* webpackChunkName: "my-garage/v1" */ './MyGarageApp');
};

/**
 * Connects the MyGarageApp component with feature switch functionality.
 *
 * @returns {JSX.Element} The connected MyGarageApp component.
 */
const MyGarageAppConnected = SwitchableApp({ featureSwitchClass: 'bsro-ui-global-nav-v2', importCallback: importApp });

/**
 * MyGarageLoader component that loads the MyGarageApp component.
 *
 * @returns {JSX.Element|null} The rendered component or null if the app root is not found.
 */
const MyGarageLoader = () => {
    console.log('myGarageLoader');

    const appRoot = document.getElementById('myGarageApp');

    return appRoot
        ? createPortal(
              <Suspense fallback={<></>}>
                  <MyGarageAppConnected />
              </Suspense>,
              appRoot
          )
        : null;
};

export { MyGarageLoader };
