import React, { useContext } from 'react';

import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';
import { removeCookie, getCookie } from 'common/js/library/util';
import { loginNameCookie } from 'common/js/data/services/serviceUtilities';

import { logoutUser } from 'common/js/data/services/account/user';

import { CreateAccountForm } from 'common/components/content/react-shared/components/create-account/CreateAccountForm';
import { SignInForm } from 'common/components/content/react-shared/components/sign-in/SignInForm';
import { ForgotPasswordForm } from 'common/components/content/react-shared/components/forgot-password/ForgotPasswordForm';
import { CreateNewQuote } from 'common/js/util/CreateNewQuote';

const MobileMenuApp = (props) => {

    const { setGlobalModal } = useContext(ModalContext);

    const {
		driverName,
		isLoggedIn,
		isTDP
    } = props;

	const handleSignInSuccess = async () => {
		if (isTDP) {
			await CreateNewQuote("addCart");
		} else {
			window.location.reload();
		}
	}

	const handleSignIn = (e) => {
		e.preventDefault();
        setGlobalModal(<SignInForm
            createAccount={handleCreateAccount}
            forgotPassword={handleForgotPassword}
            closeModal={() => setGlobalModal()}
            onSignInSuccess={handleSignInSuccess}
        />)
	}

	const handleCreateAccountSuccess = async () => {
        setGlobalModal();

		if (isTDP) {
            await CreateNewQuote("addCart");
        } else {
            window.location.reload();
        }
	}

	const handleCreateAccount = (e) => {
		e.preventDefault();
        setGlobalModal(<CreateAccountForm
            openSignInForm={handleSignIn}
            closeModal={() => setGlobalModal()}
            onCreateAccountSuccess={handleCreateAccountSuccess}
        />);
	}

	const handleForgotPasswordSuccess = (par) => {
        setGlobalModal(<SignInForm createAccount={handleCreateAccount} forgotPassword={handleForgotPassword} closeModal={() => setGlobalModal()} onSignInSuccess={handleSignInSuccess} />)
	}

	const handleForgotPassword = (e) => {
		e.preventDefault();
        setGlobalModal(<ForgotPasswordForm openSignIn={handleSignIn} closeModal={() => setGlobalModal()} onForgotPasswordSuccess={handleForgotPasswordSuccess} />);
	}

	const handleSignOut = async (e) => {
		e.preventDefault();

		await logoutUser({tokenId: getCookie('tokenId')});

		removeCookie([loginNameCookie, 'tokenId']);

		if (window.location.pathname.startsWith('/account') || window.location.pathname.startsWith('account')) {
			window.location.href = '/';
		} else if (isTDP) {
            // will redirect to TDP with new quote id
            await CreateNewQuote("removeCart");
        } else {
			window.location.reload(true);
		}
	}

    return (
        <>
			{(isLoggedIn === "true") ?
				<>
					<a className="btn" href="/account">{driverName}</a>
					<br/>
					<a className="btn" onClick={handleSignOut}>Sign Out</a>
				</>
			:
				<>
					<a className="btn" onClick={handleCreateAccount}>Create an Account</a>
					<br/>
					<a className="btn" onClick={handleSignIn}>Sign In</a>
				</>
			}
        </>
    );
}

export { MobileMenuApp }
