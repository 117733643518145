import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';

const EcommCheckoutApp = lazy(() =>
    import(/* webpackChunkName: "ecomm-checkout/v1" */ './EcommCheckoutApp')
);

const EcommCheckoutLoader = () => {
    const appRoot = document.getElementById('ecommCheckoutApp');

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner />}>
                  <EcommCheckoutApp />
              </Suspense>,
              appRoot
          )
        : null;
};

export { EcommCheckoutLoader };
