const tireDecisionGuideReduxLoader = async (store) => {
    // try {
    // any initial Redux store setup goes here
    console.log('tireDecisionGuideReduxLoader');
    // } catch (err) {
    // console.log('tireDecisionGuideReduxLoader failed:', err);
    // }
};

export { tireDecisionGuideReduxLoader };
