import React from 'react';

import WindowSize from '@reach/window-size';
import { DesktopMenuApp } from './desktop/DesktopMenuApp';
import { MobileMenuApp } from './mobile/MobileMenuApp';
import { ToolTipMessageApp } from './tooltip/ToolTipMessageApp';

const MenuApp = (props) => {
    const { driverName, driverFirstName, isLoggedIn, isMobile, isTDP, serviceHistoryInvoice } = props;

    return (
        <WindowSize>
            {({ width, height }) => (
                <>
                    {width < 680 ? (
                        <MobileMenuApp driverName={driverName} isLoggedIn={isLoggedIn} isTDP={isTDP} />
                    ) : (
                        <DesktopMenuApp
                            driverName={driverName}
                            driverFirstName={driverFirstName}
                            isLoggedIn={isLoggedIn}
                            isTDP={isTDP}
                            serviceHistoryInvoice={serviceHistoryInvoice}
                            isHeaderV2={false}
                        />
                    )}
                </>
            )}
        </WindowSize>
    );
};

const MenuAppV2 = (props) => {
    const { driverName, driverFirstName, isLoggedIn, isMobile, isTDP, serviceHistoryInvoice } = props;

    return (
        <DesktopMenuApp
            driverName={driverName}
            driverFirstName={driverFirstName}
            isLoggedIn={isLoggedIn}
            isTDP={isTDP}
            serviceHistoryInvoice={serviceHistoryInvoice}
            isHeaderV2={true}
        />
    );
};

const ToolTipApp = (props) => {
    const { hasVisitedSite } = props;
    return <ToolTipMessageApp hasVisitedSite={hasVisitedSite} />;
};

export { MenuApp, MenuAppV2, ToolTipApp };
