import React from 'react';

export const ExclusiveOfferIcon = () => {
    return (
        <img
            className="product-price__offer-icon exclusive"
            src="https://s7d1.scene7.com/is/content/bridgestone/exclusive-offer-icon-global-web-bsro"
            alt="Exclusive Offer!"
        />
    );
};

export const SpecialOfferIcon = () => {
    return (
        <img
            className="product-price__offer-icon special"
            src="https://s7d1.scene7.com/is/content/bridgestone/special-offer-icon-global-web-bsro"
            alt="Special Offer!"
        />
    );
};

export const TagIcon = () => {
    return (
        <span>
            <img
                className="product-price__tag-icon"
                src="https://s7d1.scene7.com/is/content/bridgestone/tag-badge-outline-icon-web-global-bsro"
                alt="Offer Tag!"
            />
        </span>
    );
};
