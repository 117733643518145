export const getSiteData = (siteId) => {
    let siteData = {};
    switch (siteId) {
        case 'fcac':
            siteData = Object.assign({}, siteData, { siteId, siteName: 'fcac', defaultZip: '80122' });
            break;
        case 'tiresplus':
            siteData = Object.assign({}, siteData, { siteId, siteName: 'tiresplus', defaultZip: '61614' });
            break;
        case 'hibdontire':
            siteData = Object.assign({}, siteData, { siteId, siteName: 'hibdontire', defaultZip: '74055' });
            break;
        case 'wheelworks':
            siteData = Object.assign({}, siteData, { siteId, siteName: 'wheelworks', defaultZip: '95060' });
            break;
        case 'lsp':
            siteData = Object.assign({}, siteData, { siteId, siteName: 'lsp', defaultZip: '80122' });
            break;
        default:
            siteData = Object.assign({}, siteData, { siteId: 'unknown', siteName: 'unknown', defaultZip: '11111' });
            break;
    }
    return siteData;
};
