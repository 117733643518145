import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import { MenuAppRedux, MenuAppReduxV2, ToolTipRedux } from './MenuAppRedux';

const mapState = (state) => {
    return {
        windowWidth: state.meta.windowDimensions.width
    };
};

const Menu = (props) => {
    const { windowWidth } = props;

    const isMobile = windowWidth < 680;

    const rootDiv = isMobile
        ? document.querySelector('.mobile-only[data-app="account-menu"]')
        : document.querySelector('[data-app="account-menu"]:not(.mobile-only)');

    if (rootDiv) {
        return createPortal(<MenuAppRedux isMobile={isMobile} />, rootDiv);
    } else {
        return null;
    }
};

const MenuV2 = (props) => {
    const { windowWidth } = props;

    const isMobile = windowWidth < 768;

    const rootDiv = document.getElementById('desktopMenuApp-v2');

    if (rootDiv) {
        return createPortal(<MenuAppReduxV2 isMobile={isMobile} />, rootDiv);
    } else {
        return null;
    }
};

const ToolTip = (props) => {
    const rootDiv = document.getElementById('toolTipApp');

    if (rootDiv) {
        return createPortal(<ToolTipRedux />, rootDiv);
    } else {
        return null;
    }
};

const MenuLoader = connect(mapState, null)(Menu);
const MenuLoaderV2 = connect(mapState, null)(MenuV2);
const ToolTipLoader = connect(mapState, null)(ToolTip);

export { MenuLoader, MenuLoaderV2, ToolTipLoader };
