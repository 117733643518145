import React, { lazy, Suspense, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';

const AccountApp = lazy(() => import(/* webpackChunkName: "account-chunk/v1" */ './AccountApp'));

const addDummyHistoryEntry = () => {
    if (document.referrer !== '' && document.referrer.indexOf('/account' === -1)) {
        console.log("ADDING HISTORY DUMMY ENTRY");
        window.history.pushState(null, "", window.location.href);
    }
}

const AccountLoader = (props) => {
    const accountRoot = document.getElementById('accountApp');

    useEffect(() => {
        console.log("document.referrer: ", document.referrer);

        addDummyHistoryEntry();
    }, [])

    return accountRoot ? createPortal(<Suspense fallback={<DefaultSpinner />}><AccountApp /></Suspense>, accountRoot) : null;
}

export { AccountLoader }
