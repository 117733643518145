import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const checkQueryStrings = () => {
    const queryString = getUrlParameter('bsro-ui-appt') || '';

    if (queryString === 'v2' || queryString === '2') {
        // force alternate appointment schedule flow
        setCookie('bsro-ui-appt', 'v2');
        return true;
    } else if (queryString === 'v3' || queryString === '3') {
        setCookie('bsro-ui-appt', 'v3');
        return true;
    } else if (queryString === 'v4' || queryString === '4') {
        setCookie('bsro-ui-appt', 'v4');
        return true;
    } else {
        return false;
    }
};

export const appointmentFlowSwitch = () => {
    // enable a way to trigger alternate appointment schedule flow, even if it's turned off.
    checkQueryStrings();

    const apptVersion = getCookie('bsro-ui-appt');

    console.log('appointmentFlowSwitch', {
        apptCookie: apptVersion,
        apptIsOn: apptVersion === 'v2' || apptVersion === 'v3' || apptVersion === 'v4',
        test: 'test'
    });

    //////////////////
    // Alternate Appointment Schedule Flow ON
    //////////////////
    if (apptVersion && (apptVersion === 'v2' || apptVersion === 'v3' || apptVersion === 'v4')) {
        return true;
    }

    return false;
};
