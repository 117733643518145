import { reactAppLoader } from '../../../react-app-loader/v1/js/react-app-loader';
import { getUIVersion, reportUIVersion } from 'common/js/library/version';
import { accountLoader } from 'common/components/content/account/v1/js/accountReduxLoader';
import { accountSwitch } from 'common/js/util/accountSwitch';
import { appointmentFlowSwitch } from 'common/js/util/appointmentFlowSwitch';
import { ecommCartIconReduxLoader } from 'common/components/content/ecomm-cart-icon/v1/js/ecommCartIconReduxLoader';
import { ecommCartReduxLoader } from 'common/components/content/ecomm-cart/v1/js/ecommCartReduxLoader';
import { ecommCartReduxLoaderV2 } from 'common/components/content/ecomm-cart/v2/js/ecommCartReduxLoader';
import { ecommCheckoutReduxLoader } from 'common/components/content/ecomm-checkout/v1/js/EcommCheckoutReduxLoader';
import { ecommOrderSummaryReduxLoader } from 'common/components/content/ecomm-order-summary/v1/js/EcommOrderSummaryReduxLoader';
import { ecommOrderSummaryReduxLoaderV2 } from 'common/components/content/ecomm-order-summary/v2/js/EcommOrderSummaryReduxLoader';
import { ecommQuoteAnnexReduxLoader } from 'common/components/content/ecomm-quote-annex/v1/js/ecommQuoteAnnexReduxLoader';
import { ecommQuoteAnnexReduxLoaderV2 } from 'common/components/content/ecomm-quote-annex/v2/js/ecommQuoteAnnexReduxLoader';
import { ecommSwitch } from 'common/js/util/ecommSwitch';
import { expeditedBookingReduxLoader } from 'common/components/content/expedited-booking/v1/js/ExpeditedBookingReduxLoader';
import { expeditedBookingReduxLoaderV2 } from 'common/components/content/expedited-booking/v2/js/ExpeditedBookingReduxLoader';
import { geolocationSetup } from 'common/js/util/geolocation/setup';
import { interruptAnnexReduxLoader } from 'common/components/content/interrupt-annex/v1/js/interruptAnnexReduxLoader';
import { reduxMetaBootstrap } from 'common/js/data/redux/boostrapUtils/metaBootstrap';
import { reviewsReduxLoader } from 'common/components/content/reviews/v1/js/reviewsReduxLoader';
import { reviewsReduxLoaderV2 } from 'common/components/content/reviews/v2/js/reviewsReduxLoader';
import { reviewsSwitch } from 'common/js/util/reviewsSwitch';
import { store } from 'common/js/data/redux/store/exportedStore';
import { tireComparisonSwitch } from 'common/js/util/tireComparisonSwitch';
import { tireDecisionGuideReduxLoader } from 'common/components/content/tire-decision-guide/v1/js/TireDecisionGuideReduxLoader';
import { tireDecisionGuideSwitch } from 'common/js/util/tireDecisionGuideSwitch';
import { tireComparisonReduxLoader } from 'common/components/content/tire-comparison/v1/js/TireComparisonReduxLoader';
import { tireSearchResultsReduxLoader } from 'common/components/content/tire-search-results/v1/js/TireSearchResultsReduxLoader';
import { tireSearchResultsSwitch } from 'common/js/util/tireSearchResultsSwitch';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';
import { myStoreReduxLoader } from 'common/components/content/my-store/v1/js/MyStoreReduxLoader';
import { myGarageReduxLoader } from 'common/components/content/my-garage/v1/js/MyGarageReduxLoader';
import { vehicleSelectorReduxLoader } from 'common/components/content/schedule-appointment/v2/js/VehicleSelectorReduxLoader';
import { universalVehiclePickerReduxLoader } from 'common/components/content/universal-vehicle-picker/v1/js/UniversalVehiclePickerReduxLoader';

//import { vehicleFilterLoader } from 'common/components/content/vehicle-filter/v1/js/loader';

const uiVersion = getUIVersion();
console.group(`BSRO UI Version: ${uiVersion}`);
reportUIVersion();
console.groupEnd();

const assetLoader = async () => {
    try {
        geolocationSetup(store);

        console.group('redux bootstrap');
        reduxMetaBootstrap(store);
        accountLoader(store);
        console.groupEnd();

        console.group('react features');
        reactAppLoader(store);
        console.groupEnd();

        const isAccountV2 = accountSwitch();
        console.log('isAccountV2:', isAccountV2);

        const isApptV2 = appointmentFlowSwitch();
        console.log('isApptV2:', isApptV2);

        const isTDPV2 = tireDetailPageSwitch();
        console.log('isTDPV2:', isTDPV2);

        if (tireSearchResultsSwitch()) {
            console.group('tire-search-results');
            tireSearchResultsReduxLoader(store);
            console.groupEnd();

            if (tireComparisonSwitch()) {
                console.group('tire-comparison');
                tireComparisonReduxLoader(store);
                console.groupEnd();
            }
        }

        console.group('expedited-booking-widget-v2');
        expeditedBookingReduxLoaderV2(store);
        console.groupEnd();

        console.group('expedited-booking-widget');
        expeditedBookingReduxLoader(store);
        console.groupEnd();

        if (ecommSwitch()) {
            if (tireDetailPageSwitch()) {
                console.group('ecomm-cart-v2');
                ecommCartReduxLoaderV2(store);
                console.groupEnd();

                console.group('ecomm-order-summary-v2');
                ecommOrderSummaryReduxLoaderV2(store);
                console.groupEnd();

                console.group('ecomm-quote-annex-v2');
                ecommQuoteAnnexReduxLoaderV2(store);
                console.groupEnd();
            } else {
                console.group('ecomm-cart');
                ecommCartReduxLoader(store);
                console.groupEnd();

                console.group('ecomm-order-summary');
                ecommOrderSummaryReduxLoader(store);
                console.groupEnd();

                console.group('ecomm-quote-annex');
                ecommQuoteAnnexReduxLoader(store);
                console.groupEnd();
            }

            console.group('ecomm-cart-icon');
            ecommCartIconReduxLoader(store);
            console.groupEnd();

            console.group('ecomm-checkout');
            ecommCheckoutReduxLoader(store);
            console.groupEnd();

            console.group('interrupt-annex');
            interruptAnnexReduxLoader(store);
            console.groupEnd();
        }

        if (reviewsSwitch()) {
            console.group('reviews');
            reviewsReduxLoader(store);
            console.groupEnd();

            console.group('reviews-v2');
            reviewsReduxLoaderV2(store);
            console.groupEnd();
        }

        if (tireDecisionGuideSwitch()) {
            console.group('tire-decision-guide');
            tireDecisionGuideReduxLoader(store);
            console.groupEnd();
        }

        myStoreReduxLoader(store);
        myGarageReduxLoader(store);
        vehicleSelectorReduxLoader(store);
        universalVehiclePickerReduxLoader(store);
    } catch (err) {
        console.log(err);
    }
};

export default assetLoader;
