import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik/dist/index';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import Button from '../../elements/button/v1/Button';
import TextInput from '../../elements/text-input/v1/TextInput';
import CheckboxInput from '../../elements/checkbox/v1/CheckboxInput';
import PasswordInput from '../../elements/password-input/v1/PasswordInput';
import { PasswordMeter } from '../password-meter/PasswordMeter';
import { MaskInput } from '../../elements/masked-input/v1/MaskedInput';

import { SystemErrorPrompt } from '../sign-in/prompts/SystemErrorPrompt';
import { testEmail } from '../../utils/validation';
import { accountSwitch } from 'common/js/util/accountSwitch';

import { emailSignUp } from 'common/js/data/services/emailSignUp';

import '../sign-in/prompts/_prompts.scss';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { passwordValidationCheck } from 'common/js/library/util.js';
import {
    test3of4,
    ensureNoDisallowedCharacters
} from 'common/components/content/account/v1/js/ui/preferences/ChangePasswordForm';
import { createUser } from 'common/js/data/services/account/user';
import { satelliteTrack } from 'common/js/data/services/analytics/satellite';

import { VehicleRepository, Vehicle, convertVehicleToVehicleFromAccount } from 'common/js/util/vehicle-repository';
import { setAccountVehicles } from 'common/js/data/redux/actionCreators/account';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';

import { isYMMTInList } from 'common/js/util/vehicle-repository/isYMMTInList';

var IS_ACCOUNT_V3 = accountSwitch();

const SYSTEM_ERROR = 'SYSTEM_ERROR';

const createAccountSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name is required')
        .max(50, 'First Name must be 50 characters or less')
        .matches(/^[\p{L}\s\-.']+$/u, 'First Name must contain only letters, spaces, hyphens, dots, or apostrophes'),
    lastName: Yup.string()
        .required('Last Name is required')
        .max(50, 'Last Name must be 50 characters or less')
        .matches(/^[\p{L}\s\-.']+$/u, 'Last Name must contain only letters, spaces, hyphens, dots, or apostrophes'),
    email: Yup.string()
        .required('Email is required to create an account')
        .email('A valid email address is required to create an account'),
    phone: Yup.string(),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .test({
            name: 'disallowedCharacterTest',
            exclusive: false,
            message: 'Password contains invalid characters',
            test: ensureNoDisallowedCharacters
        })
        .test({
            name: 'is3of4',
            exclusive: false,
            message: 'Please meet the password requirements',
            test: test3of4
        })
});

const phoneNumberMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const CreateAccount = (props) => {
    const [formError, setFormError] = useState({
        hasError: false,
        errorMessage: null
    });

    const [touchedFields, setTouchedFields] = useState({});
    const [submitTriggered, setSubmitTriggered] = useState(false);

    useEffect(() => {
        // TRACKING CODE FOR CARDINAL PATH.
        // CAPTURE OPEN SIGN-IN MODAL
        satelliteTrack('account.modal.registration');
    }, []);

    const { openSignInForm, onCreateAccountSuccess, newVehicleFromMyGarage, setAccountVehicles } = props;

    const handleSystemError = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: SYSTEM_ERROR
        });
    };

    const handleOpenSignInForm = (e) => {
        openSignInForm(e);
    };

    const handleCustomSubmit = async (formik) => {
        const { values, actions } = formik;
        if (Object.values(values).every((value) => value === '')) {
            const fieldNames = Object.keys(values);
            const emptyFields = fieldNames.filter((field) => !values[field] && field !== 'phone');

            // Push data layer for each empty field
            emptyFields.forEach((field) => {
                setAdobeDataLayer({
                    event: 'forms.user_generated_error',
                    form_name: 'create account',
                    step_name: 'create account',
                    field_name: field,
                    error_message: `${field} is Required`
                });
            });

            setSubmitTriggered(true);
            return;
        } else {
            await handleSubmit(values, actions);
        }
    };

    const handleSubmit = async (values, actions) => {
        setSubmitTriggered(false);
        try {
            const userResult = await createUser(values);

            IS_ACCOUNT_V3 = userResult?.version === 'v3';

            if (userResult.success === 'false') {
                if (
                    IS_ACCOUNT_V3 &&
                    userResult.status === '053' &&
                    userResult?.message?.error === 'User Registration Failed'
                ) {
                    setFormError({
                        hasError: true,
                        errorMessage: 'USER ALREADY EXISTS'
                    });
                } else if (IS_ACCOUNT_V3 && userResult?.message?.error === 'Password invalid') {
                    setFormError({
                        hasError: true,
                        errorMessage: (
                            <div>
                                <p>
                                    Your password does not meet our updated security requirements and needs to be reset.
                                </p>
                                <p>Please create a new password.</p>
                            </div>
                        )
                    });
                } else if (
                    IS_ACCOUNT_V3 &&
                    userResult.status === '520' &&
                    userResult.message?.error === 'Internal Error'
                ) {
                    setFormError({
                        hasError: true,
                        errorMessage: null,
                        errorType: SYSTEM_ERROR
                    });
                } else if (userResult.status === '053') {
                    setFormError({
                        hasError: true,
                        errorMessage: 'USER ALREADY EXISTS'
                    });
                } else if (userResult.status === 'InvalidLogin') {
                    setFormError({
                        hasError: true,
                        errorMessage: 'There is a problem with the username or password supplied. Please try again.'
                    });
                } else {
                    handleSystemError();
                }
                satelliteTrack('account.login.error');
                actions.setSubmitting(false);
            } else {
                setFormError({
                    hasError: false,
                    errorMessage: null
                });

                // analytics
                const adobeDataLayer = {
                    event: 'account.registration_success',
                    email: values.email
                };
                if (values.phone !== '') {
                    adobeDataLayer.telephone = values.phone;
                }
                setAdobeDataLayer(adobeDataLayer);
                if (newVehicleFromMyGarage.year !== '') {
                    newVehicleFromMyGarage.odometer = newVehicleFromMyGarage.mileage
                        ? newVehicleFromMyGarage.mileage
                        : '0';
                    newVehicleFromMyGarage.nickname = newVehicleFromMyGarage.vehicleNickname
                        ? newVehicleFromMyGarage.vehicleNickname
                        : undefined;
                    newVehicleFromMyGarage.drivingCondition = newVehicleFromMyGarage.drivingConditions
                        ? newVehicleFromMyGarage.drivingConditions
                        : undefined;
                    newVehicleFromMyGarage.tpms = newVehicleFromMyGarage.tpms ? newVehicleFromMyGarage.tpms : '1';
                    newVehicleFromMyGarage.vehicleId = newVehicleFromMyGarage.vehicleId
                        ? newVehicleFromMyGarage.vehicleId
                        : undefined;
                    const vehicle = new Vehicle(newVehicleFromMyGarage);
                    const isInList = await isYMMTInList(vehicle);
                    if (!isInList) {
                        const vehicleRepository = new VehicleRepository();
                        await vehicleRepository.load(true);
                        await vehicleRepository.setCurrentVehicle(vehicle);
                        setAccountVehicles(
                            [...vehicleRepository.vehicles()].map((v) => convertVehicleToVehicleFromAccount(v[1]))
                        );
                        const { YEAR, MAKE, MODEL, SUBMODEL, TPMS } = convertVehicleToVehicleFromAccount(vehicle);
                        await setVehicle(YEAR, MAKE, MODEL, SUBMODEL, TPMS);
                    }
                }

                onCreateAccountSuccess();
                if (values.optin) {
                    let signupData = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        confirmEmail: values.email
                    };
                    emailSignUp(signupData);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleAnalyticsValidation = (value) => {
        return !Object.values(passwordValidationCheck(value)).every((value) => value === true);
    };

    return (
        <div>
            {formError.hasError && formError?.errorType === SYSTEM_ERROR && <SystemErrorPrompt />}
            {!formError?.errorType && (
                <div>
                    <div className="create-account prompts">
                        <h2>Create An Account</h2>
                        {formError.hasError ? (
                            <div className="form-level-error">
                                <p className="red-copy" style={{ textAlign: 'center', margin: '0 auto', width: '80%' }}>
                                    {formError.errorMessage}
                                </p>
                            </div>
                        ) : null}
                        <Formik
                            initialValues={{ firstName: '', lastName: '', email: '', phone: '', password: '' }}
                            validationSchema={createAccountSchema}
                            render={({ errors, /* status,*/ touched, isSubmitting, values, actions }) => (
                                <Form id="createAnAccount">
                                    <Field
                                        name="firstName"
                                        id="createAccountFirstName"
                                        component={TextInput}
                                        placeholder="First Name"
                                        maxLength={50}
                                        pattern="[\p{L}\s\-.']+"
                                        onBlur={(e, fieldName) => {
                                            const { id, value } = e.target;
                                            if (
                                                value === '' &&
                                                !touchedFields[id] &&
                                                (errors[fieldName] || errors[fieldName] === undefined) &&
                                                !submitTriggered
                                            ) {
                                                setAdobeDataLayer({
                                                    event: 'forms.user_generated_error',
                                                    form_name: 'create account',
                                                    step_name: 'create account',
                                                    field_name: fieldName,
                                                    error_message: `${fieldName} is ${errors[fieldName]}`
                                                });
                                                setTouchedFields((prev) => ({ ...prev, [id]: true }));
                                            }
                                        }}
                                        required
                                    />
                                    <Field
                                        name="lastName"
                                        id="createAccountLastName"
                                        component={TextInput}
                                        placeholder="Last Name"
                                        maxLength={50}
                                        pattern="[\p{L}\s\-.']+"
                                        onBlur={(e, fieldName) => {
                                            const { id, value } = e.target;
                                            if (
                                                value === '' &&
                                                !touchedFields[id] &&
                                                (errors[fieldName] || errors[fieldName] === undefined) &&
                                                !submitTriggered
                                            ) {
                                                setAdobeDataLayer({
                                                    event: 'forms.user_generated_error',
                                                    form_name: 'create account',
                                                    step_name: 'create account',
                                                    field_name: fieldName,
                                                    error_message: `${fieldName} is ${errors[fieldName]}`
                                                });
                                                setTouchedFields((prev) => ({ ...prev, [id]: true }));
                                            }
                                        }}
                                        required
                                    />
                                    <Field
                                        name="email"
                                        id="createAccountEmail"
                                        component={TextInput}
                                        placeholder="Email"
                                        onBlur={(e, fieldName) => {
                                            const { id, value } = e.target;
                                            if (
                                                (value === '' || !testEmail(value)) &&
                                                !touchedFields[id] &&
                                                (errors[fieldName] || errors[fieldName] === undefined)
                                            ) {
                                                setAdobeDataLayer({
                                                    event: 'forms.user_generated_error',
                                                    form_name: 'create account',
                                                    step_name: 'create account',
                                                    field_name: fieldName,
                                                    error_message: errors[fieldName]
                                                });
                                                setTouchedFields((prev) => ({ ...prev, [id]: true }));
                                            }
                                        }}
                                    />
                                    <Field
                                        name="phone"
                                        id="createAccountPhone"
                                        component={MaskInput}
                                        placeholder="Phone Number (optional)"
                                        mask={phoneNumberMask}
                                        guide={true}
                                    />
                                    <Field
                                        name="password"
                                        id="createAccountPassword"
                                        component={PasswordInput}
                                        placeholder="Password"
                                        onBlur={(e, fieldName) => {
                                            const { id, value } = e.target;
                                            if (
                                                (value === '' || handleAnalyticsValidation(values['password'])) &&
                                                !touchedFields[id] &&
                                                (errors[fieldName] || errors[fieldName] === undefined)
                                            ) {
                                                setAdobeDataLayer({
                                                    event: 'forms.user_generated_error',
                                                    form_name: 'create account',
                                                    step_name: 'create account',
                                                    field_name: fieldName,
                                                    error_message: errors[fieldName]
                                                });
                                                setTouchedFields((prev) => ({ ...prev, [id]: true }));
                                            }
                                        }}
                                    />
                                    <PasswordMeter
                                        isTouched={touched['password'] || values['password'].length}
                                        pwValue={values['password']}
                                    />
                                    <div className="field-signup-optin">
                                        <Field
                                            component={CheckboxInput}
                                            name="optin"
                                            id="optin"
                                            label={`
                                            I'd like to receive coupons and promotions via email. For more information, please see the <a href="/privacy-statement/" target="_blank"> Privacy Statement</a>.`}
                                        />
                                    </div>
                                    <div className="fl-input-container margin-acct">
                                        <Button
                                            styleName="btn"
                                            type="submit"
                                            clickAction={() => handleCustomSubmit({ errors, values, actions })}
                                            disabled={Object.keys(errors).length > 0 || isSubmitting}
                                        >
                                            Create An Account
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        />
                        <p className="acct-sign-in">
                            Have an account?{' '}
                            <button className="form-footer-link" onClick={handleOpenSignInForm}>
                                Sign In
                            </button>{' '}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const newVehicleFromMyGarage = state.vehicleSelector.vehicle;

    return {
        newVehicleFromMyGarage
    };
};

const mapDispatch = (dispatch) => {
    return {
        setAccountVehicles: (vehicles) => dispatch(setAccountVehicles(vehicles))
    };
};

const CreateAccountForm = connect(mapStateToProps, mapDispatch)(CreateAccount);

export { CreateAccountForm };
