/**
 * Returns the price data for the product price component
 * @param {object} props the React props passed to the price component
 * @param {string} props.price the retail price of the product
 * @param {string} props.rearPrice the rear retail price of the product
 * @param {array} props.promo the front tire promos
 * @param {array} props.rearPromo the rear tire promos
 * @param {array} props.rebate the front tire rebates
 * @param {array} props.rearRebate the rear tire rebates
 * @param {boolean} props.isLoggedIn the logged in status of the user
 * @param {boolean} props.showPrice whether or not to show the price item
 * @param {boolean} props.isAsLowAs whether or not to prepend the word, "As low as"
 * @returns {object} the price data for the product price component
 */

import { HasSSO } from 'common/js/util/HasSSO';
import { calculatePrice } from 'common/js/util/MAPDiscount/MAPDiscount';

const getInstantSavings = (promos) => {
    if (!isPopulatedArray(promos)) return undefined;

    return promos.find((promo) => !promo.loggedInOnlyPromotion);
};

const getLoggedInDiscount = (promos) => {
    if (!isPopulatedArray(promos)) return undefined;

    const hasSSO = HasSSO();

    return promos.find((promo) => promo.loggedInOnlyPromotion && hasSSO);
};

const getMailInRebate = (rebates) => {
    if (!isPopulatedArray(rebates)) return undefined;

    return rebates[0];
};

const isPopulatedArray = (array) => array && Array.isArray(array) && array.length > 0;

export const ProductPriceData = (props) => {
    const {
        price,
        rearPrice,
        promo,
        rearPromo,
        rebate,
        rearRebate,
        isLoggedIn,
        showPrice = true,
        isAsLowAs = false
    } = props;

    const { subtotal, requiresLoggedIn } = calculatePrice(
        price,
        rearPrice,
        promo,
        rearPromo,
        rebate,
        rearRebate,
        isLoggedIn
    );

    // Object of offer data objects.
    const offersData = {
        lidiData: getLoggedInDiscount(promo),
        rearLidiData: getLoggedInDiscount(rearPromo),
        promoData: getInstantSavings(promo),
        rearPromoData: getInstantSavings(rearPromo),
        rebateData: getMailInRebate(rebate),
        rearRebateData: getMailInRebate(rearRebate)
    };

    // True if the site supports SSO
    const hasSSO = HasSSO();

    // True if the front or rear tire has a logged in discount
    const hasLIDI = Boolean(offersData.lidiData || offersData.rearLidiData);

    // True if the front or rear tire has an instant savings
    const hasPromo = Boolean(offersData.promoData || offersData.rearPromoData);

    // True if the front or rear tire has a mail in rebate
    const hasRebate = Boolean(offersData.rebateData || offersData.rearRebateData);

    // True if it's any of the offer types
    const hasOffer = hasLIDI || hasPromo || hasRebate;

    // True if we have an LIDI offer, requiresLoggedIn and supports SSO
    const isLIDI = hasLIDI && requiresLoggedIn && hasSSO;

    // True if we have an instant saving or rebate
    const isMAP = hasPromo || hasRebate;

    // True if we have a rear price
    const isStaggeredFitment = Boolean(rearPrice);

    // Object of booleans for each offer type
    const boolData = {
        hasLIDI,
        hasPromo,
        hasRebate,
        hasOffer,
        isMAP,
        isLIDI,
        isStaggeredFitment,
        requiresLoggedIn,
        showPrice,
        isAsLowAs
    };

    const finalData = { ...props, ...boolData, ...offersData, ...{ totalPrice: subtotal } };

    return finalData;
};
