import { pricingDetails } from 'common/js/data/services/ecomm/quote';

// RETRIEVE PRICING DATA (provide store number and article ids)
const requestPricing = async (storeNumber, articleIds) => {
    const dataPackage = {
        storeNumber,
        articleIds
    };
    let responseObject = null;

    try {
        const response = await pricingDetails(dataPackage);
        if (response.success === 'false') {
            // TODO: populate an error object in the Redux store to trigger an error modal
        } else {
            responseObject = response.data.products;
        }
    } catch (err) {
        console.log(err);
    }

    return responseObject;
};

export { requestPricing };
