import React, { useState, useEffect } from 'react';

import { test3of4 } from 'common/components/content/account/v1/js/ui/preferences/ChangePasswordForm';
import { passwordValidationCheck } from 'common/js/library/util.js'

import './password-meter.scss';

const PasswordMeter = (props) => {

	const {
		isTouched = false,
		pwValue
	} = props;

	const [ isValid, setIsValid ] = useState({
		minChar: false,
		lowercase: false,
		uppercase: false,
        aNumber: false,
        specialChar: false
    });

    const [is3of4Valid, setIs3of4Valid] = useState(false);

	useEffect(() => {
        const is3of4 = test3of4(pwValue);
        setIs3of4Valid(is3of4);
		setIsValid(passwordValidationCheck(pwValue));

	}, [pwValue]);

	return (
		<div className={isTouched ? (isValid.minChar && is3of4Valid ? 'valid footnote-serv-rec' : 'invalid footnote-serv-rec') : "footnote-serv-rec"}>
			<p>Passwords are case sensitive and require <span className={isTouched ? (isValid.minChar ? 'valid' : 'invalid') : null}>a minimum of 8 characters</span> and <span className={isTouched ? (is3of4Valid ? 'valid' : 'invalid') : null}>all the four conditions</span>:
            </p>
			<ul>
				<li className={isTouched ? (isValid.lowercase ? 'valid' : 'invalid') : null}>
					At least one lowercase letter
				</li>
				<li className={isTouched ? (isValid.uppercase ? 'valid' : 'invalid') : null}>
					At least one uppercase letter
				</li>
				<li className={isTouched ? (isValid.aNumber ? 'valid' : 'invalid') : null}>
					At least one number
				</li>
                <li className={isTouched ? (isValid.specialChar ? 'valid' : 'invalid') : null}>
                    At least one special character:
                        &#45;{/* - */}
                        &#33;{/* ! */}
                        &#36;{/* $ */}
                        &#37;{/* % */}
                        &#94;{/* ^ */}
                        &amp;{/* & */}
                        &#42;{/* * */}
                        &#40;{/* ( */}
                        &#41;{/* ) */}
                        &#47;{/* / */}
                        &#95;{/* _ */}
                        &#43;{/* + */}
                        &#124;{/* | */}
                        &#126;{/* ~ */}
                        &#61;{/* = */}
                        &#96;{/* ` */}
                        &#123;{/* { */}
                        &#125;{/* } */}
                        &#91;{/* [ */}
                        &#93;{/* ] */}
                        &#58;{/* : */}
                        &#59;{/* ; */}
                        &#63;{/* ? */}
                        &#44;{/* , */}
                        &#46;{/* . */}
                        &#64;{/* @ */}
                        &#35;{/* # */}
                </li>
			</ul>
		</div>
	)
}

export { PasswordMeter }
