import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';

const ExpeditedBookingApp = lazy(() => import(/* webpackChunkName: "expedited-booking/v1" */ './ExpeditedBookingApp'));

const ExpeditedBookingLoader = () => {
    // console.log('************************************');

    console.log('ExpeditedBookingLoader');
    const appRoot = document.getElementById('expedited-booking-app');
    const customHeading = appRoot ? appRoot?.parentElement?.getAttribute('data-heading') : '';
    const apptService =
        (appRoot && {
            id: appRoot.getAttribute('data-service-id'),
            name: appRoot.getAttribute('data-service-name'),
            storeNumber: appRoot.getAttribute('data-store-number'),
            isLocalServicePage: document?.body?.classList?.contains('service')
        }) ||
        null;
    // console.log('===================================');
    // console.log('service: ', apptService);

    // Removing the State Inspection option from serviceType drown in EBW (as per ticket BSRODX-2236)
    const serviceTypes = document.getElementById('service-types-data')
        ? JSON.parse(document.getElementById('service-types-data').textContent).filter(
              (serviceType) => serviceType?.serviceDesc !== 'State Inspection'
          )
        : [];
    // const serviceTypes = serviceTypesData.data;

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner styles={{ margin: '0', height: '100%' }} />}>
                  <ExpeditedBookingApp
                      apptService={apptService}
                      customHeading={customHeading}
                      serviceTypes={serviceTypes}
                      basePath=""
                  />
              </Suspense>,
              appRoot
          )
        : null;
};

export { ExpeditedBookingLoader };
