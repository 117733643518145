import React from 'react';
import MaskedInput from 'react-text-mask-legacy'; //import MaskedInput from 'react-text-mask';
import classNames from 'classnames';

const MaskInput = (props) => {
    const {
        field,
        form: { handleBlur, handleChange, touched, errors },
        id,
        placeholder,
        className,
        guide = false,
        required,
        mask,
        placeholderChar,
        setFieldValue,
        onChangeHandler,
        onBlur
    } = props;

    const handleCustomBlur = (e) => {
        if (onBlur) {
            onBlur(e, field.name);
        }

        if (field.onBlur) {
            field.onBlur(e);
        }

    }

    // const errMsgClasses = classNames('error', {
    //     'fl-error-msg fl-error-show': touched[field.name] && errors[field.name]
    // });

    const fieldClassNames = classNames(
        'fl-input',
        className,
        { 'fl-valid': !errors[field.name] && field.value },
        { 'fl-invalid': touched[field.name] && errors[field.name] }
    );

    return (
        <div className="fl-input-container form-wrap">
            <MaskedInput
                mask={mask}
                placeholderChar={placeholderChar}
                type="text"
                name={field.name}
                id={id}
                className={fieldClassNames}
                value={field.value}
                onChange={(e) => {
                    if (onChangeHandler) {
                        onChangeHandler(e, setFieldValue, handleChange);
                    } else if (handleChange) {
                        handleChange(e);
                    }
                }}
                onBlur={handleCustomBlur}
                guide={guide}
            />
            <label className="fl-input-label" htmlFor={field.name}>
                <span>{placeholder}</span>
            </label>
            <span className="fl-input-bar" />
            {touched[field.name] && errors[field.name] && (
                <div className="error fl-error-msg fl-error-show">{errors[field.name]}</div>
            )}
        </div>
    );
};

export { MaskInput };
