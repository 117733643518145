import { getInventory } from 'common/js/data/services/ecomm/quote';

// INVENTORY /bsro/services/store/inventory
const requestInventory = async (storeNumber, articles) => {
    const response = await getInventory(storeNumber, articles.join(','));
    let tempInventory = {};

    if (response && response.success === 'false') {
        console.log('fire handleCreateQuote/inventory alert');
    } else {
        // This was copied from QuoteAnnex, which uses a single card
        // and up to two articles for split fit
        // may also want to transform values with parseInt
        tempInventory = response.data.tires;
        // response.data.tires &&
        //     response.data.tires.map((inv) => {
        //         if (articles[0] && articles[0] === inv.part_number) {
        //             tempInventory.front = {
        //                 article: articles[0],
        //                 quantity: (inv && inv.quantity) || '0',
        //                 sister: (inv && inv.q_sister) || '0',
        //                 local: (inv && inv.q_local) || '0'
        //             };
        //         } else if (articles[1] && articles[1] === inv.part_number) {
        //             tempInventory.rear = {
        //                 article: articles[1],
        //                 quantity: (inv && inv.quantity) || '0',
        //                 sister: (inv && inv.q_sister) || '0',
        //                 local: (inv && inv.q_local) || '0'
        //             };
        //         }
        //     });
    }

    return tempInventory;
};

export { requestInventory };
