import React from 'react';

const SystemErrorPrompt = (props) => {
    return (
        <div className="prompts system-error">
            <h2 className="red-copy">Something went wrong.</h2>
            <p>
                <span className="red-copy">An error in our system blocked us from logging you in. It may take some time to fix, but we promise we're working on it. <br />We apologize for the inconvenience.</span>
                <br />
                <br />
                <strong className="black-copy">Please try again later.</strong>
            </p>
        </div>
    );
};

export { SystemErrorPrompt };