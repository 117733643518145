export const ecommSwitch = () => {
    //////////////////
    // ECOMM ON
    //////////////////
    if (navigator.cookieEnabled) {
        
        if (localStorage.getItem('ecommIsActive') === 'false') {
            return false;
        }
    }

    return true;

    /////////////////
    // ECOMM OFF
    /////////////////
    // if (localStorage.getItem('ecommIsActive') === 'true') {
    //     return true;
    // }

    // return false;
};
