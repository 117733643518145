import React from 'react';
import { ExclusiveOfferIcon } from '../util/OfferIcons';
import { PriceItem } from '../util/PriceItem';
import { OfferCopy } from '../util/OfferCopy';
import useProductPrice from '../util/ProductPriceContext';

export const LIDIOffer = () => {
    const { showPrice } = useProductPrice();
    return (
        <div className="product-price lidi">
            <div className="product-price__text">
                <ExclusiveOfferIcon />
                {showPrice && <PriceItem />}
                <span className="product-price__four-tires">
                    when you buy <strong>4</strong> tires
                </span>
                <OfferCopy />
            </div>
        </div>
    );
};
