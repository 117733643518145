import React, { Suspense, useEffect, useState } from 'react';
import { ProductPriceApp } from './ProductPriceApp';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

const ProductPriceLoader = () => {
    console.log('ProductPriceLoader');
    const productPrices = (appRoots, isLoggedIn) => {
        const portalArray = Array.from(appRoots)
            .map((root, i) => {
                const uniqueId = `${i}${Math.random().toString(36).substring(2, 15)}`;
                const {
                    price,
                    priceFormatted,
                    rearPrice,
                    rearPriceFormatted,
                    promo,
                    rearPromo,
                    rebate,
                    rearRebate,
                    showPrice,
                    isAsLowAs
                } = root.dataset;

                const parseFloatWithDefault = (value) => parseFloat(value) || 0;
                const parseJsonWithDefault = (value) => (value ? JSON.parse(value) : []);

                const priceValue = parseFloatWithDefault(price);
                const rearPriceValue = parseFloatWithDefault(rearPrice);
                const promoJson = parseJsonWithDefault(promo);
                const rearPromoJson = parseJsonWithDefault(rearPromo);
                const rebateJson = parseJsonWithDefault(rebate);
                const rearRebateJson = parseJsonWithDefault(rearRebate);
                const showPriceValue = showPrice && showPrice === 'true';
                const isAsLowAsValue = isAsLowAs && isAsLowAs === 'true';
                if (priceValue) {
                    return (
                        <React.Fragment key={i}>
                            <DynamicPortal
                                id={`product-price-portal-${uniqueId}`}
                                className="tsr-card__pricing"
                                dynamicParentElement={root}
                                hideParent={true}
                            >
                                <Suspense fallback={<DefaultSpinner />}>
                                    <ProductPriceApp
                                        price={priceValue}
                                        priceFormatted={priceFormatted}
                                        rearPrice={rearPriceValue}
                                        rearPriceFormatted={rearPriceFormatted}
                                        promo={promoJson}
                                        rearPromo={rearPromoJson}
                                        rebate={rebateJson}
                                        rearRebate={rearRebateJson}
                                        isLoggedIn={isLoggedIn}
                                        showPrice={showPriceValue}
                                        isAsLowAs={isAsLowAsValue}
                                    />
                                </Suspense>
                            </DynamicPortal>
                        </React.Fragment>
                    );
                } else {
                    return <></>;
                }
            })
            .filter(Boolean);
        return portalArray;
    };

    const [roots, setRoots] = useState([]);
    const [portals, setPortals] = useState(productPrices(roots));
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        roots && setPortals(productPrices(roots, isLoggedIn));
    }, [roots]);

    pubsub.subscribe(topics.CREATE_PRODUCTPRICE, (els, isLoggedIn) => {
        setIsLoggedIn(isLoggedIn);
        setRoots(els);
    });

    return portals ? portals : null;
};

export { ProductPriceLoader };
