import { requestInventory } from './util/requestInventory';
import { requestPricing } from './util/requestPricing';
import { requestRatings } from './util/requestRatings';
import {
    tsrSetDataAttributes,
    tsrSetInventory,
    tsrSetInventoryPricing,
    tsrSetPricing,
    tsrSetLoggedIn
    // tsrSetSearchByVehicle
} from 'common/js/data/redux/actionCreators/tire-search-results';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { requestInventoryPricing } from 'common/components/content/react-shared/requests/requestInventoryPricing';
import { reviewsMergeProducts, reviewsMergeProductsV2 } from 'common/js/data/redux/actionCreators/reviews';

import { setVehicleCookie } from 'common/components/content/react-shared/utils/setVehicleCookie';
import { setTireSizeCookie } from 'common/components/content/react-shared/utils/setTireSizeCookie';
import { isUserLoggedIn } from 'common/js/data/services/account/user';

import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

/**
 * These are a mockup data just for local webpack env
 */
// import { testDataTireResults } from './test-data/testDataTireResults';
// import { testDataProductArticleIds } from './test-data/testDataProductArticleIds';
// import { testDataProductArticleKey } from './test-data/testDataProductArticleKey';
// import { testDataVehicle } from './test-data/testDataVehicle';
// import { testDataSize } from './test-data/testDataSize';
// import { testPricing } from './test-data/testDataPricing';
// import { testInventory } from './test-data/testDataInventory'

const tireSearchResultsReduxLoader = async (store) => {
    console.log('tireSearchResultsReduxLoader');

    try {
        // any initial Redux store setup goes here

        /* DATA ATTRIBUTE TEST DATA
        const tireResults = testDataTireResults;
        const vehicle = testDataVehicle;
        const tireSize = testDataSize;
        const articleIds = testDataProductArticleIds?.split(',') || [];
        const articleKey = testDataProductArticleKey?.split(',') || [];
        */

        // get the data-tire-results data from the page and add to store.
        // DATA ATTRIBUTES TO GET DATA FROM
        // data-tire-results
        // data-vehicle
        // data-sizing
        // data-article-ids
        // data-product-article-key

        // Is user logged in?
        const isLoggedIn = await isUserLoggedIn();
        store.dispatch(tsrSetLoggedIn(isLoggedIn));

        const prgEl = document.querySelector('.product-results-grid');
        if (!prgEl) {
            console.log('tireSearchResultsReduxLoader error: no .product-results-grid found');
            return;
        }
        const resultsEl = prgEl?.querySelector('.results');
        if (!resultsEl) {
            console.log('tireSearchResultsReduxLoader error: .results found');
            return;
        }

        // NOTE: tire results are PRELIMINARY data without store attached. Inventory_pricing request will
        // provide the final results based on store number.

        const tireResults = resultsEl.dataset.tireResults ? JSON.parse(resultsEl.dataset.tireResults) : null;
        const vehicle = prgEl.dataset.vehicle ? JSON.parse(prgEl.dataset.vehicle) : null;
        const tireSize = prgEl.dataset.sizing ? JSON.parse(prgEl.dataset.sizing) : null;
        const articleIds = prgEl.dataset.articleIds?.split(',') || [];
        const matchedSets = prgEl.dataset.matchedSets?.split(',') || [];
        const articleKey = matchedSets.concat(articleIds);
        const bestSellerIds = prgEl.dataset.bestSellers?.split(',') || [];
        const storeNumber = window.siteProfile?.location?.storeNumber || '';
        const storePhone = resultsEl.dataset.storePhone;
        const tsrLocalStorage = getLocalStorageItem('tireSearchResults');
        const dataAttr = {
            articleIds,
            articleKey,
            bestSellerIds,
            matchedSets,
            storeNumber,
            storePhone,
            tireResults,
            tireSize,
            vehicle
        };
        const searchCriteria = vehicle || tireSize || null;

        if (
            (tsrLocalStorage &&
                !tireSize &&
                vehicle &&
                tsrLocalStorage?.vehicle &&
                tsrLocalStorage?.vehicle?.year === vehicle.year &&
                tsrLocalStorage?.vehicle?.make === vehicle.make &&
                tsrLocalStorage?.vehicle?.model === vehicle.model &&
                tsrLocalStorage?.vehicle?.submodel === vehicle.submodel) ||
            (!vehicle &&
                tireSize &&
                tsrLocalStorage?.tireSize &&
                tsrLocalStorage?.tireSize?.crossSection === tireSize.crossSection &&
                tsrLocalStorage?.tireSize?.aspectRatio === tireSize.aspectRatio &&
                tsrLocalStorage?.tireSize?.rimSize === tireSize.rimSize)
        ) {
            dataAttr.tabs = tsrLocalStorage.tabs;
            dataAttr.currentTab = tsrLocalStorage.currentTab;
        }

        store.dispatch(tsrSetDataAttributes(dataAttr));

        // stop if we don't have results
        if (!tireResults || Object.keys(tireResults).length === 0) {
            console.log('tireSearchResultsReduxLoader error: tireResults empty', { tireResults });
            return;
        }

        if (vehicle) {
            setVehicleCookie(vehicle);
        } else if (tireSize) {
            setTireSizeCookie(tireSize);
        }

        const updateResultsCount = (count) => {
            const resultsCount = document.querySelector('.vehicle-info-bar .results-count');
            // const count = cleanData?.articleKey?.length || '';
            if (resultsCount && count) {
                resultsCount.innerHTML = `${count} Tires <span>for</span>`;
            }
        };

        const inventoryPricingData = await requestInventoryPricing(storeNumber, searchCriteria);

        console.log('inventoryPricingData:', { inventoryPricingData, storeNumber, searchCriteria });
        if (inventoryPricingData) {
            store.dispatch(tsrSetInventoryPricing(inventoryPricingData, updateResultsCount));
        } else {
            // if inventory_price fails, fallback to old inventory/pricing requests
            // OLD PRICING REQUEST - USING COMBINED INVENTORY_PRICING REQUEST INSTEAD

            const pricingData = await requestPricing(storeNumber, articleIds);
            store.dispatch(tsrSetPricing(pricingData));

            // OLD INVENTORY REQUEST - USING COMBINED INVENTORY_PRICING REQUEST INSTEAD

            const inventoryData = await requestInventory(storeNumber, articleIds);
            store.dispatch(tsrSetInventory(inventoryData));
        }

        // callback for reviews response
        const updateSummaries = (data) => {
            console.log('updateSummaries', { data });

            if (tireDetailPageSwitch()) {
                // Defering callstack to avoid collision.
                window.setTimeout(() => {
                    store.dispatch(reviewsMergeProductsV2(data));
                }, 0);
            } else {
                store.dispatch(reviewsMergeProducts(data));
            }
        };
        await requestRatings(updateSummaries, tireResults);

        /* REQUEST TEST DATA
        const pricingData = testPricing;
        const inventoryData = testInventory;
        */

        // NO LONGER NEED THIS REQUEST. BEING HANDLED ON PAGE GENERATION
        /*
        const searchByVehicleData = await requestSearchByVehicle(
            `${vehicle.year}-${vehicle.make}-${vehicle.model}`,
            vehicle.submodel,
            storeNumber
        );
        store.dispatch(tsrSetSearchByVehicle(searchByVehicleData));
        */
    } catch (err) {
        console.log('tireSearchResultsReduxLoader failed:', err);
    }
};

// const requestSearchByVehicle = async (ymm, trim, storeNumber) => {
//     const response = await searchByVehicle({ ymm, trim, storeNumber });
//     let responseData = {};

//     if (response?.success === 'false') {
//         console.log('requestSearchByVehicle failed');
//     } else {
//         responseData = response.data;
//     }

//     return responseData;
// };

export { tireSearchResultsReduxLoader };
