import React from 'react';
import classNames from 'classnames';

const TextInput = (props) => {
    const {
        field,
        form: { handleBlur, handleChange, touched, errors },
        id,
        placeholder,
        className,
        required,
        maxLength,
        minLength,
        disabled,
        onBlur
    } = props;

    const handleCustomBlur = (e) => {
        if (onBlur) {
            onBlur(e, field.name);
        }

        if (field.onBlur) {
            field.onBlur(e);
        }

    }

    return (
        <div className="fl-input-container form-wrap">
            <input
                type="text"
                name={field.name}
                id={id}
                /* className={inputClasses} */
                className={classNames(
                    'fl-input',
                    className,
                    { 'fl-invalid': touched[field.name] && errors[field.name] },
                    { 'fl-valid': !errors[field.name] && field.value }
                )}
                value={field.value}
                maxLength={maxLength}
                minLength={minLength}
                onChange={handleChange}
                onBlur={handleCustomBlur}
                disabled={disabled}
            />
            <label className="fl-input-label" htmlFor={field.name}>
                <span>{placeholder}</span>
            </label>
            <span className="fl-input-bar" />
            {touched[field.name] && errors[field.name] && (
                <div className="error fl-error-msg fl-error-show">
                    {errors[field.name]}
                </div>
            )}
        </div>
    );
};

export default TextInput;
