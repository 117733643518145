import {
    accountLoginLoading,
    accountLoginUser,
    accountLogoutUser,
    accountSetFullProfile
} from 'common/js/data/redux/actionCreators/account';
import { getCookie, setCookie } from 'common/js/library/util';
import { authPoller } from './authenticationPoll';
import { getUserDetails } from 'common/js/data/services/account/user';

const accountLoader = async (store) => {
    try {
        store.dispatch(accountLoginLoading());

        const token = getCookie('tokenId');
        let userId = getCookie('userId');

        if (token !== '') {
            const userDetails = await getUserDetails(token);

            if (userDetails.success === 'true') {
                const account = userDetails.data.account;
                const profile = {
                    isLoggedIn: userDetails.data.loggedIn,
                    tokenId: userDetails.data.tokenId
                };
                userId = userDetails.data.userId;
                setCookie('userId', userId, 30);
                store.dispatch(accountLoginUser(profile));
                store.dispatch(accountSetFullProfile(account));

                authPoller(store);

                // analytics
                if (typeof window.digitalData === 'object') {
                    window.digitalData.user[0].profile[0].attributes.login_status = 'AUTHENTICATED';
                    window.digitalData.user[0].profile[0].attributes.user_id = userId;
                }
            }
        } else if (userId) {
            store.dispatch(accountLogoutUser());
            // analytics
            if (typeof window.digitalData === 'object') {
                window.digitalData.user[0].profile[0].attributes.login_status = 'LOGGED_OUT';
                window.digitalData.user[0].profile[0].attributes.user_id = userId;
            }
        } else {
            store.dispatch(accountLogoutUser());
            // analytics
            if (typeof window.digitalData === 'object') {
                window.digitalData.user[0].profile[0].attributes.login_status = 'UNKNOWN';
            }
        }
    } catch (err) {
        console.log("there's been an error in the accountLoader file: ", { err });
    }
};

export { accountLoader };
