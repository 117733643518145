import React, { useState } from 'react';
import classNames from 'classnames';
//import lock from '../../../../../../images/icon/lock.svg';

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const {
        field,
        form: { handleBlur, handleChange, touched, errors },
        placeholder,
        required,
        disabled = false,
        onBlur
    } = props;

    const hasError = errors[field.name];
    const hasValue = field.value ? true : false;
    const inputClasses = classNames(
        'fl-input',
        { 'fl-valid': hasValue && !hasError },
        { 'fl-invalid': hasValue && hasError }
    );
    const errMsgClasses = classNames('error', { 'fl-error-msg fl-error-show': errors[field.name] });

    const handleTogglePasswordVisibility = (e) => {
        e.preventDefault();
        if (showPassword) {
            setShowPassword(false);
        } else {
            setShowPassword(true);
        }
    };

    const handleCustomBlur = (e) => {
        if (onBlur) {
            onBlur(e, field.name);
        }

        if (field.onBlur) {
            field.onBlur(e);
        }
    };

    const showPasswordImage = 'https://s7d1.scene7.com/is/content/bridgestone/eye1-icon-global-web-bsro';
    const hidePasswordImage = 'https://s7d1.scene7.com/is/content/bridgestone/eye2-icon-global-web-bsro';

    return (
        <div className="fl-input-container form-wrap">
            <input
                type={showPassword ? 'text' : 'password'}
                name={field.name}
                id={field.name}
                className={`fl-input ${touched[field.name] && errors[field.name] ? 'fl-invalid' : ''} ${
                    !errors[field.name] && field.value ? 'fl-valid' : ''
                }`}
                value={field.value}
                onChange={handleChange}
                onBlur={handleCustomBlur}
                disabled={disabled}
            />
            <label className="fl-input-label" htmlFor={field.name}>
                <span>{placeholder}</span>
            </label>
            <span className="fl-input-bar"></span>
            <span className="fl-pass-toggle" onClick={handleTogglePasswordVisibility}>
                <img
                    src={showPassword ? showPasswordImage : hidePasswordImage}
                    alt="lock"
                    style={{ verticalAlign: 'bottom' }}
                />
            </span>
            {touched[field.name] && errors[field.name] && <div className={errMsgClasses}>{errors[field.name]}</div>}
        </div>
    );
};

export default PasswordInput;
