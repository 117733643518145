import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import WindowSize from '@reach/window-size';
import PropTypes from 'prop-types';

import { DesktopIconRedux } from './desktop/DesktopIconRedux';
import { MobileIconRedux } from './mobile/MobileIconRedux';

import '../../scss/ecomm-cart-icon.scss';

const mapStateToProps = (state, ownProps) => {
    return { counter: Boolean(state.ecomm.cart.guid) };
};

const EcommCartIconApplication = (props) => {
    const { counter } = props;
    const taxFlag = window.bsro?.ecomm?.tax?.flag || false;
    const disable = taxFlag;

    return (
        <>
            
            <WindowSize>
                {({ width, height }) => (
                    <>
                        {width < 768 ? (
                            <MobileIconRedux counter={counter} disable={disable} />
                        ) : (
                            <DesktopIconRedux counter={counter} disable={disable} />
                        )}
                    </>
                )}
            </WindowSize>
        </>
    );
};

const EcommCartIconApp = hot(
    connect(
        mapStateToProps,
        null
    )(EcommCartIconApplication)
);

EcommCartIconApp.propTypes = {
    counter: PropTypes.bool.isRequired
};

export { EcommCartIconApp };
