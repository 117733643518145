import React, { useEffect, useState, lazy, Suspense } from 'react';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';
//import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { ecommSwitch } from 'common/js/util/ecommSwitch';
import { isDriveGuard } from 'common/js/util/isDriveGuard';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

const EcommQuoteAnnexApp = lazy(() => import(/* webpackChunkName: "ecomm-quote-annex/v2" */ './EcommQuoteAnnexApp'));

const EcommQuoteAnnexLoaderV2 = () => {
    if (!ecommSwitch()) return null;
    if (!tireDetailPageSwitch()) return null;

    const [compareFlag, setCompareFlag] = useState(0);
    const [compareClicks, setCompareClicks] = useState(0);

    // mapping buttons
    const mapOldButtons = () => {
        const els = Array.from(document.querySelectorAll('.tire-quote-btn'));
        const portalArray = els
            .map((oldBtn, i) => {
                // scavenge for data attributes with relevant data
                // application element
                let attachmentPoint = oldBtn;
                // defines if this is in the tire detail component
                // const isTireDetail = Boolean(oldBtn.closest('#product_detail')); // id doesn't exist anymore
                const isTireDetail = Boolean(oldBtn.closest('section.product-detail-v2'));
                // card is the outer element containing the button. is undefined in tire detail component
                // for the compare modal, it uses a different class so we have to check again for it.
                let card = oldBtn.closest('.tsr-card');
                if (!card) {
                    card = oldBtn.closest('.quotebtn-section');
                }
                const oldSpinner = (card && card.querySelector('.wait')) || null;
                const tireName = oldBtn.getAttribute('data-tire-name') || '';
                const tireFriendlyName = oldBtn.getAttribute('data-tire-friendly-name') || '';
                const dataArticle = oldBtn.getAttribute('data-article') || '';
                // data object passed to app. if tire detail instance, the app may modify these values based on current quote values.
                const data = {
                    article: dataArticle.split('_')[0], // remove rear article if attached
                    rearArticleNumber: (card && card.getAttribute('data-rear-article-id')) || '',
                    quantity: oldBtn.getAttribute('data-quantity') || '',
                    type: oldBtn.getAttribute('data-type') || '',
                    // catitem: oldBtn.getAttribute('data-catitem') || '',
                    brand: oldBtn.getAttribute('data-brand') || oldBtn.getAttribute('data-catitem-tirebrand') || '',
                    name: tireName || oldBtn.getAttribute('data-catitem-tirename') || '',
                    tireFriendlyName: tireFriendlyName || tireName || '',
                    logo: oldBtn.getAttribute('data-tire-logo') || '',
                    image: oldBtn.getAttribute('data-tire-image') || '',
                    // TODO remove after || when real data get pulled
                    desc: oldBtn.getAttribute('data-description') || '',
                    mileage: oldBtn.getAttribute('data-mileage') || 'N/A',
                    cardHref: oldBtn.getAttribute('data-card-href') || '/credit-card/',
                    // Inventory data attribute isn't immediately available on page load :-(
                    // setting this value in QuoteAnnex on button click
                    frontInventory: '0',
                    rearInventory: '0',
                    standardOptional:
                        card && card.classList.contains('Standard')
                            ? 'S'
                            : card && card.classList.contains('Optional')
                              ? 'O'
                              : '',
                    driveGuard: isDriveGuard(tireName),
                    isSplitFitment: card && card.classList.contains('MatchedSet')
                };
                const compareBtn = (card && card.querySelector('.compare-checked')) || null;
                if (compareFlag === 0 && compareBtn) {
                    compareBtn.onclick = () => {
                        // need to give the modal enough time to render the buttons so we can swap them out
                        setTimeout(() => {
                            setCompareClicks(new Date().getTime());
                        }, 500);
                    };
                }

                // if just a quote button, insert next to old button
                // else if product detail section, annex larger component
                if (isTireDetail) {
                    const prodDetail = Array.from(document.querySelectorAll('.product-detail-price-block-v2'))[0];
                    data.articleIds = document
                        // .getElementById('product_detail') // this id has changed
                        .querySelectorAll('section.product-detail-v2')[0]
                        .getAttribute('data-article-id')
                        .replace('[', '')
                        .replace(']', '')
                        .split(',');
                    attachmentPoint = prodDetail;
                }

                const uniqueId = compareBtn ? i : `${i}${new Date().getTime()}`;

                return (
                    <React.Fragment key={i}>
                        <DynamicPortal
                            id={`ecomm-quote-annex-portal-${uniqueId}`}
                            dynamicParentElement={attachmentPoint}
                            hideParent={true}
                        >
                            <Suspense fallback={<></>}>
                                <EcommQuoteAnnexApp
                                    data={data}
                                    isTireDetail={isTireDetail}
                                    oldBtn={oldBtn}
                                    oldSpinner={oldSpinner}
                                />
                            </Suspense>
                        </DynamicPortal>
                    </React.Fragment>
                );
            })
            .filter(Boolean);

        if (compareFlag === 0) {
            setCompareFlag(1);
        }

        return portalArray;
    };

    const [portals, setPortals] = useState(mapOldButtons());

    useEffect(() => {
        setPortals(mapOldButtons());
    }, [compareClicks]);

    pubsub.subscribe(topics.CREATE_BUTTONS, setPortals);

    return portals ? portals : null;
};

export { EcommQuoteAnnexLoaderV2 };
