/**
 * These are a mockup data just for local webpack env
 * SERVICES
 * get store by zip: /bsro/services/store/location/get-list-by-zip
 * get store by lat/lng: /bsro/services/store/location/get-list-by-lat-lng (not sure if we need this)
 * store info: /bsro/services/store/details/[store-number]
 * time slots: /bsro/services/appointment/get-availability-for-store
 * confirmation: /bsro/services/appointment/confirm
 */

// EXAMPLE IMPORT
// import { testDataTimeSlots } from './test-data/testDataTimeSlots';

const expeditedBookingReduxLoaderV2 = async (store) => {
    console.log('expeditedBookingReduxLoaderV2');

    const useTestData = true;

    try {
        // any initial Redux store setup goes here

        if (useTestData) {
            /* DATA ATTRIBUTE TEST DATA
            const timeSlotData = testDataTimeSlots;
            */
        } else {
            // INITIAL SERVICE REQUESTS
        }
    } catch (err) {
        console.log('expeditedBookingReduxLoaderV2 failed:', err);
    }
};

export { expeditedBookingReduxLoaderV2 };
