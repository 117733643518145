import React from 'react';
import { ProductPrice } from 'common/components/content/react-shared/components/product-price/product-price';

const ProductPriceApp = (props) => {
    const {
        price,
        priceFormatted,
        rearPrice,
        rearPriceFormatted,
        promo,
        rearPromo,
        rebate,
        rearRebate,
        isLoggedIn,
        showPrice,
        isAsLowAs
    } = props;
    return (
        <ProductPrice
            price={price}
            priceFormatted={priceFormatted}
            rearPrice={rearPrice}
            rearPriceFormatted={rearPriceFormatted}
            promo={promo}
            rearPromo={rearPromo}
            rebate={rebate}
            rearRebate={rearRebate}
            isLoggedIn={isLoggedIn}
            showPrice={showPrice}
            isAsLowAs={isAsLowAs}
        />
    );
};

export { ProductPriceApp };
