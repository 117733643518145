/**
 * A React component to render the product price.  We have MAP and LIDI specific logic here.
 * MAP - relates to offers and/or rebates. Instant savings an/or Mail-in Rebates are examples of MAP.  References the retail price.
 * LIDI - relates to Logged in discount.  Only logged in users get to see the discounted price. References the sale price.
 *
 * Each scenario has a different price layout and marketing language.  This component will render the correct
 * price and marketing language based on the scenario.
 *
 * Wrapped in a ProductPriceProvider context to provide the price data to the child components.
 *
 * @module ProductPrice
 *
 */
import React from 'react';

import { ProductPriceProvider } from './util/ProductPriceContext';
import { ProductPriceData } from './util/ProductPriceData';

import { SignInOnly } from './ui/SignInOnly';
import { LIDIOffer } from './ui/LIDIOffer';
import { MAPOffer } from './ui/MAPOffer';
import { NoOffer } from './ui/NoOffer';

const ProductPrice = (props) => {
    const productPriceData = ProductPriceData(props);

    const { isLIDI, isMAP, isLoggedIn } = productPriceData;

    const type = props.type || '';

    return (
        <ProductPriceProvider value={productPriceData}>
            {isLIDI && !isLoggedIn && <SignInOnly type={type}/>}
            {isLIDI && isLoggedIn && <LIDIOffer />}
            {!isLIDI && isMAP && <MAPOffer />}
            {!isLIDI && !isMAP && <NoOffer />}
        </ProductPriceProvider>
    );
};

export { ProductPrice };
