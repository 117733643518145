import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { EcommCartIconApp } from './EcommCartIconApp';
import { ecommSwitch } from 'common/js/util/ecommSwitch';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const mapState = (state) => {
    return {
        windowWidth: state.meta.windowDimensions.width
    };
};

const CartIcon = (props) => {
    if (!ecommSwitch()) return null;

    const { windowWidth } = props;
    const isMobile = windowWidth < 768;
    const appRoot = isMobile ? document.querySelector('.hamburger') : document.querySelector('.desktop-only.cart');

    const desktopLegacy = document.querySelector('.desktop-only.cart > a');
    const mobileLegacy = document.querySelector('.mobile-only.cart');

    if (desktopLegacy) desktopLegacy.style.cssText = 'display: none !important';
    if (mobileLegacy) mobileLegacy.style.cssText = 'display: none !important';

    return appRoot && window.isEcom && window.isStoreEcom
        ? createPortal(<EcommCartIconApp isMobile={isMobile} />, appRoot)
        : null;
};

const CartIconV2 = (props) => {
    if (!ecommSwitch()) return null;

    const { windowWidth } = props;
    const isMobile = windowWidth < 768;
    const appRoot = document.querySelector('.top-nav-v2 ul.menu-right li.cart');
    const storeData = getLocalStorageItem('storeData');

    if (window.isStoreEcom && storeData?.eCommActiveFlag === '1' && appRoot) {
        appRoot.style.display = 'flex';
    }

    return appRoot && window.isEcom && window.isStoreEcom
        ? createPortal(<EcommCartIconApp isMobile={isMobile} />, appRoot)
        : null;
};

const EcommCartIconLoader = connect(mapState, null)(CartIcon);

const EcommCartIconLoaderV2 = connect(mapState, null)(CartIconV2);

export { EcommCartIconLoader, EcommCartIconLoaderV2 };
