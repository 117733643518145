import React from 'react';

const PasswordResetEmailNotFoundPrompt = (props) => {
    return (
        <div className="prompts user-not-found">
            <h2 className="black-copy">FORGOT PASSWORD</h2>
            <p>
                <span className="black-copy"><strong>Email:</strong> {props.email}</span>
                <br />
                <br />
                <span className="red-copy"><strong>System Error:</strong></span>
                <br />
                <span className="red-copy">There is a temporary system-level outage. We apologize for the inconvenience.</span>
                <br />
                <br />
                <span className="red-copy">Please try again later or <a href="/contact">Contact Customer Service.</a></span>
            </p>
        </div>
    );
};

export { PasswordResetEmailNotFoundPrompt };