/**
 * Sets vehicle data to the global site profile object
 * @param {object} vehicle
 * @param {string} type - 'tce', 'main' (the source dataset the vehicle comes from)
 */
const setVehicleSiteProfile = (vehicle, type) => {
    const { year, make, model, submodel, tpms } = vehicle;
    window.siteProfile.vehicles[type] = {
        ymm: `${year}-${make}-${model}`,
        year,
        make,
        model,
        trim: submodel,
        tpms
    };
};

export { setVehicleSiteProfile };
