import React from 'react';
import PropTypes from 'prop-types';

import './form-actions.scss';

const FormActionsWrap = (props) => {
    const {
        primaryActionButton,
        secondaryActionButton
    } = props;

    return (
        <div className="form-actions btn-group">
            {secondaryActionButton ?
                <div className="secondary-action">
                    {secondaryActionButton}
                </div>
            : null
            }
            <div className="primary-action">
                {primaryActionButton}
            </div>
        </div>
    );
}

FormActionsWrap.defaultProps = {
    primaryActionButton: () => alert("developer needs to implement primary action button function"),
    secondaryActionButton: () => alert("developer needs to implement secondary action button function")
}

FormActionsWrap.propTypes = {
    primaryActionButton: PropTypes.object.isRequired,
    secondaryActionButton: PropTypes.object
}

export { FormActionsWrap }
