import {
    COMPARE_ADD_TIRE,
    COMPARE_REMOVE_TIRE,
    COMPARE_SET_I18N,
    COMPARE_SET_MODAL_STATE,
    COMPARE_SET_TIRES,
    COMPARE_SET_LOGGED_IN
} from '../actions';

export function compareSetModalState(payload) {
    return {
        type: COMPARE_SET_MODAL_STATE,
        payload
    };
}

export function compareSetTires(payload) {
    return {
        type: COMPARE_SET_TIRES,
        payload
    };
}

export function compareAddTire(payload) {
    return {
        type: COMPARE_ADD_TIRE,
        payload
    };
}

export function compareRemoveTire(payload) {
    return {
        type: COMPARE_REMOVE_TIRE,
        payload
    };
}

export function compareSetI18n(payload) {
    return {
        type: COMPARE_SET_I18N,
        payload
    };
}

export function compareSetLoggedIn(payload) {
    return {
        type: COMPARE_SET_LOGGED_IN,
        payload
    };
}
