/**
 * Utility to check if the siteId supports sso.
 * FCAC and TP sites support sso.
 * HT and WW sites do not support sso.
 * 
 * @returns {Boolean} true if siteId supports sso
 */
export const HasSSO = () => {
    // Whitelist of sso supported sites
    const ssoSites = ['fcac', 'tp'];

    return ssoSites.includes(window?.siteId?.toLowerCase());
};