import React from 'react';
import { SpecialOfferIcon } from '../util/OfferIcons';
import { PriceItem } from '../util/PriceItem';
import { OfferCopy } from '../util/OfferCopy';
import useProductPrice from '../util/ProductPriceContext';

export const MAPOffer = () => {
    const { showPrice } = useProductPrice();
    return (
        <div className="product-price map">
            <div className="product-price__text">
                <SpecialOfferIcon />
                {showPrice && <PriceItem />}
                <OfferCopy />
            </div>
        </div>
    );
};
