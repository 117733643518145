import React from 'react';

const handleGoToMyAccount = () => {
    window.location.href = '/account/welcome';
};

const NewlyMigratedUserFirstLoginPrompt = (props) => {
    return (
        <div className="prompts newly-migrated-user-first-login">
            <h3>Your login now works for all of our merged sites!</h3>
            <p>
                We recently merged your accounts across some of our websites. This means you can now use <strong>the same email and password</strong> you just logged in with to access your accounts on any of these four websites:
                <br />
                <br />
                <img width="200" src="https://s7d1.scene7.com/is/image/bridgestone/logos-sso-global-bsro?fmt=png-alpha&scl=1" />
                <br />
                <br />
                <br />
                <br />
                <button class="btn btn-go-to-account" type="button" onClick={handleGoToMyAccount}>Go to my account</button>
            </p>
        </div>
    );
};

export { NewlyMigratedUserFirstLoginPrompt };