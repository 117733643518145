/**
 * @module MAPDiscount
 * @description
 * Create a utility function that can calculate the price of four tires given pricing data as input.
 * It should return:
 * A subtotal price for four tires
 * A flag indicating that there is a discount
 * Another flag indicating that action is required to get the discount (rebate).
 * {
 *   subtotal: 303.96,
 *   hidePrice: false,
 *   discounted: true,
 *   actionRequired: true
 * }
 *
 * The business logic for this method is as follows:
 * TO-DO
 *
 */
import { allOffers, pricingDetails } from 'common/js/data/services/ecomm/quote';

const TIRE_QUANTITY = 4;
const STAGG_TIRE_QUANTITY = 2;
let allOffersCache = null;

/**
 * get all offers
 */
(async () => {
    allOffersCache = await allOffers('');
})();

/**
 * To extract the rebate discount from rebate label value using regex
 * @return {number} rebate discount
 */
const getRebateDiscount = (rebate) => {
    const rebateCode = rebate.code;
    const rebateNode = allOffersCache?.data?.tirePromotions?.find((rebate) => rebate.friendlyId === rebateCode);

    if (rebateNode) {
        return parseInt(rebateNode.totalOfflineSavings) || 0;
    }
    return 0;
};

/**
 *
 * @param {number} price  retail price
 * @param {number} rearTirePrice
 * @param {Array} promos promo array
 * @param {Array} rearPromos rear promo array
 * @param {Array} rebates rebates array
 * @param {Array} rearRebates
 * @param {Boolean} isLoggedIn  // user logged in or not
 * @returns
 */
export const calculatePrice = (
    price,
    rearTirePrice,
    promos = [],
    rearPromos = [],
    rebates,
    rearRebates,
    isLoggedIn = false
) => {
    // Private vars.  Needed to avoid null checks.
    const _price = price || 0;
    const _rearTirePrice = rearTirePrice || 0;
    const _promos = promos || [];
    const _rearPromos = rearPromos || [];
    const _rebates = rebates || [];
    const _rearRebates = rearRebates || [];

    const allPromos = [..._promos, ..._rearPromos];
    const requiresLoggedIn = !!allPromos.find((promo) => promo?.loggedInOnlyPromotion); // True if any promo has loggedInOnlyPromotion set to true
    const hidePrice = !!allPromos.find((promo) => promo?.hidePrice); // True if any promo has hidePrice set to true
    const retailPrice = getRetailPrice(_price, _rearTirePrice);
    const totalInstantSavings = getTotalInstantSavings(_price, _rearTirePrice, _promos[0], _rearPromos[0], requiresLoggedIn, isLoggedIn);
    const totalMailInRebate = getTotalMailInRebate(_rebates, _rearRebates);
    const subtotal = retailPrice - totalInstantSavings - totalMailInRebate;

    return {
        requiresLoggedIn,
        subtotal,
        hidePrice,
        discounted: subtotal < retailPrice,
        actionRequired: totalMailInRebate > 0
    };
};

/**
 * @description Calculates the retail price for the given tire.
 * For staggered fitment, the retail price will be calculated for both the front and rear tires separately.
 * @returns {Number}
 */
const getRetailPrice = (price, rearTirePrice) => {
    // * Staggered Fitment
    if (rearTirePrice) {
        // calculate the front and rear tires separately because they may have different prices
        return price.toFixed(2) * STAGG_TIRE_QUANTITY + rearTirePrice.toFixed(2) * STAGG_TIRE_QUANTITY;
    }

    // * Single Tire Fitment
    return price.toFixed(2) * TIRE_QUANTITY;
};

/**
 * @description Retrieves the total instant savings, will factor in staggered fitment.
 * The sale price is a precaculated value that is already discounted.
 * Returns the difference between the retail price and the sale price to determine the total instant savings.
 * For staggere fitment, the total instant savings will be calculated for both the front and rear tires separately.
 *
 * @returns {Number}
 */
const getTotalInstantSavings = (frontPrice, rearPrice, frontPromo, rearPromo, requiresLoggedIn, isLoggedIn) => {

    // If the promo requires the user to be logged in and the user is not logged in, return 0.
    // No Discount!
    if (requiresLoggedIn && !isLoggedIn) return 0;

    // * Staggered Fitment
    if (rearPromo && frontPromo) {
        return (
            ((frontPrice * STAGG_TIRE_QUANTITY) - (frontPromo?.salePrice?.value * STAGG_TIRE_QUANTITY)) +
            ((rearPrice * STAGG_TIRE_QUANTITY) - (rearPromo?.salePrice?.value * STAGG_TIRE_QUANTITY))
        );
    }

    // * Single Tire Fitment
    if (frontPromo) {
        return ((frontPrice * TIRE_QUANTITY) - (frontPromo?.salePrice?.value * TIRE_QUANTITY));
    }

    return 0;
};


/**
 * @description Retrieves the total mail in rebate discount amount.
 * This method will factor in staggered fitment.
 * The method will accumulate the rebates such that the same rebate is not counted twice.
 *
 * @returns {Number}
 */
const getTotalMailInRebate = (rebates, rearRebates) => {
    // Rebate Calculations
    // Accumlate the rebates such that same rebate is not counted twice
    const rebateValues = {};

    if (rearRebates) {
        rearRebates?.forEach((rebate) => {
            rebateValues[rebate.code] = getRebateDiscount(rebate);
        });
    }

    if (rebates) {
        rebates?.forEach((rebate) => {
            rebateValues[rebate.code] = getRebateDiscount(rebate);
        });
    }

    return Object.values(rebateValues).reduce((total, amount) => total + amount, 0);
};

/**
 * @description
 * Retrieves the pricing details for the given article ids.
 * This method will also retrieve the rebate details for the given article ids.
 * The rebate details will be added to the pricing details response.
 * The totalOfflineSavings property will contain the rebate discount amount.
 *
 * @param {String} storeNumber // Store number to scope results to.
 * @param {Array} articleIds // Array of one or more article ids.  The second article id is only used for staggered fitment.
 * @returns {Array} // Returns an array of pricing details for the given article ids.
 */
export const getDiscountDetails = async (storeNumber, articleIds) => {
    const pricingDetail = await pricingDetails({
        storeNumber,
        articleIds
    });

    if (pricingDetail && pricingDetail.success === 'true') {
        if (pricingDetail?.data?.products?.length > 0) {
            for (let i = 0; i < pricingDetail?.data?.products?.length; i++) {
                const product = pricingDetail?.data?.products[i];
                if (product?.rebates?.length > 0) {
                    for (let j = 0; j < product?.rebates?.length; j++) {
                        const rebate = product?.rebates[j];
                        const rebateDiscount = await getRebateAmount(rebate.code);
                        rebate.totalOfflineSavings = rebateDiscount;
                    }
                }
            }
        }
    }

    return pricingDetail;
};

/**
 * @description
 * Retrieves the rebate discount amount for the given rebate code.
 * This method will cache the all the offers for quick look up.
 * If the rebate code is not found, 0 will be returned.  Otherwise, the rebate discount amount will be returned.
 *
 * @param {String} rebateCode // The rebate code to retrieve the discount amount for.
 * @returns {String} // Returns the rebate discount amount.
 */
export const getRebateAmount = async (rebateCode) => {
    const rebate = allOffersCache?.data?.tirePromotions?.find((rebate) => rebate.friendlyId === rebateCode);

    if (rebate) {
        return rebate.totalOfflineSavings || 0;
    }

    return 0;
};
