import React from 'react';

const AccessTokenInvalidPrompt = (props) => {
    return (
        <div className="prompts account-verified-failed">
            <h2>ACCESS FAILED</h2>
            <p>
                <span className="red-copy">Access Token is invalid. Try again.</span>
                <br />
                <br />
            </p>
        </div>
    );
};

export { AccessTokenInvalidPrompt };