import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { tireDecisionGuideSwitch } from 'common/js/util/tireDecisionGuideSwitch';

const TireDecisionGuideAppRoutable = lazy(() => import(/* webpackChunkName: "tire-decision-guide/v1" */ './TireDecisionGuideApp'));

const TireDecisionGuideLoader = () => {
    if (!tireDecisionGuideSwitch()) return null;
    console.log('tireDecisionGuideLoader');

    const appRoot = document.getElementById('tireDecisionGuideApp');

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner />}>
                  <TireDecisionGuideAppRoutable />
              </Suspense>,
              appRoot
          )
        : null;
};

export { TireDecisionGuideLoader };
