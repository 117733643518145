import { Provider } from 'react-redux';
import { RootReactApp } from './RootReactApp';
import { render } from 'react-dom';
import React from 'react';

const reactAppLoader = async (store) => {
    const rootDiv = document.createElement('div');

    return render(
        <Provider store={store}>
            <RootReactApp />
        </Provider>,
        rootDiv
    );
};

export { reactAppLoader };
