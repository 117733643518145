import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const checkQueryStrings = () => {
    const queryString = getUrlParameter('bsro-ui-tc') || '';

    if (queryString === 'v2' || queryString === '2') {
        // force TSR v2 since we'll need that for tire comparison to work
        setCookie('bsro-ui-tsr', 'v2');
        setCookie('bsro-ui-tc', 'v2');
        return true;
    } else if (queryString === 'v1' || queryString === '1') {
        setCookie('bsro-ui-tc', 'v1');
        return false;
    }
};

export const tireComparisonSwitch = () => {
    // enable a way to trigger tire comparison, even if it's turned off.
    checkQueryStrings();

    const tsrVersion = getCookie('bsro-ui-tsr');
    const tcVersion = getCookie('bsro-ui-tc');
    console.log('tireComparisonSwitch', {
        tcCookie: tcVersion,
        tsrCookie: tsrVersion,
        tsrIsOn: tsrVersion === 'v2',
        test: 'test',
        tcIsOn: !(!tsrVersion || tsrVersion !== 'v2' || (tcVersion && tcVersion === 'v1'))
    });

    //////////////////
    // Tire Comparison ON
    //////////////////
    if (tsrVersion && tsrVersion === 'v1') {
        return false;
    }

    return true;

    ///////////////
    // Tire Comparison OFF
    ///////////////
    // if (tsrVersion && tsrVersion === 'v2' && tcVersion && tcVersion === 'v2') {
    //     return true;
    // }

    // return false;
};
