import React from 'react';
import { numberToCurrency } from 'common/js/util/currency/numberToCurrency';

export const SuperCents = (props) => {
    if (!props || !props.price) return null;

    const price = props.price;

    const formattedPrice = numberToCurrency(price);

    const splitPrice = formattedPrice.split('.');

    if (splitPrice.length < 2) return price; // If no decimal, return price as is.

    return (
        <>
            <span className='product-price__price-dollar' >{splitPrice[0]}.</span><sup>{splitPrice[1]}</sup><span className='product-price__price-ea'>ea.</span>
        </>
    );
};