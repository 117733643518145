import React from 'react';

const handleGoToMyAccount = () => {
    window.location.href = '/account/overview';
};

const AccountVerifiedSuccessPrompt = (props) => {
    return (
        <div className="prompts account-verified-success">
            <h2>ACCOUNT VERIFIED</h2>
            <p>
                <span className="green-copy">Thank you for verifying your account!</span>
                <br />
                <br />
                <button class="btn btn-go-to-account" type="button" onClick={handleGoToMyAccount}>Go to my account</button>
            </p>
        </div>
    );
};

export { AccountVerifiedSuccessPrompt };