/**
 * Sets tireSize data to the global site profile object
 * @param {object} tireSize
 * @param {string} type - 'tce', 'main' (the source dataset the tire size comes from)
 */
export const setTireSizeSiteProfile = (tireSize, type) => {

    window.siteProfile.tires[type] = {
        'lvl': 3,
        'ar': tireSize.aspectRatio,
        'cs': tireSize.crossSection,
        'rs': tireSize.rimSize,
        'tireSize': `${tireSize.crossSection}-${tireSize.aspectRatio}-${tireSize.rimSize}`,
        'dt': new Date().toUTCString()
    };
};