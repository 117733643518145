import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';

const VehicleSelectorConnected = lazy(
    () => import(/* webpackChunkName: "schedule-appointment_vehicle-selector/v2" */ './VehicleSelectorApp')
);

const VehicleSelectorLoader = () => {
    console.log('VehicleSelectorLoader');

    const appRoot = !window.featureSwitches?.isTrue('bsro-ui-appt-uvp')
        ? document.getElementById('vehicle-selector')
        : null;

    return appRoot
        ? createPortal(
              <Suspense fallback={<></>}>
                  <VehicleSelectorConnected />
              </Suspense>,
              appRoot
          )
        : null;
};

export { VehicleSelectorLoader };
