import React from 'react';
import { TagIcon } from './OfferIcons';
import { SignInOnlySavingsLink, InstantSavingsLink, MailRebateLink } from './OfferDetailLinks';

import useProductPrice from './ProductPriceContext';

import { numberToCurrency } from 'common/js/util/currency/numberToCurrency';

export const OfferCopy = () => {
    const {
        rearPrice,
        promo,
        rearPromo,
        rebate,
        rearRebate,
        requiresLoggedIn,
        isLoggedIn,
        isMAP = null,
        isLIDI = null,
        hasPromo = null,
        hasRebate = null,
        hasOffer = null,
        totalPrice = 0,
        lidiData,
        promoData,
        rebateData,
        showPrice,
        isAsLowAs
    } = useProductPrice();

    const finalOfferCopy = () => {
        const isLIDIAndLoggedIn = isLIDI && isLoggedIn;
        let copyElements = [];

        if (hasRebate || isAsLowAs) {
            copyElements.push(<>As low as </>);
        }

        copyElements.push(
            <>
                <strong>{numberToCurrency(totalPrice)}</strong> <>for 4 tires</>
            </>
        );

        if (isLIDIAndLoggedIn || hasPromo || hasRebate) {
            copyElements.push(<> with </>);
        }

        if (isLIDIAndLoggedIn) {
            copyElements.push(<SignInOnlySavingsLink promo={lidiData} />);
        }

        if (!isLIDI && hasPromo) {
            copyElements.push(<InstantSavingsLink promo={promoData} />);
        }

        if ((isLIDIAndLoggedIn || hasPromo) && hasRebate) {
            copyElements.push(<>, and </>);
        }

        if (hasRebate) {
            copyElements.push(<MailRebateLink rebate={rebateData} />);
        }

        return (
            <>
                {copyElements.map((element, index) => (
                    <React.Fragment key={index}>{element}</React.Fragment>
                ))}
            </>
        );
    };

    return (
        <>
            {showPrice && <hr className="product-price__separator" />}
            <div className="product-price__offer-copy">
                <div className="product-price__offer-copy-text">
                    {hasOffer && <TagIcon />}
                    <span>{finalOfferCopy()}</span>
                </div>
            </div>
        </>
    );
};
