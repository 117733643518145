import React from 'react';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
const onAnchorClick = (e) => {
    e.stopPropagation(); // To stop the event from bubbling up to the parent element.
    const linkValue = e.target.dataset.value;
    const linkText = e.target.textContent;
    setAdobeDataLayer({
        event: 'cta_click',
        cta_type: linkValue,
        cta_text: linkText
    });
};

import useProductPrice from './ProductPriceContext';

export const SignInOnlySavingsLink = () => {
    const { lidiData } = useProductPrice();

    return (
        <>
            <a
                onClick={onAnchorClick}
                href={`/offers/offer-detail/${lidiData?.code}`}
                className="product-price__offer-detail dL_couponClick"
                data-coupon-name={lidiData?.displayName}
                data-coupon-id={lidiData?.code}
                target="_blank"
                rel="noreferrer"
                data-value="Sign-in"
            >
                Sign In Only Savings
            </a>
        </>
    );
};

export const InstantSavingsLink = () => {
    const { promoData } = useProductPrice();

    return (
        <>
            <a
                onClick={onAnchorClick}
                href={`/offers/offer-detail/${promoData?.code}`}
                className="product-price__offer-detail dL_couponClick"
                data-coupon-name={promoData?.displayName}
                data-coupon-id={promoData?.code}
                target="_blank"
                rel="noreferrer"
                data-value="instant"
            >
                Instant Savings
            </a>
        </>
    );
};

export const MailRebateLink = () => {
    const { rebateData } = useProductPrice();

    return (
        <>
            <a
                onClick={onAnchorClick}
                href={`/offers/offer-detail/${rebateData?.code}`}
                className="product-price__offer-detail dL_couponClick"
                data-coupon-name={rebateData?.label}
                data-coupon-id={rebateData?.code}
                target="_blank"
                rel="noreferrer"
                data-value="rebate"
            >
                Mail In Rebate
            </a>
        </>
    );
};