import React, { useEffect, useState, useRef } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const ToolTipMessageApp = ({ hasVisitedSite }) => {
    const TOOL_TIP_LOCAL_STORAGE_KEY = 'bsro-ui-tooltip-shown';
    const TOOL_TIP_TIMEOUT = 5000;
    const [visible, setVisible] = useState(true);
    const timerRef = useRef(null);

    useEffect(() => {
        if (getLocalStorageItem(TOOL_TIP_LOCAL_STORAGE_KEY)) {
            setVisible(false);
        } else {
            showTooltip();
        }
    }, []);

    const hideToolTip = (evt) => {
        if (evt) {
            evt.preventDefault();
        }

        setLocalStorageItem(TOOL_TIP_LOCAL_STORAGE_KEY, 'true'); // Mark tooltip as shown
        setVisible(false);
    };

    const showTooltip = () => {
        if (!getLocalStorageItem(TOOL_TIP_LOCAL_STORAGE_KEY)) {
            setVisible(true);
            // Set a timer to hide the tooltip after 5 seconds
            startTimer();
        }
    };

    const onTimeout = () => {
        stopTimer();
        hideToolTip();
    };

    const startTimer = () => {
        timerRef.current = setTimeout(onTimeout, TOOL_TIP_TIMEOUT);
    };

    const stopTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    return (
        !hasVisitedSite &&
        visible && (
            <div
                onMouseEnter={stopTimer}
                onMouseLeave={startTimer}
                onTouchStart={stopTimer}
                onTouchEnd={startTimer}
                className="tool-tip"
            >
                <p>Sign In for a Better Experience</p>
                <p className="lighter">Save vehicles to your garage, get current offers, and more!</p>
                <a href="#" className="close" onClick={hideToolTip}>
                    Close
                </a>
            </div>
        )
    );
};

export { ToolTipMessageApp };
