import { setAddOns, setCart, setCheckoutStep } from 'common/js/data/redux/actionCreators/ecomm';
import { getAddOns, getCart } from 'common/js/data/services/ecomm/cart';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const ecommOrderSummaryReduxLoaderV2 = async (store) => {
    const handleGetCart = async (cartGuid) => {
        try {
            const response = await getCart(cartGuid);
            if (response.success === 'false') {
                // TODO: error handling
                // cartGuid is bad, remove from local storage
                window.localStorage.removeItem('guid');
                // set checkout step to -1 to indicate that cart can't be loaded
                store.dispatch(setCheckoutStep(-1));
                return false;
            } else {
                store.dispatch(setCart(response.data));
                if (store.getState().ecomm.checkout.step < 1) {
                    store.dispatch(setCheckoutStep(1));
                }
            }
        } catch (err) {
            console.log(err);
            return false;
        }
        return true;
    };

    const handleGetAddOns = async (cartGuid) => {
        try {
            const response = await getAddOns(cartGuid);
            if (response.success === 'false') {
                // TODO: error handling
            } else {
                store.dispatch(setAddOns(response.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    try {
        // if cart-guid exists in local-storage or cookie, get cart data with it.
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = lsCart && lsCart.guid ? lsCart.guid : false;

        if (cartGuid) {
            let pass = await handleGetCart(cartGuid);
            if (pass) await handleGetAddOns(cartGuid);
        } else {
            store.dispatch(setCheckoutStep(-1));
        }
    } catch (err) {
        console.log('ecommOrderSummaryReduxLoaderV2 failed:', err);
    }
};

export { ecommOrderSummaryReduxLoaderV2 };
