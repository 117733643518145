import React, { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { SwitchableApp } from 'common/components/content/react-shared/utils/SwitchableApp';

const importApp = () => {
    return import(/* webpackChunkName: "universal-vehicle-picker/v1" */ './UniversalVehiclePickerApp');
};

const UniversalVehiclePickerAppConnected = SwitchableApp({
    featureSwitchClass: 'bsro-ui-appt-uvp',
    importCallback: importApp
});

const UniversalVehiclePickerLoader = () => {
    console.log('universalVehiclePickerLoader');

    const appRoot = document.getElementById('universalVehiclePickerApp');

    return appRoot
        ? createPortal(
              <Suspense fallback={<></>}>
                  <UniversalVehiclePickerAppConnected />
              </Suspense>,
              appRoot
          )
        : null;
};

export { UniversalVehiclePickerLoader };
