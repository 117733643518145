import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import config from './config';
import { pathnameTest } from 'common/components/content/react-shared/utils/regex';
import { tireComparisonSwitch } from 'common/js/util/tireComparisonSwitch';

const TireComparisonApp = lazy(() => import(/* webpackChunkName: "tire-comparison/v1" */ './TireComparisonApp'));

const TireComparisonLoader = () => {
    if (!tireComparisonSwitch) return null;

    console.log('TireComparisonApp');
    const appRoot = document.getElementById('tire-comparison-app');

    const exclusionList = config.exclusionList;
    const url = new URL(document.location.href);
    const urlData = {
        pathname: url.pathname
    };
    const isPathnameValid = pathnameTest(urlData.pathname, exclusionList);

    return appRoot && isPathnameValid
        ? createPortal(
              <Suspense fallback={<></>}>
                  <TireComparisonApp />
              </Suspense>,
              appRoot
          )
        : null;
};

export { TireComparisonLoader };
