import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const checkQueryStrings = () => {
    const queryString = getUrlParameter('bsro-ui-account') || '';

    if (queryString === 'v3' || queryString === '3') {
        setCookie('bsro-ui-account', 'v3');
    } else if (queryString === 'v3.migration') {
        setCookie('bsro-ui-account', 'v3.migration');
    } else if (queryString === 'v2' || queryString === '2') {
        setCookie('bsro-ui-account', 'v2');
    } else if (queryString === 'v1' || queryString === '1') {
        setCookie('bsro-ui-account', 'v1');
    }
};

export const accountSwitch = () => {
    checkQueryStrings();

    const accountVersion = getCookie('bsro-ui-account');

    //////////////////
    // Account SSO ON
    //////////////////
    // if (accountVersion && accountVersion === 'v2') {
    //     return false;
    // }

    return true;

    ///////////////
    // Account SSO OFF
    ///////////////
    // if (accountVersion && (accountVersion === 'v2' || accountVersion === 'v3' || accountVersion === 'v3.migration')) {
    //     return true;
    // }

    // return false;
};
