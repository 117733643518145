const ecommCheckoutReduxLoader = async (store) => {
    try {
        // get the cart-guid from cookie or local-storage
        const cartGuid = '';

        if (cartGuid !== '') {
            // get cart data
            const cartDetails = { success: 'true' };
            // const cartDetails = await getCartDetails(cartGuid);

            if (cartDetails.success === 'false') {
                // ???
            } else {
                // add cart data to store
                // store.dispatch(myActionCreator(profile));
                // pass store to app loader
            }
        } else {
            // ???
        }
    } catch (err) {
        console.log('ecommCheckoutLoader failed:', err);
    }
};

export { ecommCheckoutReduxLoader };
