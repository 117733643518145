/**
 * Helper function to create a new quote based on previously created quote request data.
 * This function is needed to create a new quote when the user is not logged in.
 * Primarily used for Logged In Discounts on TDP.
 * When a user has a logged in discount and decides to log out, a new quote needs to be created to account for the calculations.
 *
 * @param {Object} data - quote request data
 * @returns {Promise<void>} - redirects to TDP with new quote id
 */

import { setCookie } from 'common/js/library/util';
import { createQuote } from 'common/js/data/services/ecomm/quote';
import { getTDPPath } from 'common/js/util/getTDPPath';
import { getLocalStorageItem, deleteLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { setLocalStorageCart } from 'common/js/data/localstorage/localStorageCart';
import { addToCart } from 'common/js/data/services/ecomm/cart';

const timeToLive = 60 * 60 * 1000; // 1 hour (in milliseconds)

export const CreateNewQuote = async (cartAction) => {
    // Checing previously created quote request objects.
    const quoteRequestData = getLocalStorageItem('quoteRequestTempData') || getLocalStorageItem('quoteRequestData');

    if (!quoteRequestData) return;

    try {
        const response = await createQuote(quoteRequestData);
        if (!response) return;
        if (response.success === 'true') {
            const quote = response.data;
            if (
                quote &&
                quote.tireQuoteId &&
                quote.tire &&
                quote.tire.tireName &&
                quote.tire.brand &&
                quote.tire.article
            ) {
                setCookie('bsro.ui.quoteID', quote.tireQuoteId);
                //To check cart if already an item is added for anonymouse user and he is signing as logged in user then we need to preserve this cart data.
                const lsCart = getLocalStorageItem('cart');
                if (lsCart && cartAction === "addCart") {
                    await addItemToCart(quote);
                } else if (lsCart && cartAction === "removeCart") {
                    deleteLocalStorageItem('cart');
                }

                const newPath = getTDPPath(
                    quote.tire.brand,
                    quote.tire.tireName,
                    quote.tire.article,
                    quote.tireQuoteId
                );

                // Redirect to TDP with new quote id
                window.location.href = `${newPath}${window.location.hash}`;
            } else {
                console.error('Failed to create new quote.');
            }
        } else if (response.success === 'false') {
            console.error('Failed to create new quote.');
        }
    } catch (err) {
        console.error(err);
    }
};

export const addItemToCart = async (quote) => {
    setLocalStorageCart(quote.cartGuid, timeToLive);

    const products = [
        {
            code: (quote.tire && quote.tire.article) || quote.article ||  '',
            qty: (quote.quantity && quote.quantity.toString()) || '0',
            inventory: quote.inventoryFront || '0'
        }
    ];
    if ((quote.rearTire && quote.rearTire.article) || quote.rearArticle) {
        products.push({
            code: (quote.rearTire && quote.rearTire.article) || quote.rearArticle || '',
            qty: quote.rearInventory || quote.inventoryFront || quote.quantity,
            inventory: quote.inventoryRear || '0'
        });
    }

    const cData = {
        bossConfirmed: 1, // override existing cart
        brgtEntries: {
            packageId: '1', // always 1. the key for the entry
            products
        }
    };

    let guid = quote.cartGuid || quote.guid;

    let response = await addToCart(cData, guid);
    return response;
};
