import React from 'react';
import { getCookie, getUrlParameter } from 'common/js/library/util';
import { postOptions } from 'common/js/data/services/serviceUtilities';
import { navigate } from '@gatsbyjs/reach-router';

const resendVerificationEmail = async (evt) => {
	try {

        evt.preventDefault();

        let anchor = evt.currentTarget;

        let temporaryTokenId = anchor.dataset.tokenid;

        let tokenId = temporaryTokenId || getUrlParameter('tokenId') || getCookie('tokenId');

        let dataPackage = { tokenId };

		const options = postOptions(dataPackage);

		const response = await fetch(`/bsro/services/account/send-email-verification`, options);

		if (!response.ok) {
			const errorData = await response.json();
			console.error("ERROR DATA: ", errorData);
			const errorString = `send-email-verification error: Response code ${errorData.status}.\n\nError: ${errorData.data.error}`;
			throw new Error(errorString);
		}
		const data = await response.json();

        if (data?.status === 'sessionExpired') {
            navigate('/account/sign-in');
            window.location.reload();
        } else if (data.success === 'true') {
            // Sent
            console.log('Sent');
            anchor.innerHTML = 'Sent';
        } else {
            // Unable to send
            anchor.innerHTML = 'Not Sent';
        }

		// TODO: get the send-email-verification service fixed.

	} catch (err) {
        return err;
	}
};

const EmailConfirmationRequiredPrompt = (props) => {
    return (
        <div className="prompts email-confirmation-required">
            <h2>VERIFY YOUR ACCOUNT</h2>
            <p>
                You must verify your email in order to access your account.  We just sent a verification email to <strong>{props.email}</strong>
                <br />
                <br />
                Please click the link in your email to verify your account.
                <br />
                <strong>The link will expire in 24 hours.</strong>
                <br />
                <br />
                <span className="red-copy"><strong>You will not be logged in until you verify your account.</strong></span>
                <br />
                <br />
                <a href="#" data-tokenId={props?.tokenId} onClick={resendVerificationEmail}>Re-send Verification Email</a>
            </p>
        </div>
    );
};

export { EmailConfirmationRequiredPrompt };