/**
 * Loads the My Store Redux store.
 *
 * @param {Object} store - The Redux store object.
 * @returns {Promise<void>}
 */
const myStoreReduxLoader = async (store) => {
    console.log('myStoreReduxLoader');
};

export { myStoreReduxLoader };
