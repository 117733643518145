import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { tireSearchResultsSwitch } from 'common/js/util/tireSearchResultsSwitch';

const TireSearchResultsApp = lazy(() => import(/* webpackChunkName: "tire-search-results/v1" */ './TireSearchResultsApp'));

const TireSearchResultsLoader = () => {
    if (!tireSearchResultsSwitch()) return null;

    console.log('tireSearchResultsLoader');
    const appRoot = document.getElementById('tire-search-results-app');

    const grid =
        document.querySelector('.product-results-grid') || document.querySelector('.tire-search-results-grid') || null;
    const searchType = grid ? (grid.getAttribute('data-vehicle') ? 'vehicle' : 'tire size') : null;

    const url = new URL(document.location.href);
    const urlData = {
        hash: url.hash,
        pathname: url.pathname
    };

    return appRoot
        ? createPortal(
              <Suspense fallback={<DefaultSpinner />}>
                  <TireSearchResultsApp searchType={searchType} urlData={urlData} />
              </Suspense>,
              appRoot
          )
        : null;
};

export { TireSearchResultsLoader };
