import {
    accountSetFullProfile,
    accountLoginUser,
    accountLogoutUser
} from 'common/js/data/redux/actionCreators/account';
import { checkUserToken, getUserDetails } from 'common/js/data/services/account/user';
import { getCookie } from 'common/js/library/util';

const updaterFunction = async (store) => {
    try {
        const token = getCookie('tokenId');

        if (typeof token !== 'undefined') {
            const updater = await checkUserToken(token);

            if (updater.success === 'true') {
                console.log('updater data: ', updater.data);

                if (store.getState().meta.tokenId !== updater.data.tokenId) {
                    // Redux Action - set new token id to store
                    // if returned token doesn't match existing token, set new token on Redux store in meta reducer
                    console.log('new token issued, need to set to store', updater);
                    store.dispatch(
                        accountLoginUser({
                            isLoggedIn: 'true',
                            tokenId: updater.data.tokenId
                        })
                    );
                }

                if (updater.data.updated === 'true') {
                    // get profile from server
                    // set profile to Redux store
                    const newCookie = getCookie('tokenId');
                    const responseData = await getUserDetails(newCookie);

                    if (responseData.success === 'false') {
                        // TODO: handle an error here
                    } else {
                        const account = responseData.data.account;
                        const profile = {
                            isLoggedIn: 'true',
                            tokenId: responseData.data.tokenId
                        };
                        store.dispatch(accountLoginUser(profile));
                        store.dispatch(accountSetFullProfile(account));
                    }
                }
            } else {
                accountLogoutUser();
            }
        } else {
            // unset all profile details?
        }
    } catch (err) {}
};

const authPoller = (store) => {
    setTimeout(() => updaterFunction(store), 5000);

    setInterval(() => updaterFunction(store), 270000);
};

export { authPoller };
