import {
    ECOMM_CART_EXPIRED,
    ECOMM_MERGE_CART,
    ECOMM_REMOVE_CART,
    ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY,
    ECOMM_SET_ADD_ONS,
    ECOMM_SET_CART,
    ECOMM_SET_CART_RESET_COUNTER,
    ECOMM_SET_CART_STATE,
    ECOMM_SET_CHECKOUT_APPOINTMENT,
    ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM,
    ECOMM_SET_CHECKOUT_BILLING,
    ECOMM_SET_CHECKOUT_PAYMENT,
    ECOMM_SET_CHECKOUT_STEP,
    ECOMM_SET_CHECKOUT_STORE_AVAILABILITY,
    ECOMM_SET_CHECKOUT_SUBMITTING,
    ECOMM_SET_ECOMM_STORE,
    ECOMM_SET_FORCE_NEW_QUOTE,
    ECOMM_SET_ORDER,
    ECOMM_SET_QUOTE,
    ECOMM_SET_SERVICE_NAMES_LIST,
    ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL,
    ECOMM_SET_TIRE_DETAIL_BUTTON_STATE,
    ECOMM_SET_TIRE_DETAIL_IS_IN_CART,
    ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST,
    ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE,
    ECOMM_SET_TIRE_DETAIL_VIEW_STATE,
    ECOMM_SET_SORTED_DATA
} from '../actions';

export function cartExpired(payload) {
    return {
        type: ECOMM_CART_EXPIRED,
        payload
    };
}

export function removeCart(payload) {
    return {
        type: ECOMM_REMOVE_CART,
        payload
    };
}

export function setCheckoutSubmitting(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_SUBMITTING,
        payload
    };
}

export function mergeCart(payload) {
    return {
        type: ECOMM_MERGE_CART,
        payload
    };
}

export function setQuote(payload) {
    return {
        type: ECOMM_SET_QUOTE,
        payload
    };
}

export function setForceNewQuote(payload) {
    return {
        type: ECOMM_SET_FORCE_NEW_QUOTE,
        payload
    };
}

export function setCart(payload) {
    return {
        type: ECOMM_SET_CART,
        payload
    };
}

export function setCartState(payload) {
    return {
        type: ECOMM_SET_CART_STATE,
        payload
    };
}

export function setCartResetCounter(payload) {
    return {
        type: ECOMM_SET_CART_RESET_COUNTER,
        payload
    };
}

export function setAddOns(payload) {
    return {
        type: ECOMM_SET_ADD_ONS,
        payload
    };
}

export function setOrder(payload) {
    return {
        type: ECOMM_SET_ORDER,
        payload
    };
}

export function setCheckoutAppointment(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_APPOINTMENT,
        payload
    };
}

export function setCheckoutAppointmentConfirm(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM,
        payload
    };
}

export function setCheckoutBilling(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_BILLING,
        payload
    };
}

export function setCheckoutPayment(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_PAYMENT,
        payload
    };
}

export function setCheckoutStep(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_STEP,
        payload
    };
}

export function replaceCheckoutStoreAvailability(payload) {
    return {
        type: ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY,
        payload
    };
}

export function setCheckoutStoreAvailability(payload) {
    return {
        type: ECOMM_SET_CHECKOUT_STORE_AVAILABILITY,
        payload
    };
}

export function setServiceNamesList(payload) {
    return {
        type: ECOMM_SET_SERVICE_NAMES_LIST,
        payload
    };
}

export function setEcommStore(payload) {
    return {
        type: ECOMM_SET_ECOMM_STORE,
        payload
    };
}

export function setTireDetailViewState(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_VIEW_STATE,
        payload
    };
}

export function setTireDetailQuoteViewState(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE,
        payload
    };
}

export function setTireDetailButtonState(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_BUTTON_STATE,
        payload
    };
}

export function setTireDetailButtonLabel(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL,
        payload
    };
}

export function setTireDetailIsInCart(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_IS_IN_CART,
        payload
    };
}

export function setTireDetailIsScrolledPast(payload) {
    return {
        type: ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST,
        payload
    };
}

export function setSortedData(payload) {
    return {
        type: ECOMM_SET_SORTED_DATA,
        payload
    };
}

export const setPricingDetailsTimestamp = (timestamp) => ({
    type: 'ECOMM_SET_PRICING_DETAILS_TIMESTAMP',
    payload: timestamp
});
