import { META_SET_CLOSEST_STORE_LOCATION, META_SET_CURRENT_ZIP, META_SET_WINDOW_SIZE } from '../actions';

export function metaSetClosestStoreLocation(storeLocation) {
    return {
        type: META_SET_CLOSEST_STORE_LOCATION,
        payload: {
            closestStoreLocation: storeLocation
        }
    };
}

export function metaSetCurrentZipCode(zip) {
    return {
        type: META_SET_CURRENT_ZIP,
        payload: {
            zip
        }
    };
}

export function metaSetWindowSize(windowDimensions) {
    return {
        type: META_SET_WINDOW_SIZE,
        payload: {
            windowDimensions
        }
    };
}
