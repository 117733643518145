/**
 * This component is used to lazy load a component based on a feature switch class.
 * It will check if the class is present on the body tag and immediately initiate the import.
 * If the class is not present, it will apply a mutation observer to detect the class change and initiate the import.
 *
 * It uses the React.lazy function to lazy load the component. The importCallback is a function that returns the
 * import statement specifict to a particular app.
 * @param {string} props.featureSwitchClass - The class name to check for on the body tag
 * @param {function} props.importCallback - The function that returns the import statement for the app
 */
import { lazy } from 'react';

export const SwitchableApp = (props) =>
    lazy(() => {
        return new Promise((resolve) => {
            const resolveImport = () => {
                resolve(props.importCallback());
            };

            // Check if the feature switch class is present on the body tag and immediately resolve the import
            if (document.body.classList.contains(props.featureSwitchClass)) {
                resolveImport();
            } else {
                // If the feature switch class is not present, apply a mutation observer to detect the class change
                const observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (mutation.attributeName === 'class') {
                            // Check if the feature switch class is present on the body tag
                            const currentClassState = mutation.target.classList.contains(props.featureSwitchClass);
                            if (currentClassState) {
                                observer.disconnect();
                                resolveImport();
                            }
                        }
                    });
                });
                observer.observe(document.body, { attributes: true });
            }
        });
    });
