import React from 'react';

const AccountVerifiedFailedPrompt = (props) => {
    return (
        <div className="prompts account-verified-failed">
            <h2>ACCOUNT VERIFICATION FAILED</h2>
            <p>
                <span className="red-copy">The Account Verifiication Failed.  Please Try Again.</span>
                <br />
                <br />
            </p>
        </div>
    );
};

export { AccountVerifiedFailedPrompt };