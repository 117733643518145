import React from 'react';
import PropTypes from 'prop-types';

const DefaultSpinner = (props) => {
    const { className, message, styles } = props;

    return (
        <div className={className} style={styles}>
            <div className="coral-Wait coral-Wait--center coral-Wait--large"></div>
            <p className="message">{message}</p>
        </div>
    );
};

DefaultSpinner.defaultProps = {
    className: 'wait',
    message: 'loading',
    styles: { marginTop: '175px', marginBottom: '300px' }
};

DefaultSpinner.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    styles: PropTypes.object
};

export { DefaultSpinner };
