import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';

import { TireDecisionGuideLoader } from 'common/components/content/tire-decision-guide/v1/js/ui/TireDecisionGuideLoader';
import { TireSearchResultsLoader } from 'common/components/content/tire-search-results/v1/js/ui/TireSearchResultsLoader';
import { TireComparisonLoader } from 'common/components/content/tire-comparison/v1/js/ui/TireComparisonLoader';
import { ReviewsLoader } from 'common/components/content/reviews/v1/js/ui/reviewsLoader';
import { ReviewsLoaderV2 } from 'common/components/content/reviews/v2/js/ui/reviewsLoader';
import { CompareCheckboxLoader } from 'common/components/content/compare-checkbox/v1/js/ui/CompareCheckboxLoader';
import { ProductPriceLoader } from 'common/components/content/product-price/v1/js/ui/ProductPriceLoader';
import { SignInButtonLoader } from 'common/components/content/signin-button/v1/js/ui/SignInButtonLoader';
import {
    MenuLoader,
    MenuLoaderV2,
    ToolTipLoader
} from 'common/components/content/account-topnav-menu/v1/js/MenuLoader';
import { AccountLoader } from 'common/components/content/account/v1/js/ui/AccountLoader';
import { EcommQuoteAnnexLoader } from 'common/components/content/ecomm-quote-annex/v1/js/ui/EcommQuoteAnnexLoader';
import { EcommQuoteAnnexLoaderV2 } from 'common/components/content/ecomm-quote-annex/v2/js/ui/EcommQuoteAnnexLoader';
import { EcommCartLoader } from 'common/components/content/ecomm-cart/v1/js/ui/EcommCartLoader';
import { EcommCartLoaderV2 } from 'common/components/content/ecomm-cart/v2/js/ui/EcommCartLoader';
import {
    EcommCartIconLoader,
    EcommCartIconLoaderV2
} from 'common/components/content/ecomm-cart-icon/v1/js/ui/EcommCartIconLoader';
import { EcommCheckoutLoader } from 'common/components/content/ecomm-checkout/v1/js/ui/EcommCheckoutLoader';
import { EcommOrderSummaryLoader } from 'common/components/content/ecomm-order-summary/v1/js/ui/EcommOrderSummaryLoader';
import { EcommOrderSummaryLoaderV2 } from 'common/components/content/ecomm-order-summary/v2/js/ui/EcommOrderSummaryLoader';
import { ExpeditedBookingLoader } from 'common/components/content/expedited-booking/v1/js/ui/ExpeditedBookingLoader';
import { ExpeditedBookingLoaderV2 } from 'common/components/content/expedited-booking/v2/js/ui/ExpeditedBookingLoader';
import Modal from 'common/components/content/react-shared/elements/modal/v1/Modal';
import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';
import { InterruptAnnexLoader } from 'common/components/content/interrupt-annex/v1/js/ui/InterruptAnnexLoader';
import { setModalHandler } from 'common/components/content/react-app-loader/v1/js/setModalHandler';
import { MyGarageLoader } from 'common/components/content/my-garage/v1/js/ui/MyGarageLoader';
import { MyStoreLoader } from 'common/components/content/my-store/v1/js/ui/MyStoreLoader';
import { VehicleSelectorLoader } from 'common/components/content/schedule-appointment/v2/js/ui/VehicleSelectorLoader';
import { UniversalVehiclePickerLoader } from 'common/components/content/universal-vehicle-picker/v1/js/ui/UniversalVehiclePickerLoader';

const RootReact = (props) => {
    const [modal, setModal] = useState({
        isOpen: false,
        content: null,
        closeModalFunction: null,
        containerClass: ''
    });

    const handleSetModal = setModalHandler(setModal);

    return (
        <>
            <ModalContext.Provider value={{ setGlobalModal: handleSetModal }}>
                <TireDecisionGuideLoader />
                <TireSearchResultsLoader />
                <TireComparisonLoader />
                <ReviewsLoader />
                <ReviewsLoaderV2 />
                <CompareCheckboxLoader />
                <ProductPriceLoader />
                <SignInButtonLoader />
                <MenuLoader />
                <MenuLoaderV2 />
                <ToolTipLoader />
                <AccountLoader />
                <EcommQuoteAnnexLoader />
                <EcommQuoteAnnexLoaderV2 />
                <EcommCartIconLoader />
                <EcommCartIconLoaderV2 />
                <EcommCartLoader />
                <EcommCartLoaderV2 />
                <EcommCheckoutLoader />
                <EcommOrderSummaryLoader />
                <EcommOrderSummaryLoaderV2 />
                <ExpeditedBookingLoader />
                <ExpeditedBookingLoaderV2 />
                <InterruptAnnexLoader />
                <MyStoreLoader />
                <MyGarageLoader />
                <VehicleSelectorLoader />
                <UniversalVehiclePickerLoader />
            </ModalContext.Provider>
            <Modal
                closeModal={modal.closeModalFunction}
                isModalOpen={modal.isOpen}
                isRoute={false}
                modalContent={modal.content}
                containerClass={modal.containerClass}
            />
        </>
    );
};

const RootReactApp = hot(RootReact);

export { RootReactApp };
