import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik/dist/index';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import TextInput from '../../elements/text-input/v1/TextInput';
import PasswordInput from '../../elements/password-input/v1/PasswordInput';
import { AccountVerifiedSuccessPrompt } from '../verify-email/prompts/AccountVerifiedSuccessPrompt';
import { AccountVerifiedFailedPrompt } from '../verify-email/prompts/AccountVerifiedFailedPrompt';
import { testEmail } from '../../utils/validation';
import { EmailConfirmationRequiredPrompt } from './prompts/EmailConfirmationRequiredPrompt';
import { NewlyMigratedUserFirstLoginPrompt } from './prompts/NewlyMigratedUserFirstLogin';
import { PasswordResetEmailNotFoundPrompt } from './prompts/PasswordResetEmailNotFoundPrompt';
import { SystemErrorPrompt } from './prompts/SystemErrorPrompt';
import { UserNotFoundPrompt } from './prompts/UserNotFoundPrompt';
import { satelliteTrack } from 'common/js/data/services/analytics/satellite';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';

import { authenticateUser } from 'common/js/data/services/account/user';
import {
    accountSetFullProfile,
    accountLoginUser,
    setAccountVehicles
} from 'common/js/data/redux/actionCreators/account';
import { setZipStoreHtml } from 'common/js/util/geolocation/setZipStoreHtml';
import { setStoreByStoreNumber } from 'common/js/data/services/store-location';
import { getCookie } from 'common/js/library/util';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
// Prompts

import { accountSwitch } from 'common/js/util/accountSwitch';

import { ChangePasswordForm } from 'common/components/content/account/v1/js/ui/preferences/ChangePasswordForm';

import { VehicleRepository, Vehicle, convertVehicleToVehicleFromAccount } from 'common/js/util/vehicle-repository';
import './prompts/_prompts.scss';

import { isYMMTInList } from 'common/js/util/vehicle-repository/isYMMTInList';

const EMAIL_CONFIRMATION_REQUIRED = 'EMAIL_CONFIRMATION_REQUIRED';
const NEWLY_MIGRATED_USER_FIRST_LOGIN = 'NEWLY_MIGRATED_USER_FIRST_LOGIN';
const SYSTEM_ERROR = 'SYSTEM_ERROR';
const USER_NOT_FOUND = 'USER_NOT_FOUND';
const ACCOUNT_VERIFIED_SUCCESS = 'ACCOUNT_VERIFIED_SUCCESS';
const ACCOUNT_VERIFIED_FAILED = 'ACCOUNT_VERIFIED_FAILED';
const PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED';
const PASSWORD_RESET_EMAIL_NOT_FOUND = 'PASSWORD_RESET_EMAIL_NOT_FOUND';

var IS_ACCOUNT_V3 = accountSwitch();

const signInFormSchema = Yup.object().shape({
    email: Yup.string().email().required('Email address is required to sign-in'),
    //.matches(/[a-zA-Z0-9!#$%&‘*+\–\/=?^_`.{|}~]/g),
    password: Yup.string().required('Password is required')
});

const SignIn = (props) => {
    const [formError, setFormError] = useState({
        hasError: false,
        errorMessage: null
    });

    useEffect(() => {
        // TRACKING CODE FOR CARDINAL PATH.
        // CAPTURE OPEN SIGN-IN MODAL
        satelliteTrack('account.modal.login');
    }, []);

    const {
        createAccount,
        forgotPassword,
        onSignInSuccess,
        dispatch,
        setLoggedIn,
        setAccountProfile,
        closeModal,
        newVehicleFromMyGarage,
        setAccountVehicles
        // account
    } = props;

    let touchedFields = {};

    const handlePasswordChangeRequired = (tokenId) => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: PASSWORD_CHANGE_REQUIRED,
            tokenId
        });
    };

    const handleUserNotFound = (email) => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: USER_NOT_FOUND,
            email
        });
    };

    const handleNewlyMigratedUserFirstLogin = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: NEWLY_MIGRATED_USER_FIRST_LOGIN
        });
    };

    const handleAccountVerifiedSuccess = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: ACCOUNT_VERIFIED_SUCCESS
        });
    };

    const handleAccountVerifiedFailed = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: ACCOUNT_VERIFIED_FAILED
        });
    };

    const handleSystemError = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: SYSTEM_ERROR
        });
    };

    const handleEmailConfirmationRequired = (email, tokenId) => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: EMAIL_CONFIRMATION_REQUIRED,
            email,
            tokenId
        });
    };

    const handlePasswordResetEmailNotFound = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: PASSWORD_RESET_EMAIL_NOT_FOUND
        });
    };

    const handleCreateAccount = (e) => {
        createAccount(e);
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        forgotPassword(e);
    };

    const handleSignInSuccess = async (data) => {
        // set loggedIn state and tokenId to store
        setLoggedIn({
            isLoggedIn: 'true',
            tokenId: data.tokenId
        });
        setAccountProfile(data.account);

        // set preferredStore in profile to current store
        let _zip, _store;
        const geolocationStatus = getCookie('geoPreference');
        console.log('geoPreference: ', geolocationStatus);
        if (geolocationStatus === 'allowed') {
            _zip = window.siteProfile.location.myZip
                ? window.siteProfile.location.myZip
                : window.siteProfile.location.autoZip;
        } else {
            _zip = window.siteProfile.location.autoZip;
        }

        if (data.account.DRIVER[0].PREFERRED_STORE) {
            const storeData = data.account.STORE.filter((store) => store.ID === data.account.DRIVER[0].PREFERRED_STORE);
            // console.log('STORE DATA: ', storeData);
            if (storeData.length) {
                _store = storeData[0].STORE_NUMBER;
            } else {
                _store = window.siteProfile.location.storeNumber;
            }
        } else {
            _store = window.siteProfile.location.storeNumber;
        }
        // /end set preferredStore in profile to current store

        // SET VEHICLE
        if (data.account.VEHICLE.length > 0) {
            const firstVehicle = data.account.VEHICLE[data.account.VEHICLE.length - 1];
            const { YEAR, MAKE, MODEL, SUBMODEL, TPMS } = firstVehicle;
            if (window.featureSwitches?.is('bsro-ui-global-nav', 'v1')) {
                await setVehicle(YEAR, MAKE, MODEL, SUBMODEL, TPMS);
            }
        }

        if (newVehicleFromMyGarage.year !== '') {
            newVehicleFromMyGarage.odometer = newVehicleFromMyGarage.mileage ? newVehicleFromMyGarage.mileage : '0';
            newVehicleFromMyGarage.nickname = newVehicleFromMyGarage.vehicleNickname
                ? newVehicleFromMyGarage.vehicleNickname
                : undefined;
            newVehicleFromMyGarage.drivingCondition = newVehicleFromMyGarage.drivingConditions
                ? newVehicleFromMyGarage.drivingConditions
                : undefined;
            newVehicleFromMyGarage.tpms = newVehicleFromMyGarage.tpms ? newVehicleFromMyGarage.tpms : '1';
            newVehicleFromMyGarage.vehicleId = newVehicleFromMyGarage.vehicleId
                ? newVehicleFromMyGarage.vehicleId
                : undefined;

            const vehicle = new Vehicle(newVehicleFromMyGarage);
            const isInList = await isYMMTInList(vehicle);
            if (!isInList) {
                const vehicleRepository = new VehicleRepository();
                await vehicleRepository.load(true);
                await vehicleRepository.setCurrentVehicle(vehicle);
                setAccountVehicles(
                    [...vehicleRepository.vehicles()].map((v) => convertVehicleToVehicleFromAccount(v[1]))
                );
                const { YEAR, MAKE, MODEL, SUBMODEL, TPMS } = convertVehicleToVehicleFromAccount(vehicle);
                await setVehicle(YEAR, MAKE, MODEL, SUBMODEL, TPMS);
            }
        }

        console.log('ZIP AND STORE NUMBER: ', { _zip, _store });
        setStoreByStoreNumber(_store);
        await setZipStoreHtml(_zip, _store);

        // analytics
        setAdobeDataLayer({
            event: 'account.login.success',
            email: data.account.DRIVER[0].EMAIL
        });

        // set account to store
        onSignInSuccess();
    };

    const getPrompt = () => {
        switch (formError?.errorType) {
            case PASSWORD_CHANGE_REQUIRED:
                return (
                    <ChangePasswordForm
                        tokenId={formError?.tokenId}
                        updateSuccess={handleSignInSuccess}
                        closeModal={closeModal}
                    />
                );
            case EMAIL_CONFIRMATION_REQUIRED:
                return <EmailConfirmationRequiredPrompt email={formError?.email} tokenId={formError?.tokenId} />;
            case NEWLY_MIGRATED_USER_FIRST_LOGIN:
                return <NewlyMigratedUserFirstLoginPrompt />;
            case ACCOUNT_VERIFIED_SUCCESS:
                return <AccountVerifiedSuccessPrompt />;
            case ACCOUNT_VERIFIED_FAILED:
                return <AccountVerifiedFailedPrompt />;
            case PASSWORD_RESET_EMAIL_NOT_FOUND:
                return <PasswordResetEmailNotFoundPrompt email={formError?.email} />;
            case USER_NOT_FOUND:
                return <UserNotFoundPrompt title="SIGN IN" email={formError?.email} />;
            case SYSTEM_ERROR:
                return <SystemErrorPrompt />;
        }
    };

    const handleSignInButtonClick = (values) => {
        if (values.email === '' || !testEmail(values.email)) {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'login',
                step_name: 'Sign In',
                field_name: 'Email',
                error_message: 'Email Address is required to sign-in'
            });
        }

        if (values.password === '') {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'login',
                step_name: 'Sign In',
                field_name: 'Password',
                error_message: 'Password is required'
            });
        }
    };

    const handleSubmit = async (values, actions) => {
        try {
            setFormError({
                hasError: false,
                errorMessage: null
            });

            let loginResult = await authenticateUser(values, dispatch);

            IS_ACCOUNT_V3 = loginResult?.version === 'v3';

            if (loginResult.success === 'false') {
                satelliteTrack('account.login.error');
                actions.setSubmitting(false);
                if (loginResult.status === '520') {
                    if (IS_ACCOUNT_V3 && loginResult?.message?.error === 'Internal Error') {
                        handleSystemError();
                    }
                } else if (loginResult.status === '003') {
                    if (IS_ACCOUNT_V3 && loginResult?.message?.error === 'Internal Error') {
                        handlePasswordResetEmailNotFound();
                    }
                } else if (loginResult.status === 'InvalidLogin') {
                    setFormError({
                        hasError: true,
                        errorMessage: 'Username or password is invalid'
                    });
                } else if (loginResult.status === '053') {
                    if (loginResult.data.message === 'Account Locked') {
                        setFormError({
                            hasError: true,
                            errorMessage: (
                                <span>
                                    Your account has been locked due to too many failed login attempts.
                                    <br />
                                    <a href="/account/forgot-password">Click to Reset Password</a>
                                </span>
                            )
                        });
                    } else if (IS_ACCOUNT_V3 && loginResult?.message?.error === 'Invalid Login') {
                        setFormError({
                            hasError: true,
                            errorMessage: 'Username or password is invalid'
                        });
                    } else if (IS_ACCOUNT_V3 && loginResult.message.error === 'Account Locked') {
                        setFormError({
                            hasError: true,
                            errorMessage: (
                                <span>
                                    Your account has been locked.
                                    <br />
                                    <a href="" onClick={handleForgotPassword}>
                                        Click to Reset Password
                                    </a>
                                </span>
                            )
                        });
                    } else if (IS_ACCOUNT_V3 && loginResult.message?.error === 'Email confirmation required') {
                        handleEmailConfirmationRequired(values.email, loginResult?.data?.tokenId);
                    } else if (IS_ACCOUNT_V3 && loginResult.message?.error === 'User not found') {
                        handleUserNotFound(values.email);
                    } else if (IS_ACCOUNT_V3 && loginResult.message?.error === 'Password change required') {
                        handlePasswordChangeRequired(loginResult?.data?.tokenId);
                    } else if (loginResult.data.message === 'Password change required') {
                        setFormError({
                            hasError: true,
                            errorMessage: (
                                <span>
                                    Due to account inactivity your password must be changed.
                                    <br />
                                    <a href="" onClick={handleForgotPassword}>
                                        Click to Reset Password
                                    </a>
                                </span>
                            )
                        });
                    } else if (
                        IS_ACCOUNT_V3 &&
                        (loginResult.message?.error === 'Access Token is invalid. Try again.' ||
                            loginResult.message?.error === 'Confirmation code is invalid. Try again')
                    ) {
                        handleAccountVerifiedFailed();
                    }
                }
            } else if (
                IS_ACCOUNT_V3 &&
                loginResult.success === 'true' &&
                loginResult.message?.error === 'Confirm Channel' &&
                loginResult.status === '200'
            ) {
                handleNewlyMigratedUserFirstLogin();
            } else if (
                IS_ACCOUNT_V3 &&
                loginResult.status === '200' &&
                loginResult.message?.error === 'Email verified successfully'
            ) {
                handleAccountVerifiedSuccess();
            } else if (
                IS_ACCOUNT_V3 &&
                loginResult.success === 'true' &&
                loginResult.status === '200' &&
                loginResult?.data?.loggedIn === 'true'
            ) {
                handleSignInSuccess(loginResult.data);
            } else {
                handleSignInSuccess(loginResult.data);
            }
        } catch (err) {
            handleSystemError(); // All unknown network errors.
        }
    };

    return (
        <div className="sign-in">
            {formError.hasError && formError?.errorType && getPrompt()}
            {!formError?.errorType && <h2>Sign In</h2>}
            {!formError?.errorType && formError.hasError ? (
                <div className="form-level-error">
                    <p style={{ color: 'red' }}>{formError.errorMessage}</p>
                </div>
            ) : null}
            {!formError?.errorType && (
                <Formik
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                    validationSchema={signInFormSchema}
                >
                    {({ errors, isSubmitting, values }) => (
                        <Form id="signInForm">
                            <Field
                                name="email"
                                id="signInEmail"
                                component={TextInput}
                                placeholder="Email"
                                onBlur={(e, fieldName) => {
                                    const { id } = e.target;
                                    if (!touchedFields[id] && (errors[fieldName] || errors[fieldName] === undefined)) {
                                        setAdobeDataLayer({
                                            event: 'forms.user_generated_error',
                                            form_name: 'login',
                                            step_name: 'Sign In',
                                            field_name: 'Email',
                                            error_message:
                                                errors[fieldName] === undefined
                                                    ? 'Email Address is required to sign-in'
                                                    : `${errors[fieldName]}`
                                        });
                                        touchedFields[id] = true;
                                    }
                                }}
                            />
                            <Field
                                name="password"
                                id="signInPassword"
                                component={PasswordInput}
                                placeholder="Password"
                                onBlur={(e, fieldName) => {
                                    const { id } = e.target;
                                    if (!touchedFields[id] && (errors[fieldName] || errors[fieldName] === undefined)) {
                                        setAdobeDataLayer({
                                            event: 'forms.user_generated_error',
                                            form_name: 'login',
                                            step_name: 'Sign In',
                                            field_name: 'Password',
                                            error_message: `${fieldName} is ${errors[fieldName]}`
                                        });
                                        touchedFields[id] = true;
                                    }
                                }}
                            />
                            <div className="fl-input-container">
                                <button
                                    className="btn btn-sign-in"
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => handleSignInButtonClick(values)}
                                >
                                    Sign In
                                </button>
                                <p className="acct-sign-in">
                                    <button
                                        className="form-footer-link remove-underline"
                                        onClick={handleForgotPassword}
                                    >
                                        Forgot Your Password?
                                    </button>
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {!formError?.errorType && (
                <p className="acct-sign-in">
                    Don’t have an account?{' '}
                    <button className="form-footer-link" onClick={handleCreateAccount}>
                        Sign Up
                    </button>
                </p>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const newVehicleFromMyGarage = state.vehicleSelector.vehicle;
    const account = state.account;

    return {
        newVehicleFromMyGarage,
        account
    };
};

const mapDispatch = (dispatch) => {
    return {
        setLoggedIn: (profile) =>
            dispatch(accountLoginUser({ isLoggedIn: profile.isLoggedIn, tokenId: profile.tokenId })),
        setAccountProfile: (profile) => dispatch(accountSetFullProfile(profile)),
        setAccountVehicles: (vehicles) => dispatch(setAccountVehicles(vehicles))
    };
};

const SignInForm = connect(mapStateToProps, mapDispatch)(SignIn);

export { SignInForm };
