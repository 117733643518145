import React, { useContext, useRef, useCallback, useEffect } from 'react';
import { render } from 'react-dom';
import { setCookie, removeCookie, getCookie } from 'common/js/library/util';
import { loginNameCookie } from 'common/js/data/services/serviceUtilities';

import { logoutUser } from 'common/js/data/services/account/user';

import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';

import { CreateAccountForm } from 'common/components/content/react-shared/components/create-account/CreateAccountForm';
import { SignInForm } from 'common/components/content/react-shared/components/sign-in/SignInForm';
import { ForgotPasswordForm } from 'common/components/content/react-shared/components/forgot-password/ForgotPasswordForm';
import { CreateNewQuote } from 'common/js/util/CreateNewQuote';
import { pubsub } from 'common/js/library/pubsub';

const DesktopMenuApp = (props) => {
    const { setGlobalModal } = useContext(ModalContext);

    //helpers for closing the menu clicking outside
    const myStoreMenuRef = useRef(null);

    const { driverName, driverFirstName, isLoggedIn, isTDP, serviceHistoryInvoice, isHeaderV2 } = props;

    const closeHandler = useCallback((e) => {
        e.stopPropagation();

        if (myStoreMenuRef.current && !myStoreMenuRef.current.contains(e.target)) {
            // console.log('close handler', e.target);
            hideNavPanel();
        }
    });

    //bind and unbind document events
    const bindDocumentEvents = () => {
        document.addEventListener('mousedown', closeHandler, true);
    };

    const unBindDocumentEvents = () => {
        document.addEventListener('mousedown', closeHandler, true);
    };

    const handleSignInSuccess = async () => {
        if (isTDP) {
            await CreateNewQuote('addCart');
        } else {
            window.location.reload();
        }
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        setGlobalModal(
            <SignInForm
                createAccount={handleCreateAccount}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={handleSignInSuccess}
            />
        );
    };

    const handleCreateAccountSuccess = async () => {
        setGlobalModal();

        if (isTDP) {
            await CreateNewQuote('addCart');
        } else {
            window.location.reload();
        }
    };

    const handleCreateAccount = (e) => {
        e.preventDefault();

        setGlobalModal(
            <CreateAccountForm
                openSignInForm={handleSignIn}
                closeModal={() => setGlobalModal()}
                onCreateAccountSuccess={handleCreateAccountSuccess}
            />
        );
    };

    const handleForgotPasswordSuccess = (par) => {
        setGlobalModal(
            <SignInForm
                createAccount={handleCreateAccount}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={handleSignInSuccess}
            />
        );
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        setGlobalModal(
            <ForgotPasswordForm
                openSignIn={handleSignIn}
                closeModal={() => setGlobalModal()}
                onForgotPasswordSuccess={handleForgotPasswordSuccess}
            />
        );
    };

    const handleSignOut = async (e) => {
        e.preventDefault();

        await logoutUser({ tokenId: getCookie('tokenId') });

        removeCookie([loginNameCookie, 'tokenId']);

        if (window.location.pathname.startsWith('/account') || window.location.pathname.startsWith('account')) {
            window.location.href = '/';
        } else if (isTDP) {
            // will redirect to TDP with new quote id
            await CreateNewQuote('removeCart');
        } else {
            window.location.reload(true);
        }
    };

    const displayWelcomeMessage = () => {
        if (isHeaderV2) {
            const rootDiv = document.getElementById('account-user-name');
            if (rootDiv) {
                render(
                    <>
                        <span>Hello, {driverName}</span>
                    </>,
                    rootDiv
                );
            }
        }
    };

    const displayHeaderV2Layout = () => {
        if (isHeaderV2) {
            const isHeaderV2 = document.querySelector('.top-nav-v2') ? true : false;
            if (isHeaderV2) {
                return (
                    <div className="nav-account-info">
                        <button className="close-btn" onClick={hideNavPanel}>
                            <img
                                src="https://s7d1.scene7.com/is/content/bridgestone/close-icon-global-web-bsro"
                                alt="Close Button"
                            />
                        </button>
                        <h3>
                            Hello,
                            <br />
                            {driverFirstName}
                        </h3>
                    </div>
                );
            }
        }
    };

    const subscribeToHeaderEvents = () => {
        if (isHeaderV2) {
            pubsub.subscribe('on-subnav-open', () => {
                hideNavPanel();
            });
            pubsub.subscribe('on-my-store-open', () => {
                hideNavPanel();
            });
            pubsub.subscribe('on-my-garage-open', () => {
                hideNavPanel();
            });
        }
    };

    const showNavPanel = () => {
        if (isHeaderV2) {
            const navPanel = document.querySelector('#desktopMenuApp-v2 .nav-account-panel');
            if (navPanel) {
                navPanel.classList.add('is-active');
                pubsub.publish('on-my-account-open');
            }
        }
    };

    const hideNavPanel = () => {
        if (isHeaderV2) {
            const navPanel = document.querySelector('#desktopMenuApp-v2 .nav-account-panel');
            if (navPanel) {
                navPanel.classList.remove('is-active');
            }
        }
    };

    const saveServiceHistory = (e) => {
        if (isHeaderV2) {
            removeCookie('service-history-invoice');
            setCookie('service-history-invoice', serviceHistoryInvoice);
        }
    };

    useEffect(() => {
        bindDocumentEvents();
        subscribeToHeaderEvents();

        return () => {
            unBindDocumentEvents();
        };
    }, []);

    return (
        <>
            {isLoggedIn === 'true' ? (
                <div ref={myStoreMenuRef}>
                    <button className="nav-account-toggle" onClick={showNavPanel}>
                        {isHeaderV2 ? driverFirstName : driverName}
                    </button>
                    <div className="nav-account-panel">
                        {displayHeaderV2Layout()}
                        <ul>
                            <li>
                                <a href="/account/overview">Account Overview</a>
                            </li>
                            <li>
                                {isHeaderV2 &&
                                serviceHistoryInvoice > 0 &&
                                serviceHistoryInvoice > getCookie('service-history-invoice') ? (
                                    <>
                                        <span className="notification">
                                            {serviceHistoryInvoice - getCookie('service-history-invoice')}
                                        </span>
                                        <a href="/account/vehicles" onClick={saveServiceHistory}>
                                            Vehicles &amp; Service
                                        </a>
                                    </>
                                ) : (
                                    <a href="/account/vehicles">Vehicles &amp; Service</a>
                                )}
                            </li>
                            <li>
                                <a href="/account/preferences">Preferences</a>
                            </li>
                            <li className="nav-sign-out">
                                {isHeaderV2 ? (
                                    <>
                                        <a href="/account/sign-out" onClick={handleSignOut}>
                                            Sign Out
                                        </a>
                                    </>
                                ) : (
                                    <a href="#" onClick={handleSignOut}>
                                        Sign Out
                                    </a>
                                )}
                            </li>
                        </ul>
                    </div>
                    {displayWelcomeMessage()}
                </div>
            ) : (
                <>
                    <a href="/account/create-account" onClick={handleCreateAccount}>
                        <span>Create an Account</span>
                    </a>
                    <a href="/account/sign-in" onClick={handleSignIn}>
                        <span>Sign In</span>
                    </a>
                </>
            )}
        </>
    );
};

export { DesktopMenuApp };
