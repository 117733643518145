import {
    TSR_ADD_APPLIED_FILTER,
    TSR_DISABLE_FILTER_SORT,
    TSR_MERGE_TIRE_DATA,
    TSR_REMOVE_ALL_APPLIED_FILTERS,
    TSR_REMOVE_APPLIED_FILTER,
    TSR_SET_COMPARE_GROUP,
    TSR_SET_DATA_ATTRIBUTES,
    TSR_SET_FILTERS,
    TSR_SET_INVENTORY,
    TSR_SET_INVENTORY_PRICING,
    TSR_SET_PRICING,
    TSR_SET_RESULT_TYPE,
    TSR_SET_SEARCH_TYPE,
    TSR_SET_SORT,
    TSR_SET_TAB,
    TSR_SET_VIEW,
    TSR_SET_LOGGED_IN
} from '../actions';

export function tsrSetDataAttributes(payload) {
    return {
        type: TSR_SET_DATA_ATTRIBUTES,
        payload
    };
}

export function tsrSetInventoryPricing(payload, callback) {
    return {
        type: TSR_SET_INVENTORY_PRICING,
        payload,
        callback
    };
}

export function tsrSetPricing(payload) {
    return {
        type: TSR_SET_PRICING,
        payload
    };
}

export function tsrSetInventory(payload) {
    return {
        type: TSR_SET_INVENTORY,
        payload
    };
}

export function tsrMergeTireData(payload) {
    return {
        type: TSR_MERGE_TIRE_DATA,
        payload
    };
}

export function tsrSetTab(payload) {
    return {
        type: TSR_SET_TAB,
        payload
    };
}

export function tsrSetSearchType(payload) {
    return {
        type: TSR_SET_SEARCH_TYPE,
        payload
    };
}

export function tsrSetResultType(payload) {
    return {
        type: TSR_SET_RESULT_TYPE,
        payload
    };
}

export function tsrSetFilter(payload, target) {
    return {
        type: TSR_SET_FILTERS,
        payload,
        target
    };
}

export function tsrSetSort(payload, target) {
    return {
        type: TSR_SET_SORT,
        payload,
        target
    };
}

export function tsrSetView(payload, target) {
    return {
        type: TSR_SET_VIEW,
        payload,
        target
    };
}

export function tsrAddAppliedFilter(payload, target) {
    return {
        type: TSR_ADD_APPLIED_FILTER,
        payload,
        target
    };
}

export function tsrRemoveAppliedFilter(payload, target) {
    return {
        type: TSR_REMOVE_APPLIED_FILTER,
        payload,
        target
    };
}

export function tsrDisableFilterSort(target) {
    return {
        type: TSR_DISABLE_FILTER_SORT,
        target
    };
}

export function tsrRemoveAllAppliedFilters(target) {
    return {
        type: TSR_REMOVE_ALL_APPLIED_FILTERS,
        target
    };
}

export function tsrSetCompareGroup(payload) {
    return {
        type: TSR_SET_COMPARE_GROUP,
        payload
    };
}

export function tsrSetLoggedIn(payload) {
    return {
        type: TSR_SET_LOGGED_IN,
        payload
    };
}