import { setZipStoreHtml } from './setZipStoreHtml';
import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';
import { getLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import {
    getStoreByStoreNumber,
    getStoreLocationListByLatLong,
    getUserIP,
    setStoreByStoreNumber
} from 'common/js/data/services/store-location';
import { metaSetClosestStoreLocation, metaSetCurrentZipCode } from 'common/js/data/redux/actionCreators/meta';
import { store } from 'common/js/data/redux/store/exportedStore';

const geoPref = getCookie('geoPreference');
const hasSetPreferredStore = getCookie('hasSetPreferredStore') || false;
const ipCookie = getCookie('geoIP') || false;

console.log('geolocation hasSetStore:', hasSetPreferredStore);

const tryGeo = () => {
    console.log('attempt geolocation');
    if (window.navigator.geolocation && hasSetPreferredStore == false) {
        console.log('geolocation requested');
        window.navigator.geolocation.getCurrentPosition(pos, posError);
        window.GEO_FLAG = true;
    } else {
        //window.debug_log("geolocation not supported");
    }
};

const pos = async (position) => {
    try {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        console.log('geolocation SUCCESS lat: ' + lat + ' long: ' + long);
        const storeList = await getStoreLocationListByLatLong(lat, long);
        const storeData = (Array.isArray(storeList) && storeList[0]) || {};
        const storeNumber = storeData.storeNumber || null; // window.siteProfile.location.storeNumber;
        const zip = storeData.zip || null; // window.siteProfile.location.autoZip;

        if (storeNumber && zip) {
            store.dispatch(metaSetCurrentZipCode(zip));
            console.log(`calling setZipStoreHtml(${zip}, ${storeNumber})`);
            setZipStoreHtml(zip, storeNumber);

            const storeToSet = await setStoreByStoreNumber(storeNumber);

            store.dispatch(metaSetClosestStoreLocation(storeToSet));

            if (geoPref !== 'allowed') {
                if (window._satellite) {
                    window._satellite.setVar('event_data', { storeNumber: storeNumber });
                    window._satellite.track('geolocation.accept');
                }

                setCookie('geoPreference', 'allowed');
            }
        }
    } catch (e) {
        // method doesnt exist yet
        console.log('geolocation ERROR');
    }
};

const posError = () => {
    console.log('geolocation DENIED');
    window.GEO_FLAG = false;

    if (geoPref !== 'denied') {
        setCookie('geoPreference', 'denied');
        if (window._satellite) {
            window._satellite.track('geolocation.deny');
        }
    }

    try {
        console.log('calling showZipForm()');
        window.showZipForm();
    } catch (e) {
        // method doesnt exist yet
    }
};

const trackIP = (ip) => {
    console.log('trackIP:', ip);
    if (ip) {
        window.digitalData.user[0].profile[0].ip = ip;
    }
};

const setIPCookie = (ip) => {
    const cookieData = {
        ip,
        timestamp: Date.now()
    };
    setCookie('geoIP', JSON.stringify(cookieData), 30);
};

const requestIP = async (track = false) => {
    try {
        const response = await getUserIP();
        const ip = (response.data && response.data.ip) || false;
        setIPCookie(ip);
        if (track) trackIP(ip);
    } catch (err) {
        console.log('IP Error:', err);
    }
};

const initIP = async () => {
    const cIP = (ipCookie && ipCookie.ip) || false;
    const timestamp = (ipCookie && ipCookie.timestamp) || 0;
    const day = 24 * 60 * 60 * 1000;
    console.log('initIP', {
        ipCookie,
        cIP,
        timestamp,
        day,
        now: Date.now(),
        isStale: Date.now() > timestamp + day
    });

    if (cIP) {
        trackIP(cIP);
        const isStale = Date.now() > timestamp + day;

        // if it's been longer than a day, refresh the IP address in the cookie.
        // otherwise, just refresh the timestamp in the cookie.
        if (isStale) {
            requestIP();
        } else {
            setIPCookie(cIP);
        }
    } else {
        requestIP(true);
    }
};

const geolocationSetup = async () => {
    // check if there is a store in the query string. if so, apply this store instead.
    let qsStore = getUrlParameter('storeNumber') || getUrlParameter('store');
    console.log('geo setup - qs test:', qsStore);
    if (window.siteProfile?.location?.myZip !== '') {
        document.getElementById('tab-my-store-container')?.classList?.remove('seo-page');
    }
    if (qsStore && !isNaN(qsStore)) {
        if (window.siteProfile?.location?.storeNumber) {
            window.siteProfile.location.storeNumber = qsStore;
            window.lwsStoreId = qsStore;
        }
        const storeToSet = await setStoreByStoreNumber(qsStore);
        const zip = storeToSet.data?.zip || '00000';

        console.log('geo setup - qs store:', qsStore, storeToSet, zip);

        store.dispatch(metaSetClosestStoreLocation(storeToSet));
        store.dispatch(metaSetCurrentZipCode(zip));
        setZipStoreHtml(zip, qsStore, true);

        return;
    }

    try {
        initIP();
        console.group('common/js/util/geolocation/setup.js');
        window.GEO_FLAG = false;

        console.log('GEO_PREF:', geoPref);

        let storeToSet = {};

        if (window.siteProfile) {
            if (window.siteProfile.location.locationLvl == 0) {
                console.log('location level 0');
                tryGeo();
            } else if (window.siteProfile.location.locationLvl == 1) {
                console.log('location level 1 with zip: ' + window.siteProfile.location.autoZip);
                //if (siteProfile.location.autoZip == "60605") {
                tryGeo();
                //}
            } else if (window.siteProfile.location.locationLvl === 2) {
                console.log('location level 2', window.siteProfile.location);
                const storeNumber = window.siteProfile.location.storeNumber;
                const zip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;
                let storeLS = getLocalStorageItem('storeData');
                const storeNumberLS = storeLS && storeLS.storeNumber;

                if (storeNumber) {
                    if (!storeLS || storeNumberLS != storeNumber) {
                        const response = await getStoreByStoreNumber(storeNumber);
                        setLocalStorageItem('storeData', response.data);
                        storeToSet = response.data;
                    } else {
                        storeToSet = storeLS;
                    }

                    store.dispatch(metaSetCurrentZipCode(zip));
                    store.dispatch(metaSetClosestStoreLocation(storeToSet));
                }
            } else {
                console.log('NO LOCATION LEVEL');
                // TODO: Determine if there is a further case to account for
                // window.debug_log("skipping geolocation : lvl " + window.siteProfile.location.locationLvl);
            }
        }

        const isPinnacleStore = (store) => {
            return store.onlineExperience === 'pinnacle';
        };

        if (window.digitalData) {
            const userProfile = window.digitalData.user?.[0]?.profile?.[0];

            if (userProfile) {
                userProfile.attributes = userProfile.attributes || {}; // Ensure attributes exist

                userProfile.attributes.pinnacle_status = window.featureSwitches.isTrue('bsro-ui-pinnacle')
                    ? 'in radius'
                    : 'outside radius';
                userProfile.attributes.pinnacle_selected = isPinnacleStore(storeToSet) ? 'true' : 'false';
            }
        }

        console.groupEnd();
    } catch (error) {
        console.log('Error in geolocation/setup.js', error);
    }
};

export { geolocationSetup };
