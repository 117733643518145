import { connect } from 'react-redux';
import { MenuApp, MenuAppV2, ToolTipApp } from './MenuApp';

const mapStateToProps = (state, ownProps) => {
    //const loggedIn = state.meta.isLoggedIn;
    const noName = 'Signed In';
    const firstName =
        state.account.profile.DRIVER &&
        state.account.profile.DRIVER.length > 0 &&
        state.account.profile.DRIVER[0].FIRSTNAME
            ? state.account.profile.DRIVER[0].FIRSTNAME + ' '
            : '';
    const lastName =
        state.account.profile.DRIVER &&
        state.account.profile.DRIVER.length > 0 &&
        state.account.profile.DRIVER[0].LASTNAME
            ? state.account.profile.DRIVER[0].LASTNAME.charAt(0) + '.'
            : '';
    const hasName =
        firstName !== ''
            ? state.account.profile.DRIVER && state.account.profile.DRIVER[0].LASTNAME
                ? firstName + lastName
                : firstName
            : noName;
    const serviceHistoryInvoice =
        state.account.profile.SERVICE_HISTORY_INVOICE.length > 0
            ? state.account.profile.SERVICE_HISTORY_INVOICE.length
            : 0;

    const visitedSite = false;

    return {
        isLoggedIn: state.account.isLoggedIn,
        driverName: state.account.isLoggedIn === 'true' ? hasName : null,
        driverFirstName: state.account.isLoggedIn === 'true' ? firstName : null,
        windowWidth: state.meta.windowDimensions.width,
        isTDP: window?._digitalData?.page?.pageInfo?.pageName === 'tires:tire-product',
        serviceHistoryInvoice: state.account.isLoggedIn === 'true' ? serviceHistoryInvoice : null,
        hasVisitedSite: visitedSite
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const MenuAppRedux = connect(mapStateToProps, mapDispatchToProps)(MenuApp);
const MenuAppReduxV2 = connect(mapStateToProps, mapDispatchToProps)(MenuAppV2);
const ToolTipRedux = connect(mapStateToProps, mapDispatchToProps)(ToolTipApp);

export { MenuAppRedux, MenuAppReduxV2, ToolTipRedux };
