import { reviewsMergeProducts, reviewsSetReviews } from 'common/js/data/redux/actionCreators/reviews';
import { getProductReviews } from 'common/js/data/services/reviews/reviews';
import { prConfig } from 'common/components/content/react-shared/utils/power-reviews/prConfig';
import { pubsub } from 'common/js/library/pubsub';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { topics } from 'common/js/library/topics';
import { updateProductSummaries } from 'common/components/content/react-shared/utils/power-reviews/updateProductSummaries';

// request all reviews data and add to the redux store
const reviewsReduxLoader = async (store) => {
    console.log('reviewsReduxLoader');

    const instantiate = async (els) => {
        let groupId,
            locale = 'en-US',
            reviewsId = '';

        const idArray = els
            .map((el, i) => {
                const id = stringSanitizer(el.dataset.id, 'upper');

                groupId = (prConfig && prConfig.groupId) || '';

                console.log('reviewsReduxLoader - init map:', {
                    i,
                    type: el.dataset.type,
                    id,
                    el
                });

                if (el.dataset.type && el.dataset.type === 'reviews') {
                    reviewsId = id;
                }

                return id || false;
            })
            .filter(Boolean);

        console.log('reviewsReduxLoader - idArray:', { idArray, els });
        // making this await because of merge conflict if it comes back after reviews
        // would need to fix merge conflict to remove.
        await updateProductSummaries(updateSummaries, idArray, groupId, locale);

        // if any els are type review, request review data also.
        if (reviewsId) {
            updateProductReviews(reviewsId);
        }
    };

    const updateSummaries = (data) => {
        store.dispatch(reviewsMergeProducts(data));
    };

    /**
        id=<one productId>[&locale=en_US][&groupId=1234][&page=<#N>][&pageSize=<# of reviews per page>][&sortBy=highestRatings|latest|oldest|lowerstRatings
            id is only required param
            page and pageSize used for pagination
            sortBy changes order
    */
    const updateProductReviews = async (id) => {
        if (!id) return false;

        // const page = 1;
        const pageSize = 6;
        const sortBy = 'highestRatings';

        try {
            const response = await getProductReviews({
                id,
                // page,
                pageSize,
                sortBy
            });

            console.log('updateProductReviews response:', { response });

            if (response.success === 'false') {
                return false;
            } else {
                store.dispatch(reviewsSetReviews(id, response.data, response.nextPageKey));
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    // Note: if anything triggers this on a page that reviews are on, it will reinitialize the set reviews request.
    pubsub.subscribe(topics.CREATE_REVIEWS, (els) => {
        instantiate(els);
    });

    const els = Array.from(document.querySelectorAll('.reviews-app'));
    instantiate(els);
};

export { reviewsReduxLoader };
