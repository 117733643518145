export const noSpace = (value) => {
    if (!value) return '';
    return value.replace(/\s+/g, '');
};

export const visaTest = (value) => /^4\d{15}$/.test(noSpace(value));

export const visaPrefixTest = (value) => /^4/.test(noSpace(value));

export const mastercardTest = (value) =>
    /^(?:5[1-5]\d{2}|222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)\d{12}$/.test(noSpace(value));

export const mastercardPrefixTest = (value) =>
    /^(?:5[1-5]\d{2}|222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)/.test(noSpace(value));

export const amexTest = (value) => /^3[47]\d{13}$/.test(noSpace(value));

export const amexPrefixTest = (value) => /^3[47]/.test(noSpace(value));

export const discoverTest = (value) => /^6(?:011|5\d{2})\d{12}$/.test(noSpace(value));

export const discoverPrefixTest = (value) => /^6(?:011|5\d{2})/.test(noSpace(value));

export const expirationDateTest = (value) => /^((0[1-9])|(1[0-2]))\/(\d{2})$/.test(value);

export const cvvTest = (value) => /^\d{3}$/.test(value);

export const cvvAmexTest = (value) => /^\d{4}$/.test(value);

export const cfnaCobrand = (value) => /^559330/.test(noSpace(value));
export const cfnaPrivate = (value) => /^271742/.test(noSpace(value));

/**
 * Validate if a pathname is part of a exclusion list
 */
const setRegexRules = (exclusionList) => {
    return exclusionList
        .filter((rule) => rule !== '/')
        .map((rule) => {
            const ruleSections = rule.split('/').filter((s) => s);
            if (ruleSections.includes('*')) {
                const pathnames = ruleSections.filter((s) => s !== '*').join('/');
                const rxString = `/\\b(${pathnames})\\b/*`;
                return new RegExp(rxString, 'i');
            } else {
                const pathnames = ruleSections.join('/');
                const rxString = `/\\b(${pathnames})\\b`;
                return RegExp(rxString, 'i');
            }
        });
};
export const pathnameTest = (pathname, exclusionList) => {
    let isPathnameValid = false;
    let isExcludedUrl = false;

    if (exclusionList.includes('/') && pathname == '/') {
        isExcludedUrl = true;
    } else {
        const regexRules = setRegexRules(exclusionList);
        const validationList = regexRules.map((rx) => {
            return rx.test(pathname);
        });
        isExcludedUrl = validationList.some((value) => value === true);
    }

    isPathnameValid = !isExcludedUrl;

    return isPathnameValid;
};
