import React, { Suspense, useEffect, useState } from 'react';
import { SignInButtonApp } from './SignInButtonApp';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

const SignInButtonLoader = () => {
    console.log('SignInButtonLoader');
    const initialSignInButtons = Array.from(document.querySelectorAll('.login-app'));

    const signInButtons = (appRoots) => {
        const portalArray = appRoots
            .map((root, i) => {
                const uniqueId = `${i}${Math.random().toString(36).substring(2, 15)}`;
                const label = root.dataset.label || 'Sign In';
                return (
                    <React.Fragment key={i}>
                        <DynamicPortal
                            id={`signin-button-portal-${uniqueId}`}
                            className="signin-button-wrapper"
                            dynamicParentElement={root}
                            hideParent={true}
                        >
                            <Suspense fallback={<DefaultSpinner />}>
                                <SignInButtonApp label={label} />
                            </Suspense>
                        </DynamicPortal>
                    </React.Fragment>
                );
            })
            .filter(Boolean);

        return portalArray;
    };

    const [roots, setRoots] = useState(initialSignInButtons);
    const [portals, setPortals] = useState(signInButtons(roots));

    useEffect(() => {
        setPortals(signInButtons(roots));
    }, [roots]);

    pubsub.subscribe(topics.OFFERS_SIGN_IN_ONLY, (els) => {
        console.log('OFFERS_SIGN_IN_ONLY', els);
        els && setRoots(els);
    });

    return portals ? portals : null;
};

export { SignInButtonLoader };
