import { getCookie } from 'common/js/library/util';
import { setTireSize } from 'common/js/data/services/tire';
import { setTireSizeSiteProfile } from './setTireSizeSiteProfile';

/**
 * Sets the tire cookie, then updates the site profile
 * @param {object} tireSize
 * @returns
 */
export const setTireSizeCookie = (tireSize) => {
    const { aspectRatio, crossSection, rimSize } = tireSize;

    if (!aspectRatio || !crossSection || !rimSize) return false;

    const type = window.isEcom ? 'tce' : 'main';
    const cookieData = getCookie(`bsro.cp-${window.siteId}`);
    const cookiedTire = cookieData && cookieData.tires && cookieData.tires[type];

    // if we already have a tire size cookied and it matches the siteProfile tire size, skip
    if (
        cookiedTire &&
        cookiedTire.ar === aspectRatio &&
        cookiedTire.cs === crossSection &&
        cookiedTire.rs === rimSize
    ) {
        return false;
    }

    setTireSize(tireSize);
    setTireSizeSiteProfile(tireSize, type);
};