import React, { useContext } from 'react';

import { ExclusiveOfferIcon, TagIcon } from '../util/OfferIcons';
import { PriceItem } from '../util/PriceItem';
import { OfferCopy } from '../util/OfferCopy';
import useProductPrice from '../util/ProductPriceContext';
import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';

import { CreateAccountForm } from 'common/components/content/react-shared/components/create-account/CreateAccountForm';
import { SignInForm } from 'common/components/content/react-shared/components/sign-in/SignInForm';
import { ForgotPasswordForm } from 'common/components/content/react-shared/components/forgot-password/ForgotPasswordForm';

import { CreateNewQuote } from 'common/js/util/CreateNewQuote';

const isTDP = window?._digitalData?.page?.pageInfo?.pageName === 'tires:tire-product';

export const SignInOnly = (props) => {
    const { showPrice } = useProductPrice();
    const { type } = props;
    const { setGlobalModal } = useContext(ModalContext);

    const onSignIn = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setGlobalModal(
            <SignInForm
                createAccount={onSignUp}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={onSignInSuccess}
            />
        );
    };

    const onSignUp = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setGlobalModal(
            <CreateAccountForm
                openSignInForm={onSignIn}
                closeModal={() => setGlobalModal()}
                onCreateAccountSuccess={onCreateAccountSuccess}
            />
        );
    };

    const onSignInSuccess = async () => {
        if (isTDP) {
            await CreateNewQuote('addCart');
        } else {
            window.location.reload();
        }
    };

    const onCreateAccountSuccess = async () => {
        setGlobalModal();

        if (isTDP) {
            await CreateNewQuote('addCart');
        } else {
            window.location.reload();
        }
    };

    const onForgotPasswordSuccess = () => {
        setGlobalModal(
            <SignInForm
                createAccount={onSignUp}
                forgotPassword={handleForgotPassword}
                closeModal={() => setGlobalModal()}
                onSignInSuccess={onSignInSuccess}
            />
        );
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        setGlobalModal(
            <ForgotPasswordForm
                openSignIn={onSignIn}
                closeModal={() => setGlobalModal()}
                onForgotPasswordSuccess={onForgotPasswordSuccess}
            />
        );
    };

    return (
        <div className={`product-price ${type !== 'compare-tray' ? 'signin-only' : ''}`}>
            <div className="product-price__text">
                {type === 'compare-tray' ? (
                    <PriceItem />
                ) : (
                    <>
                        <ExclusiveOfferIcon />
                        {showPrice && <PriceItem />}
                        <OfferCopy />
                        <div className="product-price__signin-only-description">
                            <span>
                                <TagIcon />
                            </span>
                            <span className="additional-savings-text">
                                <>Additional savings when you </>
                                <button className="product-price__signin-only-link" onClick={onSignIn}>
                                    Sign In
                                </button>
                                <> or </>
                                <button className="product-price__signin-only-link" onClick={onSignUp}>
                                    Sign Up
                                </button>
                                <> for free</>
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
