import '../scss/jump-links.scss';
import { component } from 'common/js/library/component';

let jumpLinks;

const init = (config) => {
    jumpLinks = document.querySelector(config?.selector);

    if (!jumpLinks) return;
};

const instanceInit = (rootEl) => {
    if (!rootEl) return;

    const list = jumpLinks?.querySelector('ul');
    const listItems = list?.querySelectorAll('li');

    [...listItems].forEach((el) => {
        const link = el?.querySelector('a.button');
        link.addEventListener('click', (event) => {
            event.preventDefault();
            if (!event.currentTarget.classList.contains('is-active')) {
                const sectionId = event.currentTarget.getAttribute('href').replace('#', '');
                const section = document.getElementById(sectionId);
                if (section) {
                    resetAllButtons();
                    event.currentTarget.classList.add('is-active');
                    window.scrollTo({
                        top: section.offsetTop - jumpLinks.offsetHeight,
                        behavior: 'smooth'
                    });
                }
            }
        });
    });

    const resetAllButtons = () => {
        [...listItems].forEach((el) => {
            const link = el?.querySelector('a.button');
            link.classList.remove('is-active');
        });
    };

    const activeButton = (id) => {
        const link = jumpLinks.querySelector("[href='#" + id + "']");
        link.classList.add('is-active');
    };

    const observerOptions = {
        root: null,
        threshold: 0,
        rootMargin: '-50% 0% -50% 0%'
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                resetAllButtons();
                activeButton(entry.target.getAttribute('id'));
            }
        });
    }, observerOptions);

    [...listItems].forEach((el) => {
        const link = el?.querySelector('a.button');
        const sectionId = link.getAttribute('href').replace('#', '');
        const section = document.getElementById(sectionId);
        if (section) {
            observer.observe(section);
        }
        if (sectionId === 'jsi_wrapper') {
            window.addEventListener('bsro-ui-lsp-offers-ready', (event) => {
                const sectionOffers = document.getElementById('jsi_wrapper');
                if (sectionOffers) {
                    observer.observe(sectionOffers);
                }
            });
        }
    });
};

component({
    selector: '.jump-links',
    instanceInit,
    init,
    name: 'JumpLinks'
});
