import { getCookie } from 'common/js/library/util';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';
import { setVehicleSiteProfile } from './setVehicleSiteProfile';

/**
 * Sets the vehicle cookie, then updates the site profile
 * @param {object} vehicle
 * @returns
 */
const setVehicleCookie = (vehicle) => {
    const { year, make, model, submodel } = vehicle;

    // require a valid vehicle data to process
    if (!year || !make || !model || !submodel) return false;

    const vehicleType = window.isEcom ? 'tce' : 'main';
    const cookieData = getCookie(`bsro.cp-${window.siteId}`);
    const cookiedVehicle = cookieData && cookieData.vehicles && cookieData.vehicles[vehicleType];

    // if we already have a vehicle cookied and it matches the siteProfile vehicle, skip
    if (
        cookiedVehicle &&
        cookiedVehicle.year === vehicle.year &&
        cookiedVehicle.make === vehicle.make &&
        cookiedVehicle.model === vehicle.model &&
        cookiedVehicle.trim === vehicle.submodel
    ) {
        return false;
    }

    setVehicle(year, make, model, submodel);
    setVehicleSiteProfile(vehicle, vehicleType);
};

export { setVehicleCookie };
