import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik/dist/index';
import * as Yup from 'yup';

import TextInput from '../../elements/text-input/v1/TextInput';
import Button from '../../elements/button/v1/Button';
import { forgotPassword } from 'common/js/data/services/account/user';
import { FormActionsWrap } from '../../ui/FormActionsWrap/FormActionsWrap';

import { UserNotFoundPrompt } from '../sign-in/prompts/UserNotFoundPrompt';
import { SystemErrorPrompt } from '../sign-in/prompts/SystemErrorPrompt';
import { accountSwitch } from 'common/js/util/accountSwitch';

const USER_NOT_FOUND = 'USER_NOT_FOUND';
const SYSTEM_ERROR = 'SYSTEM_ERROR';

const IS_ACCOUNT_V3 = accountSwitch();

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Please provide an email address").email().matches(/[a-zA-Z0-9!#$%&‘*+\–\/=?^_`.{|}~]/g)
});

const ForgotPasswordForm = (props) => {

    const [ forgotPasswordSuccess, setForgotPasswordSuccess ] = useState(false);
    const {
        openSignIn,
        onForgotPasswordSuccess
    } = props;
	const [ formError, setFormError ] = useState({
		hasError: false,
		errorMessage: null
    });

    const handleUserNotFound = (email) => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: USER_NOT_FOUND,
            email
        });
    };

    const handleSystemError = () => {
        setFormError({
            hasError: true,
            errorMessage: null,
            errorType: SYSTEM_ERROR
        });
    };

    const handleOpenSignIn = (e) => {
		openSignIn(e);
	}

    const handleSubmit = async (values, actions) => {
        // console.log('forgot password submit: ', values);
        // console.log('forgot password submit "actions": ', actions);
        const responseData = await forgotPassword(values.email);

        if (!responseData) {
            handleSystemError();
        }

        if (responseData.success === 'false') {
            if (IS_ACCOUNT_V3 &&
                responseData?.status === '053' &&
                responseData?.message?.error === 'User not found') {
                    handleUserNotFound(values.email);
                } else if (IS_ACCOUNT_V3 &&
                    responseData?.status === '520' &&
                    responseData?.message?.error === 'Internal Error') {
                    handleSystemError();
            } else {
                actions.setSubmitting(false);
                console.log('Forgot Password response failure: ', responseData);
            }

        } else if (responseData.success === 'true') {
            setForgotPasswordSuccess(true);
        }
    }

    const handleClickSignIn = (e) => {
        e.preventDefault();
        onForgotPasswordSuccess();
    }

    return (
        <div className="forgot-password">
            {formError.hasError && formError?.errorType === USER_NOT_FOUND && <UserNotFoundPrompt email={formError?.email} />}
            {formError.hasError && formError?.errorType === SYSTEM_ERROR && <SystemErrorPrompt />}
            {!formError?.errorType &&
                <div>
                    <h2>Forgot Password</h2>
                    <p>To reset your password, please enter your account email address and press submit.</p>
                    {(forgotPasswordSuccess) ?
                    <p style={{color: 'green'}}>
                        Password reset request has been submitted. Please check your email for further instructions.
                        <br/>
                        <a href="#" onClick={handleClickSignIn}>Sign in.</a>
                    </p>
                    : null
                    }
                    <Formik
                        initialValues={{email: ''}}
                        onSubmit={handleSubmit}
                        validationSchema={forgotPasswordSchema}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form id="forgotPasswordForm">
                                <Field name="email" id="forgotPasswordEmail" component={TextInput} placeholder="Email" />
                                <FormActionsWrap
                                    primaryActionButton={<Button styleName="sm" clickAction={()=> {}} type="submit" disabled={isSubmitting}>Submit</Button>}
                                    secondaryActionButton={<Button styleName="secondary sm" clickAction={handleOpenSignIn} disabled={isSubmitting}>Cancel</Button>}
                                />
                            </Form>
                        )}
                    />
                </div>
            }
        </div>
    );
}

export { ForgotPasswordForm }
