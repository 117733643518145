import { DesktopIcon } from './DesktopIcon';
import { connect } from 'react-redux';
import { setCartState } from 'common/js/data/redux/actionCreators/ecomm';

const mapStateToProps = (state, ownProps) => {
    return { isOpen: state?.ecomm?.isCartOpen || false };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateCartState: (data) => dispatch(setCartState(data))
    };
};

const DesktopIconRedux = connect(mapStateToProps, mapDispatchToProps)(DesktopIcon);

export { DesktopIconRedux };
