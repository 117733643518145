/**
 * A component that renders the price for a single tire.  This component is used by the ProductPrice component.
 * It's responsible for rendering the price and fitment for a single tire.  It's also responsible for rendering
 * the fitment title if the fitment is staggered.
 *
 * @module PriceItem
 */

import React from 'react';

import { SuperCents } from './SuperCents';

import useProductPrice from './ProductPriceContext';

/**
 * Helper component to render the fitment title if the fitment is staggered
 *
 * @param {Object} props
 * @returns {JSX.Element | null}
 */
const FitmentTitle = (props) => {
    if (!props || !props.fitment) return null;

    return <div className="product-price__fitment"><span>{props.fitment}:</span></div>;
};

/**
 * Helper component to render the price for a single tire.  Like a line item in a list.
 *
 * @param {Object} props
 * @returns {JSX.Element | null}
 */
const PriceLineItem = (props) => {
    const { isLIDI, isStaggeredFitment, lidiData, rearLidiData, priceFormatted, rearPriceFormatted, isLoggedIn } =
        useProductPrice();

    const isRear = props.fitment === 'Rear';

    const salePriceFormatted = (isRear ? rearLidiData : lidiData)?.salePrice?.formattedValue;

    const retailPriceFormatted = isRear ? rearPriceFormatted : priceFormatted;

    return (
        <div className="product-price__price-item">
            {isStaggeredFitment && <FitmentTitle fitment={props.fitment} />}
            <div className="product-price__price">
                {isLIDI && isLoggedIn && (
                    <>
                        <SuperCents price={salePriceFormatted} />
                        <span className="product-price__price-or">or</span>
                    </>
                )}
                <SuperCents price={retailPriceFormatted} />
            </div>
        </div>
    );
};

/**
 * Renders the price for a single tire.  This component is used by the ProductPrice component.
 * It's responsible for rendering staggered fitment if applicable.
 *
 * @returns {JSX.Element | null}
 */
export const PriceItem = () => {
    const { isStaggeredFitment } = useProductPrice();

    return (
        <>
            <PriceLineItem key="front-tire" fitment="Front" />
            {isStaggeredFitment && <PriceLineItem key="rear-tire" fitment="Rear" />}
        </>
    );
};
