import React, { Suspense, lazy, useEffect, useState } from 'react';
import { DefaultSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/DefaultSpinner';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';

const CompareCheckboxApp = lazy(() =>
    import(/* webpackChunkName: "compare-checkbox/v1" */ './CompareCheckboxAppRedux')
);

const CompareCheckboxLoader = () => {
    const initialCompareCheckboxes = Array.from(document.querySelectorAll('.compare-button-app'));
    if (initialCompareCheckboxes.length > 0) {
        const compareCheckboxes = (appRoots) => {
            let tireResults = document.querySelector('[data-tire-results]')?.dataset?.tireResults;
            const results = tireResults ? JSON.parse(tireResults) : null;
            // determine what compare checkbox app it is and apply the matching components.
            const portalArray = appRoots
                .map((root, i) => {
                    const articleId = root.dataset.article;
                    let frontArticleId = articleId;
                    let rearArticleId = null;
                    if (root.dataset.article.includes('_')) {
                        const ids = root.dataset.article.split('_');
                        frontArticleId = ids[0];
                        rearArticleId = ids[1];
                    }

                    const uniqueId = `${i}${new Date().getTime()}`;
                    let tireCompareData = {};
                    let frontTireData = {};
                    let rearTireData = {};
                    if (results) {
                        frontTireData = results[frontArticleId].tire;
                        frontTireData.tireSurveyDetails = results[frontArticleId].tireSurveyDetails;

                        if (rearArticleId) {
                            rearTireData = results[rearArticleId].tire;
                            rearTireData.tireSurveyDetails = results[rearArticleId].tireSurveyDetails;
                            tireCompareData = [frontTireData, rearTireData];
                        } else {
                            tireCompareData = frontTireData;
                        }
                    }

                    return (
                        <React.Fragment key={i}>
                            <DynamicPortal
                                id={`compare-checkbox-portal-${uniqueId}`}
                                className="compare-checkbox"
                                dynamicParentElement={root}
                                hideParent={true}
                            >
                                <Suspense fallback={<DefaultSpinner />}>
                                    <CompareCheckboxApp
                                        articleId={articleId}
                                        frontArticleId={frontArticleId}
                                        rearArticleId={rearArticleId}
                                        tireCompareData={tireCompareData}
                                    />
                                </Suspense>
                            </DynamicPortal>
                        </React.Fragment>
                    );
                })
                .filter(Boolean);

            return portalArray;
        };

        const [roots, setRoots] = useState(initialCompareCheckboxes);
        const [portals, setPortals] = useState(compareCheckboxes(roots));

        useEffect(() => {
            setPortals(compareCheckboxes(roots));
        }, [roots]);

        return portals ? portals : null;
    }
    return null;
};

export { CompareCheckboxLoader };
