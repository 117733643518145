export default {
    exclusionList: [
        '/',
        '/blog/*',
        '/appointment/*',
        '/contact/*',
        '/contact-free-services',
        '/repair/*',
        '/maintain/*',
        '/sem/*',
        '/offers/*',
        '/locate/*',
        '/tires/warranty-options',
        '/tires/all-tires',
        '/tires/suv-tires',
        '/tires/winter-tires',
        '/penny-tire-test',
        '/about',
        '/credit-card',
        '/bgca',
        '/resolv',
        '/fleetcare',
        '/firestonedirect',
        '/gasonomics',
        '/southcentral',
        '/triplepromise',
        '/my-firestone-app',
        '/privacy-statement',
        '/account/*'
    ]
};
