import { prConfig } from 'common/components/content/react-shared/utils/power-reviews/prConfig';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { updateProductSummaries, updateProductSummariesV2 } from 'common/components/content/react-shared/utils/power-reviews/updateProductSummaries';

import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

const requestRatings = async (callback, tireData) => {
    let groupId = (prConfig && prConfig.groupId) || '',
        locale = 'en-US',
        tireArr = [];

    for (const [key, value] of Object.entries(tireData)) {
        tireArr.push(value);
    }

    const idArray = tireArr
        .map((item, i) => {
            const brand = item.tire?.brand || '';
            const tireName = item.tire?.tireFriendlyName || item.tire?.tireName || '';
            const id = stringSanitizer(`${brand}${tireName}`, 'upper');

            return id || false;
        })
        .filter(Boolean);

    if (tireDetailPageSwitch()) {
        await updateProductSummariesV2(callback, idArray, groupId, locale);
    } else {
        await updateProductSummaries(callback, idArray, groupId, locale);
    }
};

export { requestRatings };
